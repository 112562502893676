/**
 * TODO: THIS IS IMPORTANT FOR PARSE SDK CONFIGURATION
 * https://www.back4app.com/docs/react/quickstart
 * https://www.back4app.com/docs/react/real-time/react-hook-real-time
 * WE NEED TO UPDATE PARSE SDK CONFIG AND REQUESTS
 */

// @ts-ignore
import Parse from "parse/dist/parse.min.js";

export const useParseSDK = (): { Parse: typeof Parse } => {
  const appId: string | undefined = process.env.REACT_APP_API_ID;
  const javascriptId: string | undefined = process.env.REACT_APP_JAVASCRIPT_ID;

  // Initialize Parse
  //Parse.CoreManager.setStorageController(Parse.IndexedDB);
  if (appId && javascriptId) {
    Parse.initialize(appId, javascriptId);
    Parse.serverURL = "https://parseapi.back4app.com/";
  } else {
    console.error("App ID and/or JavaScript ID are missing.");
  }

  return {
    Parse,
  };
};
