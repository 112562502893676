import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setBills as setBillsRedux } from "../slices/bills.slice"; // Import the actions

export const useBillsState = () => {
  const dispatch = useDispatch();

  // Assuming that TypeScript can correctly infer the type here
  const billsState = useAppSelector((state) => state.billsState);

  // Function to set bills
  const setBills = useCallback(
    (data: typeof billsState) => {
      dispatch(setBillsRedux({ bills: data.bills }));
    },
    [dispatch],
  );

  return {
    billsState,
    setBills,
  };
};
