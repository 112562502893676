import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useScheduledRecipesState } from "@reduxHooks/useScheduleSlice";

export const useFetchSchedule = () => {
  const { Parse } = useParseSDK();
  const {scheduledRecipesState, setScheduleData } = useScheduledRecipesState();
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);
  const businessId = Userbusiness[0];
  const userId = userData[0];

  return {
    fetchSchedule: async () => {
      if (!navigator.onLine) {
       // alert('You are offline. Fetching schedule from Redux state.');
        // Scheduled recipes should already be in the Redux state if fetched online previously
        if (scheduledRecipesState.scheduledRecipes && scheduledRecipesState.scheduledRecipes.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setScheduleData({ scheduledRecipes: [] });  // Provide fallback if no scheduled recipes are in state
          return;
        }
      } else {
        try {
          const data = await Parse.Cloud.run(
            "fetchScheduledRecipes",
            {
              businessId: businessId,
            },
            {
              user: userId,
            }
          );

          if (data && Array.isArray(data) && data.length > 0) {
            const convertDatesToIsoStrings = () => {
              return data.map((recipe) => ({
                ...recipe,
                title: recipe.recipe,
                start: recipe.start?.toISOString() || recipe.date,  // Convert Date to ISO string
                end: recipe.end?.toISOString() || recipe.date,  // Convert Date to ISO string
              }));
            };
            const formattedData = convertDatesToIsoStrings();
            setScheduleData({ scheduledRecipes: formattedData });
          } else {
            setScheduleData({ scheduledRecipes: [] });
          }
        } catch (error) {
          console.error("Error fetching schedule", error);
          setScheduleData({ scheduledRecipes: [] });  // Handle error by setting empty or fallback data
        }
      }
    },
  };
};
