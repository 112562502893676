import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Alert, Container, CssBaseline, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material'; // Import ArrowBack icon
import { useParseSDK} from 'hooks/useParseSDK';
import { brandColors } from '../../brandColors'; // Assuming your brand colors can be adapted for Material UI theme
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

const UserResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState({ show: false, message: '', severity: 'info' });
  const {Parse}=useParseSDK();
  
  const doUserPasswordReset = async () => {
    try {
      await Parse.User.requestPasswordReset(email);
      setAlert({ show: true, message: `Please check ${email} to proceed with password reset.`, severity: 'success' });
      setTimeout(() => navigate('/'), 3000); // Redirect to home or previous page after showing success message
    } catch (error) {
      setAlert({ show: true, message: error.message, severity: 'error' });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {/* Header with back button and title */}
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-start',
            mt: 2,
            mb: 3,
          }}
        >
          <IconButton aria-label="go back" onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" component="h1" sx={{ ml: 2, color: brandColors.primary }}>
            Reset Password
          </Typography>
        </Box>

        {alert.show && (
          <Alert severity={alert.severity} sx={{ width: '100%', mb: 2 }}>
            {alert.message}
          </Alert>
        )}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, bgcolor: brandColors.primary }}
          onClick={() => doUserPasswordReset()}
        >
          Reset Password
        </Button>
      </Box>
    </Container>
  );
};

export default UserResetPassword;
