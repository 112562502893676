import React from "react";
import { Box, Grid } from "@mui/material";
import { Stepper } from "../Stepper";
import { SignUpMainWrapper } from "./HOC/SignUpMainWrapper";
import { ConfirmOTPWrapper } from "./HOC/ConfirmOTPWrapper";
import { InviteMembersWrapper } from "./HOC/InviteMembersWrapper";
import PricingPage from "./HOC/PricingWrapper";

const SignUpForm = () => {
  const {
    SignupMain,
    isSignupValid,
    triggerSignup,
    submitSignUp,
    isLoading,
    email,
  } = SignUpMainWrapper();
  const { OtpForm, validateOTP } = ConfirmOTPWrapper();
  const { InviteMembers, inviteSubmit } = InviteMembersWrapper();

  const StepperConfig = [
    {
      Item: SignupMain,
      // TODO: extract as a separate global abstracted funtion to be used for all the configs
      handleNext: async (
        //render props
        activeStep: number,
        setActiveStep: React.Dispatch<React.SetStateAction<number>>,
        length: number
      ) => {
        const NextStepHandler = () => {
          activeStep < length - 1 &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };
        await triggerSignup();
        if (isSignupValid) {
          await submitSignUp(NextStepHandler);
        }
      },
      label: "SignUp Credentials",
    },

    {
      Item: OtpForm,
      handleNext: async (
        activeStep: number,
        setActiveStep: React.Dispatch<React.SetStateAction<number>>,
        length: number
      ) => {
        const NextStepHandler = () => {
          activeStep < length - 1 &&
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };
        await validateOTP(email, NextStepHandler);
      },
      label: "Confirm OTP",
    },

    {
      Item: InviteMembers,
      handleNext: async (
        activeStep: number,
        setActiveStep: React.Dispatch<React.SetStateAction<number>>,
        length: number
      ) => {
        // const NextStepHandler = () => {
        //   activeStep < length - 1 &&
        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // };
        await inviteSubmit();
        // console.log("INVOKE FORM2 TRIGGER FN");
      },
      label: "Invite Team",
    },
    // {
    //   Item: PricingPage,
    //   handleNext: async (
    //     activeStep: number,
    //     setActiveStep: React.Dispatch<React.SetStateAction<number>>,
    //     length: number
    //   ) => {
    //     // await inviteSubmit(NextStepHandler);
    //     // console.log("INVOKE FORM2 TRIGGER FN");
    //   },
    //   label: "Subscribe",
    // },
  ];

  return (
    <Grid
      container
      spacing={2} // Adjust spacing between grid items as needed
      alignItems="center"
      justifyContent="space-around" // This will space the items similarly to your original design
      sx={{
        maxWidth: "80vw",
        margin: "auto",
        marginTop: 10,
      }}>
      <Grid
        item
        xs={12}
        md={4} // Adjust grid sizes as needed for responsive design
        sx={{
          display: "flex",
          justifyContent: "center", // Centers the image in its grid item
          paddingRight: { xs: "0", md: "60px" }, // Adjusts padding for responsiveness
        }}>
        <img
          src={require("../../assets/images/logo-icon.png")}
          width="200px"
          height="200px"
          alt="logo"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          display: "flex",
          justifyContent: "center", // Adjust as needed for alignment
        }}>
        <Stepper data={StepperConfig} isNextDisabled={isLoading} />
      </Grid>
    </Grid>
  );
};

export default SignUpForm;
