export const matchStockIds = (ingredientsArr, stockData) => {
  const ingredientsWithStock = [];
  ingredientsArr?.forEach((ingredient) => {
    let matchedStockItem = null;
    const ingredientId = ingredient.stockId;
   
    for (const stockName of Object.keys(stockData)) {
      const stockItem = stockData[stockName];

      if (stockItem.id === ingredientId) {
        matchedStockItem = { ...stockItem, name: stockName };
        break;
      }
    }

    ingredientsWithStock.push({
      ...ingredient,
      stockItem: matchedStockItem,
    });
  });

  return ingredientsWithStock;
};
