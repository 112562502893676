import React, {useState} from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, IconButton, TextField,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';


const InvoiceTable = ({
  handleClickOpen, handleCollectiveDelete, handleSelectAllInvoices, handleSelectInvoice, 
  isAllSelected, invoices, selectedInvoices, emptyState
}) => {
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredInvoices = searchText
  ? invoices.filter(invoice => {
      // Convert search text to lower case and split into keywords
      const searchKeywords = searchText.toLowerCase().split(/\s+/);
      // Convert invoice number to lower case for case-insensitive comparison
      const invoiceNumberLower = invoice.invNumber.toLowerCase();

      // Check if any keyword is included in the invoice number
      return searchKeywords.some(keyword => invoiceNumberLower.includes(keyword));
    })
  : invoices;
  const noDataMessage = (entityName) => (
    <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
      No {entityName} found. Please add some.
    </Typography>
  );
  return (
    <>
     <TextField
        label="Search by Invoice Number"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        style={{ marginBottom: '10px' }}
      />
      <Button onClick={() => handleClickOpen('add')}>Add Invoice</Button>
      <Button color="secondary" onClick={handleCollectiveDelete} disabled={selectedInvoices.length === 0}>
        Delete Selected
      </Button>
      {emptyState ?(
              noDataMessage("invoices")
            ):(
      <TableContainer component={Paper} sx={{ maxHeight:"60vh" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isAllSelected(invoices)}
                  onChange={() => handleSelectAllInvoices(invoices)}
                />
              </TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.map((invoice) => (
              <TableRow key={invoice.id} selected={selectedInvoices.includes(invoice.id)}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedInvoices.includes(invoice.id)}
                    onChange={() => handleSelectInvoice(invoice.id)}
                  />
                </TableCell>
                <TableCell>{invoice.invNumber}</TableCell>
                {/* ... other cells */}
                <TableCell>
                  <IconButton onClick={() => handleClickOpen('edit', invoice)}><EditIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
    </>
  );
};

export default InvoiceTable;
