// Importing required packages from Redux Toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Units Enum
enum Units {
  KG = "kg",
  G = "g",
  LITER = "l",
  ML = "ml",
  DEGREE_C = "°C",
  PCS = "pcs",
}

// Ingredient Interface
interface Ingredient {
  id: string;
  stockId: string;
  amount: string;
  unit: Units;
  category: string;
}
 interface Packages {
 
                name: string,
                id: string,
                amount: string,
                price: string,
                serves:string,

               }
 
// Recipe Interface
interface Recipe {
  id: string;
  name: string;
  cookTime: string; // Consider using a more precise time type
  cookTemp: string; // Consider using number or specific enum for temperature units
  method: string; // Assume you have a type or enum for CookingMethod
  servings: number;
  retailPrice: number;
  businessId: string;
  isIngredient: boolean;
  ingredients: Ingredient[];
  topping: Ingredient[];
  base: Ingredient[];
  packaging: Packages[];
}

// Initial State Type and Definition
type RecipesStateType = {
  recipes: Recipe[];
};

const initialState: RecipesStateType = {
  recipes: [],
};

// Recipe Redux Slice
const recipeSlice = createSlice({
  name: "recipeState",
  initialState,
  reducers: {
   setRecipes: (state, action: PayloadAction<{ recipes: Recipe[] }>) => {
  state.recipes = action.payload.recipes;
},

     
    // Add more reducers as needed
  },
});

export const { setRecipes } = recipeSlice.actions;
export default recipeSlice.reducer;
