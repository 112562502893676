import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setPackages as setPackagesRedux } from "../slices/packages.slice";

export const usePackagesState = () => {
  const dispatch = useDispatch();
  const packagesState = useAppSelector((state) => state.packagesState);

  const setPackages = useCallback(
    (data: typeof packagesState) => {
      dispatch(setPackagesRedux({ packages: data.packages }));
    },
    [dispatch],
  );

  return {
    packagesState,
    setPackages,
  };
};
