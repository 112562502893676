import { useParseSDK } from "hooks/useParseSDK";

const {Parse}=useParseSDK();

///////////////////////////////////////////////////////////////Packages/////////////////////////////////////////////////

export const handleAddPackage = async (packages, businessId) => {
  const Package = Parse.Object.extend("Packages");
  const packageObjects = [];

  for (const { name, amount, price, serves } of packages) {
    const packageObj = new Package();
    packageObj.set("name", name);
    packageObj.set("amount", amount);
    packageObj.set("price", price);
    packageObj.set("serves", serves);
    packageObj.set("Business", {
      __type: "Pointer",
      className: "Business",
      objectId: businessId,
    });
    packageObjects.push(packageObj);
  }

  try {
    await Parse.Object.saveAll(packageObjects);
    
  } catch (error) {
    throw new Error(`Failed to save packages: ${error.message}`);
  }
};

export const handleUpdatePackage = async (newPackageData) => {
  try {
    const Packages = Parse.Object.extend("Packages");
    const packageQuery = new Parse.Query(Packages);
    const packageToUpdate = await packageQuery.get(newPackageData.id);

    packageToUpdate.set("name", newPackageData.name);
    packageToUpdate.set("amount", newPackageData.amount);
    packageToUpdate.set("price", newPackageData.price);
    packageToUpdate.set("serves", newPackageData.serves);

     await packageToUpdate.save();
  
   
  } catch (error) {
    console.error("Error while updating Package:", error);
  }
};

export const handleDeletePackage = async (packageId) => {
  try {
    const Packages = Parse.Object.extend("Packages");
    const packageQuery = new Parse.Query(Packages);
    const packageToDelete = await packageQuery.get(packageId);

    if (!packageToDelete) {
      console.error(`Package not found: ${packageId}`);
      return;
    }

    await packageToDelete.destroy();
   
  } catch (error) {
    
    alert("Package delete failed.");
  }
};

export const deleteAllSelectedPackages = async (packageIds) => {
  const Packages = Parse.Object.extend("Packages");
  const query = new Parse.Query(Packages);

  // Use the "containedIn" query method to match all objects with IDs in the packageIds array
  query.containedIn("objectId", packageIds);

  try {
    const results = await query.find();
    await Parse.Object.destroyAll(results);

  } catch (error) {
   
    alert("Failed to delete all selected packages.");
  }
};
