import React, {useState} from "react";
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Cancel } from "@mui/icons-material";

export const EditableIngredientsList = ({
  title,
  items,
  onChange,
  isEditing,
  onAddNewItem,
  onDeleteItem,
  onCancelNewItem, // Function to handle cancellation of new items
  stockOptions,
  newItems, // IDs of new items
}) => {
  const [validationErrors, setValidationErrors] = useState({});

  // Directly validate within onChange handler
  const handleOnChange = (index, field, value) => {
    onChange(index, field, value);
    const errors = { ...validationErrors };

    // Perform validation
    if (field === 'amount' && !value) {
      errors[`amount-${index}`] = 'Amount is required.';
    } else if (field === 'amount') {
      delete errors[`amount-${index}`];
    }

    if (field === 'unit' && !value) {
      errors[`unit-${index}`] = 'Unit is required.';
    } else if (field === 'unit') {
      delete errors[`unit-${index}`];
    }

    setValidationErrors(errors);
  };

  // Validate all items before adding a new one
  const handleOnAddNewItem = () => {
    let isValid = true;
    const errors = {};

    items.forEach((item, index) => {
      if (!item.amount) {
        isValid = false;
        errors[`amount-${index}`] = 'Amount and Unit are required.';
      }
      if (!item.unit) {
        isValid = false;
        errors[`unit-${index}`] = 'Unit is required.';
      }
    });

    setValidationErrors(errors);

    if (isValid) {
      onAddNewItem();
    } else {
      alert('Please correct the validation errors before adding a new item.');
    }
  };

  // Function to render each row of the table
  const renderRow = (item, index) => (
    <TableRow key={`ingredient-${index}`}>
      {/* Name/Category Cell */}
      <TableCell component="th" scope="row">
        {isEditing ? (
          <>
            <Select
              labelId={`category-select-label-${index}`}
              id={`category-select-${index}`}
              value={item.category || ""}
              onChange={(e) => onChange(index, "category", e.target.value)}
              size="small"
              displayEmpty
              fullWidth
              style={{ marginBottom: 8 }}
            >
              <MenuItem value="SelectCategory" disabled>
                Select Category
              </MenuItem>
              {Object.keys(stockOptions).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={item.stockId || ""}
              onChange={(e) => onChange(index, "stockId", e.target.value)}
              size="small"
              displayEmpty
              fullWidth
            >
              <MenuItem value="SelectStockID" disabled>
                Select Stock
              </MenuItem>
              {item.category &&
                stockOptions[item.category]?.map((stock) => (
                  <MenuItem key={stock.id} value={stock.id}>
                    {stock.Name}
                  </MenuItem>
                ))}
            </Select>
          </>
        ) : (
          <Typography>
            {stockOptions[item.category]?.find((s) => s.id === item.stockId)
              ?.Name || "N/A"}
          </Typography>
        )}
      </TableCell>

     {/* Quantity Cell */}
     <TableCell align="right">
        {isEditing ? (
          <TextField
            type="number"
            value={item.amount || ""}
            onChange={(e) => handleOnChange(index, "amount", e.target.value)}
            size="small"
            error={!!validationErrors[`amount-${index}`]}
            helperText={validationErrors[`amount-${index}`] || ''}
          />
        ) : (
          <Typography>{item.amount}</Typography>
        )}
      </TableCell>

      {/* Unit Cell */}
      <TableCell align="right">
        {isEditing ? (
          <Select
            value={item.unit || ""}
            onChange={(e) => handleOnChange(index, "unit", e.target.value)}
            size="small"
            displayEmpty
            fullWidth
            error={!!validationErrors[`unit-${index}`]}
          >
            {/* Unit options */}
            <MenuItem value="g">Grams (g)</MenuItem>
            <MenuItem value="kg">Kilograms (kg)</MenuItem>
            <MenuItem value="pcs">Pieces (Pcs)</MenuItem>
            <MenuItem value="ml">Milliliters (ml)</MenuItem>
            <MenuItem value="l">Liters (L)</MenuItem>
          </Select>
        ) : (
          <Typography>{item.unit}</Typography>
        )}
        {validationErrors[`unit-${index}`] && (
          <Typography color="error" variant="caption">
            {validationErrors[`unit-${index}`]}
          </Typography>
        )}
      </TableCell>

      {/* Actions Cell */}
      <TableCell align="right">
        {!isEditing && (
          <IconButton
            onClick={() => onDeleteItem(item.id)}
            color="error"
            sx={{ marginRight: 1 }}
          >
            <Delete />
          </IconButton>
        )}
        {isEditing &&
          newItems?.includes(item.id) && ( // Show cancel button for new items
            <IconButton
              onClick={() => onCancelNewItem(item.id)}
              color="default"
            >
              <Cancel />
            </IconButton>
          )}
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
        <Table
          sx={{ minWidth: 650, maxHeight: 500 }}
          aria-label={`${title.toLowerCase()} table`}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit</TableCell>
              {isEditing && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, index) => renderRow(item, index))}
            {isEditing && (
              <TableRow>
                <TableCell colSpan={4} align="right">
                  <Button
                    onClick={handleOnAddNewItem}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Add New {title}
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
