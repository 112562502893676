export const convertUnits = (value, unit, name) => {
  if (unit?.toLowerCase() === "kg") {
    return { value: value * 1000, unit: "g" };
  } else if (unit?.toLowerCase() === "liter"||unit?.toLowerCase()==="l") {
    return { value: value * 1000, unit: "ml" };
  } else if (
    unit?.toLowerCase() === "gram" ||
    unit?.toLowerCase() === "grams" ||
    unit?.toLowerCase() === "g"
  ) {
    return { value: value, unit: "g" };
  } else if (unit?.toLowerCase() === "kilo liter") {
    return { value: value * 1000000, unit: "ml" };
  } else if (name?.toLowerCase() === "eggs" || name?.toLowerCase() === "egg") {
    if (
      unit?.toLowerCase() === "kg" ||
      unit?.toLowerCase() === "kilogram" ||
      unit?.toLowerCase() === "kgs"
    ) {
      return { value: (value * 1000) / 50, unit: "pcs" };
    } else if (unit?.toLowerCase() === "liter") {
      return { value: (value * 1000) / 50, unit: "pcs" };
    } else if (
      unit?.toLowerCase() === "gram" ||
      unit?.toLowerCase() === "grams" ||
      unit?.toLowerCase() === "g"
    ) {
      return { value: value / 50, unit: "pcs" };
    }
  } else {
    return { value: value, unit: unit };
  }
};
