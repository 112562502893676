import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StockData {
  qty: number;
  calculatedPrice: number;
  calculatedQuantity: number;
  id: string;
  unit: string;
}

interface StockDataState {
  stockData: Record<string, StockData>;
}

const initialState: StockDataState = {
  stockData: {},
};

const stockDataSlice = createSlice({
  name: "stockData",
  initialState,
  reducers: {
    setStockData: (state, action: PayloadAction<Record<string, StockData>>) => {
      state.stockData = action.payload;
    },
     updateStockQty: (
      state,
      action: PayloadAction<{ name: string; newQty: number }>,
    ) => {
      const { name, newQty } = action.payload;
      if (state.stockData[name]) {
        state.stockData[name].qty = newQty;
      }
    },
  },
});

export const { setStockData, updateStockQty } =
  stockDataSlice.actions;
export default stockDataSlice.reducer;
