import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setLoginState as setLoginStateRedux } from "../slices/login.slice"; // Import the actions from your user.slice.ts

export const useUserState = () => {
  const dispatch = useDispatch();

  // TypeScript should correctly infer the type here
  const loginState = useAppSelector((state) => state.loginState); // Renamed to userState for consistency

  // Function to set user
  const setLoginState = useCallback(
    (data: typeof loginState) => {
      // Changed the type to match your Redux slice
      dispatch(setLoginStateRedux({ user: data.user }));
    },
    [dispatch],
  );

  return {
    loginState, // Renamed to userState for consistency
    setLoginState, // Renamed for clarity
  };
};
