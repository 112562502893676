// OmarChatbot.js

import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Avatar,
} from "@mui/material";
import Person from "@mui/icons-material/Person";
import logoIcon from "assets/images/logo-icon.png"; // Adjust the path as necessary

function CustomAvatar() {
  return (
    <Box
      sx={{
        width: 50, // Adjust the size of the avatar
        height: 50,
        borderRadius: "50%", // Makes the Box circular, like an Avatar
        overflow: "hidden", // Ensures the image doesn't overflow the circular shape
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        bgcolor: "white", // Background color
        marginRight: 1,
      }}>
      <img
        src={logoIcon}
        alt="Logo"
        style={{
          height: "auto",
          width: "100%", // Adjust this value or the height to control the image size within the avatar
        }}
      />
    </Box>
  );
}

const useTypingAnimation = (message, typingSpeed) => {
  const [displayedMessage, setDisplayedMessage] = React.useState("");

  React.useEffect(() => {
    if (message.length > displayedMessage.length) {
      const timer = setTimeout(() => {
        setDisplayedMessage(message.substr(0, displayedMessage.length + 1));
      }, typingSpeed);
      return () => clearTimeout(timer);
    }
  }, [message, displayedMessage, typingSpeed]);

  return displayedMessage;
};

function OmarChatbot() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [typingMessage, setTypingMessage] = useState(null);

  const displayedTypingMessage = useTypingAnimation(
    typingMessage?.text || "",
    30
  );

  const sendMessage = (e) => {
    e.preventDefault(); // Prevent form submission refresh
    if (!inputValue.trim()) return; // Ignore empty messages

    // Add user message immediately
    setMessages([...messages, { text: inputValue, sender: "user" }]);
    setInputValue("");

    // Simulate bot reply with typing effect
    const botMessage =
      "Hello there! It’s so nice to meet you. I'm just getting ready to go live, and I can’t wait to chat more with you. Thanks a ton for hanging in there! My developer is working hard and will share when I'm all set to launch. Stay tuned!";
    setTimeout(() => {
      setTypingMessage({ text: botMessage, sender: "bot" });
    }, 500);
  };

  React.useEffect(() => {
    if (typingMessage && displayedTypingMessage === typingMessage.text) {
      setMessages((messages) => [...messages, typingMessage]);
      setTypingMessage(null); // Reset typing message
    }
  }, [displayedTypingMessage, typingMessage]);

  return (
    <Box sx={{ margin: "auto", textAlign: "center" }}>
      <Paper
        elevation={3}
        sx={{ maxHeight: "75vh", overflow: "auto", marginBottom: 2 }}>
      <List>
  {[...messages].reverse().map((message, index) => (
    <ListItem key={index} alignItems="flex-start">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: message.sender === "bot" ? "flex-start" : "flex-end",
        }}>
        {message.sender === "bot" && <CustomAvatar />}
        <Box
          sx={{
            bgcolor: message.sender === "bot" ? "primary.light" : "secondary.light",
            borderRadius: 2,
            padding: 1,
            wordBreak: "break-word", // Ensure long words don't overflow
            textAlign: message.sender === "bot" ? "left" : "right",
          }}>
          {message.text}
        </Box>
        {message.sender === "user" && (
          <Avatar
            sx={{
              width: 50, 
              height: 50,
              borderRadius: "50%", 
              bgcolor: "#E1BEE7", 
              marginLeft: 1,
            }}>
            <Person />
          </Avatar>
        )}
      </Box>
    </ListItem>
  ))}
  
  {typingMessage && (
    <ListItem alignItems="flex-start">
      <CustomAvatar />
      <ListItemText
        primary={displayedTypingMessage}
        primaryTypographyProps={{
          sx: { bgcolor: "primary.light", borderRadius: 2, padding: 1 },
        }}
      />
    </ListItem>
  )}
</List>
      </Paper>
      <form onSubmit={sendMessage}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          autoFocus
          autoComplete="off" // Disable saved data suggestions
        />
        <Button type="submit" variant="contained" sx={{ marginTop: 1 }}>
          Send
        </Button>
      </form>
    </Box>
  );
}

export default OmarChatbot;
