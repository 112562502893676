import React, { useState } from "react";
import {
  Stepper as MuiStepper,
  Step,
  Button,
  Box,
  StepLabel,
} from "@mui/material";

export const Stepper = ({
  data,
  isNextDisabled,
}: {
  data: any;
  isNextDisabled: boolean;
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    await data[activeStep].handleNext(activeStep, setActiveStep, data.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box width="100%">
      <MuiStepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginBottom: 3 }}
      >
        {data.map((item: any) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={item.label} {...stepProps}>
              <StepLabel {...labelProps}>{item.label}</StepLabel>
            </Step>
          );
        })}
      </MuiStepper>

      <>
        {data[activeStep].Item()}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "16px",
          }}
        >
          <Button
            disabled={activeStep === 0 || isNextDisabled}
            onClick={handleBack}
            sx={{ mr: 1 }}
            variant="contained"
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            disabled={isNextDisabled}
            onClick={() => handleNext()}
          >
            {activeStep === data.length - 1 ? "Submit" : "Next"}
          </Button>
        </Box>
      </>
    </Box>
  );
};
