import { useParseSDK } from "hooks/useParseSDK";

const {Parse}=useParseSDK();

///////////////////////////////////////////////////////////////Invoices///////////////////////////////////////////

export const addInvoices = async (invoices, businessId) => {
  
  const Invoice = Parse.Object.extend("Invoices");

  const invoiceObjectsToSave = invoices
    .map((invoice) => {
      try {
        if (
          !invoice.date ||
          !invoice.invNumber ||
          !invoice.lastDate ||
          !invoice.stock ||
          !invoice.supplier
        ) {
          return;
        }

        const newInvoice = new Invoice();
        newInvoice.set("date", invoice.date);
        newInvoice.set("business", {
          __type: "Pointer",
          className: "Business",
          objectId: businessId,
        });
        newInvoice.set("invNumber", invoice.invNumber);
        newInvoice.set("lastDate", invoice.lastDate);
        newInvoice.set("Supplier", {
          __type: "Pointer",
          className: "Suppliers",
          objectId: invoice.supplier,
        });

        

        newInvoice.set("stock", invoice.stock);

        return newInvoice;
      } catch (error) {
        console.error(
          `Error while adding invoice number ${invoice.invNumber}: `,
          error,businessId
        );
      }
    })
    .filter(Boolean); // Remove undefined elements due to errors

  try {
    await Parse.Object.saveAll(invoiceObjectsToSave);
  } catch (error) {
    console.error("Error while adding Invoices array: ", error);
  }
};

export const handleUpdateInvoice = async (newInvoiceData) => {
  
  try {
    // Create the Parse Object Instance
    const Invoices = Parse.Object.extend("Invoices");
    const invoiceQuery = new Parse.Query(Invoices);

    // Use the 'id' from newInvoiceData to find the Parse Object
    const invoiceToUpdate = await invoiceQuery.get(newInvoiceData.id);

    // Update the fields on the Parse Object
    invoiceToUpdate.set("invNumber", newInvoiceData.invNumber);
    invoiceToUpdate.set("date", newInvoiceData.date);
    invoiceToUpdate.set("lastDate", newInvoiceData.lastDate);

   
    invoiceToUpdate.set("stock", newInvoiceData.stock);
    invoiceToUpdate.set("Supplier",{
      __type: "Pointer",
      className: "Suppliers",
      objectId: newInvoiceData.supplier,
    })
    // Save the updates back to Parse Server
   await invoiceToUpdate.save();
 
  } catch (error) {
    console.error("Error while updating Invoice:", error, newInvoiceData.id);
  }
};

export const handleDeleteInvoice = async (invoiceId) => {
  
  try {
    const Invoices = Parse.Object.extend("Invoices");
    const query = new Parse.Query(Invoices);
    const invoiceToDelete = await query.get(invoiceId);

    if (!invoiceToDelete) {
      console.error(`Invoice not found: ${invoiceId}`);
      return;
    }

    await invoiceToDelete.destroy();

  } catch (error) {
    console.error(`Error while deleting invoice: ${invoiceId}`, error);
    alert("Invoice delete failed.");
  }
};

export const deleteAllSelectedInvoices = async (invoiceIds) => {
  
  const Invoices = Parse.Object.extend("Invoices");
  const query = new Parse.Query(Invoices);

  // Use the "containedIn" query to fetch all invoices with IDs in the invoiceIds array
  query.containedIn("objectId", invoiceIds);

  try {
    const results = await query.find();
    await Parse.Object.destroyAll(results);
  } catch (error) {
    console.error(`Failed to delete selected invoices: ${invoiceIds}`,error);
    alert("Failed to delete all selected invoices.");
  }
};