import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';


const MonthlyExpenseCalculator = ({ invoices, onCalculate }) => {
    const currentYear = new Date().getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const years = Array.from(new Array(10), (val, index) => currentYear - index); // Last 10 years

    useEffect(() => {
        calculateMonthlyStockExpense();
    }, [selectedMonth, selectedYear]); // Recalculate when month or year changes

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const calculateMonthlyStockExpense = () => {
        let monthlyExpense = 0;

        invoices.forEach(invoice => {
            let invoiceDate = new Date(invoice.date);
            let invoiceMonth = invoiceDate.getMonth();
            let invoiceYear = invoiceDate.getFullYear();

            if (invoiceMonth === selectedMonth && invoiceYear === selectedYear) {
                invoice.stock.forEach(item => {
                    let totalCost = item.qty * item.price;
                    monthlyExpense += totalCost;
                });
            }
        });

        onCalculate(monthlyExpense);
    };

    return (
        <div>
            <FormControl>
                <InputLabel>Year</InputLabel>
                <Select value={selectedYear} onChange={handleYearChange}>
                    {years.map(year => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Month</InputLabel>
                <Select value={selectedMonth} onChange={handleMonthChange}>
                    {months.map((month, index) => (
                        <MenuItem key={index} value={index}>{month}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MonthlyExpenseCalculator;