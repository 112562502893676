import React, { useEffect, useState ,useRef, useCallback} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Paper,
  Tabs,
  Tab,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useStockState } from "@reduxHooks/useStocksState";
import PieChartComponent from "./PieChartComponent";
import StockPickerDialog from "./StockDialog";
import InvoiceTable from './InvoiceTable'; // Adjust the import path as needed
import DialogComponent from "./DialogComponent";
import PackageTable from './PackageTable'; // Adjust the import path as needed
import ExpensesTable from "./ExpensesTable";
import { usePackagesState } from "@reduxHooks/usePackagesState";
import { useBillsState } from "@reduxHooks/useBillsState";
import { useInvoicesState } from "@reduxHooks/useInvoicesState";
import{useFetchSuppliers} from "@api/suppliers";
import { useFetchBills } from "@api/bills";
import { useFetchInvoices } from "@api/invoice";
import { useFetchPackages } from "@api/packaging";
import MonthlyExpenseCalculator from "./MonthlyStockCostCalc";
import { useAppSelector } from "redux/store";
import { useSuppliersState } from "@reduxHooks/useSupplierState";

import { addInvoices, deleteAllSelectedInvoices, handleUpdateInvoice } from "@api/CRUDOpInvoices";
import { deleteAllSelectedPackages, handleAddPackage, handleUpdatePackage } from "@api/CRUDPackages";
import { deleteAllSelectedExpenses, handleAddExpense, handleUpdateExpense } from "@api/CRUDBills";
import ResponseModal from "@components/success/errorModal";

// Validation Schema using Yup for each type
const invoiceSchema = yup
  .object({
    invNumber: yup.string().required("Invoice number is required"),
    date: yup.date().required("Date is required"),
    lastDate: yup.date().required("Last date is required"),
    // Add other fields as necessary
  })
  .required();

const packageSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    amount: yup.string().required("Amount is required"),
    price: yup.string().required("Price is required"),
    serves: yup.string().required("Serves is required"),
    // Add other fields as necessary
  })
  .required();

const expenseSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    amount: yup.string().required("Amount is required"),
    recurrence: yup.string().required("Recurrence is required"),
    // Add other fields as necessary
  })
  .required();

/* Main Dashboard Component */
const ExpensesDashboard = () => {

  const hasFetchedData = useRef(false);

  const {fetchBills}=useFetchBills();
    const {fetchInvoices}=useFetchInvoices();
    const {fetchPackages}=useFetchPackages();
  const {fetchSupplier}=useFetchSuppliers();

  
  const fetchData = useCallback(() => {
    if (!hasFetchedData.current) {
      fetchPackages();
      fetchInvoices();
      fetchBills();
      fetchSupplier();
    
      hasFetchedData.current = true;
    }
  }, []); 
  
    useEffect(() => {
      if (!hasFetchedData.current) {
        fetchData();
     
      }
    }, [fetchData]);

  const {billsState} = useBillsState();
  const {invoicesState}= useInvoicesState();
  const {packagesState} = usePackagesState();
  const {suppliersState}=useSuppliersState();
  const [showAnim, setSHowAnim]=useState(false);
  const [showSuccess,setShowSuccess]=useState(false);
  const [showError, setShowError]=useState(false);

  const {stockState}=useStockState();
  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);

  const businessId = Userbusiness[0];
  const userId = userData[0];

  
  const [openDialog, setOpenDialog] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const [formType, setFormType] = useState(""); // 'add' or 'edit'
  const [selectedTab, setSelectedTab] = useState(0);



  const [currentInvoiceStocks, setCurrentInvoiceStocks] = useState([]);
  const [supplier, setSupplier]=useState('');
  // Define form methods
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(
      selectedTab === 0
        ? invoiceSchema
        : selectedTab === 1
        ? packageSchema
        : expenseSchema
    ),
    defaultValues:{
      recurrence:"Weekly",
    }
  });

  const recurrenceValue = watch("recurrence");

  // Handlers for Dialog
  const handleClickOpen = (type, item = null) => {
    setFormType(type);
    setEditableItem(item);
    if (item) {
      reset(item); // Pre-fill form if item exists (for edit)
    } else {
      reset(); // Reset form for new addition
    }
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setEditableItem(null);
    
  };

  // Handlers for Add, Update, Delete
  const handleAdd = async(data) =>  {
   
    if (selectedTab === 0) {
   try{const newData=[data];
     await addInvoices(newData,businessId);
     await fetchInvoices();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }

    } else if (selectedTab === 1) {

    try{ await handleAddPackage([data],businessId);
     await fetchPackages();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
    } else if (selectedTab === 2) {
     try{ await handleAddExpense([data],businessId);
      await fetchBills();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
    }
  };

  const handleUpdate = async(data) => {
    // Implement update functionality based on selectedTab
    if (selectedTab === 0) {
    try{ await handleUpdateInvoice(data);
     await fetchInvoices();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
    } else if (selectedTab === 1) {
  
     try{ await handleUpdatePackage(data);
      await fetchPackages();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
    } else if (selectedTab === 2) {
    try{ await handleUpdateExpense(data);
     await fetchBills();
      reset();
      setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
    }
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date);
  };
  

  // Handlers for Form Submission
  const onSubmit = (data) => {
    let updatedData = {...data};
    
    // If the current tab is 'Invoices', add the 'stock' attribute to 'updatedData'.
    if (selectedTab === 0) {
      updatedData = {...updatedData, stock: currentInvoiceStocks, supplier:supplier, date: formatDate(updatedData.date), lastDate: formatDate(updatedData.lastDate)};
    }
    
    if (formType === "add") {
      handleAdd(updatedData);
    } else if (formType === "edit") {
      handleUpdate(updatedData);
    }
    handleClose(); // Close dialog and reset state after submission
  };
  


    // State for the Stock Picker Dialog
    const [openStockPicker, setOpenStockPicker] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedStocks, setSelectedStocks] = useState([]);
  
   
    // Function to handle opening the stock picker dialog
    const handleOpenStockPicker = () => {
      setOpenStockPicker(true);
    };
  
    // Function to handle closing the stock picker dialog
    const handleCloseStockPicker = () => {
      setOpenStockPicker(false);
    };
  
    // Function to handle stock selection within the dialog
   
    
    // Function to handle deleting a stock item from the invoice being edited
const handleDeleteStock = (index) => {
  // Make a shallow copy of the current invoice stocks
  let updatedStocks = [...currentInvoiceStocks];
  
  // Remove the stock item at the specified index
  updatedStocks.splice(index, 1);
  
  // Update the currentInvoiceStocks state with the new array
  setCurrentInvoiceStocks(updatedStocks);
};


    // Function to add the selected stocks to the invoice being edited
   // Updated function to handle stock selection within the dialog
const handleToggleStock = (stockId, category) => {
  const stockIdentifier = { stockId, category };  // Combine stock ID and category
  setSelectedStocks((prevStocks) => {
    const existingIndex = prevStocks.findIndex(stock => stock.stockId === stockId && stock.category === category);
    if (existingIndex !== -1) {
      // Remove the stock if it's already selected
      return prevStocks.filter((_, index) => index !== existingIndex);
    } else {
      // Add the new stock
      return [...prevStocks, stockIdentifier];
    }
  });
};


// Updated function to add the selected stocks to the invoice being edited
const handleAddStocksToInvoice = () => {
  const newStocks = selectedStocks.map(({ stockId, category }) => {
    const stockDetails = stockState.stock[category]?.find((stock) => stock.id === stockId);

    if (!stockDetails) {
      console.error("Stock not found in the selected category", { stockId, category });
      return null;
    }

    return {
      category,
      name: stockDetails.Name,
      price: stockDetails.Price,
      quantity: stockDetails.Quantity,
      qty: 1,
      unit:stockDetails.Unit,
      stockId
    };
  }).filter(stock => stock);  // Filter out any null values resulting from not finding a stock

  const updatedStocks = [...(editableItem ? editableItem.stock : []), ...newStocks];

  if (editableItem) {
    setEditableItem({ ...editableItem, stock: updatedStocks });
  } else {
    setCurrentInvoiceStocks(updatedStocks);
  }

  setSelectedStocks([]);
  handleCloseStockPicker();
};


const handleStockChange = (index, field, value) => {
  // Make a shallow copy of the current invoice stocks
  let updatedStocks = [...currentInvoiceStocks];
  
  // Attempt to update a property directly in an object - this could cause issues
  if (field === 'qty' || field === 'price' || field === 'quantity'||field === 'unit') {
    // Correct approach: create a new object for the updated stock
    const updatedStock = { ...updatedStocks[index], [field]: Number(value) };
    updatedStocks[index] = updatedStock; // Replace the item at the index with the updated item
  }
  
  // Update the currentInvoiceStocks state with the new array
  setCurrentInvoiceStocks(updatedStocks);
};


  // Whenever editableItem changes, update the currentInvoiceStocks
  useEffect(() => {
    if (editableItem && editableItem.stock) {
      setCurrentInvoiceStocks(editableItem.stock);
    } else {
      setCurrentInvoiceStocks([]); // Reset if there's no editableItem
    }
  }, [editableItem]);



  const [selectedInvoices, setSelectedInvoices] = useState([]); // Track selected items
  const [selectedPackages, setSelectedPackages] = useState([]);
const [selectedExpenses, setSelectedExpenses] = useState([]);

  // Handle single checkbox toggle
  const handleSelectInvoice = (id) => {
    setSelectedInvoices((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // Handle select all checkboxes
  const handleSelectAllInvoices = (items) => {
    if (selectedInvoices.length === items.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(items.map((item) => item.id));
    }
  };

// Selection handlers for packages
const handleSelectPackage = (id) => {
  setSelectedPackages((prev) => {
    if (prev.includes(id)) {
      return prev.filter((item) => item !== id);
    } else {
      return [...prev, id];
    }
  });
};

const handleSelectAllPackages = (items) => {
  if (selectedPackages.length === items.length) {
    setSelectedPackages([]);
  } else {
    setSelectedPackages(items.map((item) => item.id));
  }
};

// Selection handlers for expenses
const handleSelectExpense = (id) => {
  setSelectedExpenses((prev) => {
    if (prev.includes(id)) {
      return prev.filter((item) => item !== id);
    } else {
      return [...prev, id];
    }
  });
};

const handleSelectAllExpenses = (items) => {
  if (selectedExpenses.length === items.length) {
    setSelectedExpenses([]);
  } else {
    setSelectedExpenses(items.map((item) => item.id));
  }
};

// Unified handler for deleting selected items
const handleCollectiveDelete = async () => {
 try{ if (selectedTab === 0) {
 await deleteAllSelectedInvoices(selectedInvoices);
     setSelectedInvoices([]); 
     await fetchInvoices(); 
  } else if (selectedTab === 1) {
   await deleteAllSelectedPackages(selectedPackages);
    await fetchPackages();
   setSelectedPackages([]);
  } else if (selectedTab === 2) {
    await deleteAllSelectedExpenses(selectedExpenses);
    await fetchBills();
    setSelectedExpenses([]);
  }
  setSHowAnim(true);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSHowAnim(false);
      }, 2000);
    }catch(error){
      setSHowAnim(true);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSHowAnim(false);
      }, 2000);
    }
};

// Check if all items are selected
const isAllSelected = (items) => {
  if (selectedTab === 0) return items.length > 0 && selectedInvoices.length === items.length;
  if (selectedTab === 1) return items.length > 0 && selectedPackages.length === items.length;
  if (selectedTab === 2) return items.length > 0 && selectedExpenses.length === items.length;
};
  

const normalizeToMonthly = (expense) => {
  switch (expense.recurrence) {
    case 'Yearly':
      return Number(expense.amount) / 12;
    case 'Weekly':
      return Number(expense.amount) * 4; // Approximating to 4 weeks in a month
    case 'Bi-Weekly':
      return Number(expense.amount) * 2; // Approximating to 2 bi-week periods in a month
    case 'Quarterly':
      return Number(expense.amount) * 4 / 12; // 4 quarters in a year
    // Add more cases as needed for different recurrences
    case 'Monthly':
    default:
      return Number(expense.amount);
  }
};

const preprocessExpenses = (expenses) => {
  return expenses?.map((expense) => ({
    ...expense,
    amount: normalizeToMonthly(expense),
    recurrence: 'Monthly', // Set all to Monthly after normalization
  }));
};

const [calculatedExpense, setCalculatedExpense] = useState(null);
const [pieData, setPieData] = useState([]);



const processedExpenses = preprocessExpenses(billsState.bills);

useEffect(() => {
    // Update pieData when calculatedExpense changes
    const updatedPieData = processedExpenses.map(expense => ({
        name: expense.name,
        value: Number(expense.amount),
    }));

    if (calculatedExpense !== null) {
        updatedPieData?.push({
            name: 'Stock Expense',
            value: Number(calculatedExpense.toFixed(2)),
        });
    }

    setPieData(updatedPieData);
}, [calculatedExpense, processedExpenses]);

const handleExpenseCalculation = (expense) => {
    setCalculatedExpense(expense);
};




  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
      <Box style={{ padding: "20px", height:"30vh" , marginBottom:5}}>
        <Typography variant="h6" sx={{mb:2}}>Outcome Overview</Typography>
        <MonthlyExpenseCalculator invoices={invoicesState.invoices} onCalculate={handleExpenseCalculation} />
           
     <PieChartComponent pieData={pieData}/>
     </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box style={{ padding: "20px"}}>
      <Paper sx={{marginBottom:5}}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setSelectedTab(newValue)}
        >
          <Tab label="Invoices" />
          <Tab label="Packaging" />
          <Tab label="Expenses" />
        </Tabs>
      </Paper>
     {selectedTab === 0 && (
     <>  
     {invoicesState.invoices.length === 0
     
            ? 
            <InvoiceTable
              handleClickOpen={handleClickOpen}
              handleCollectiveDelete={handleCollectiveDelete}
              handleSelectAllInvoices={handleSelectAllInvoices}
              handleSelectInvoice={handleSelectInvoice}
              isAllSelected={isAllSelected}
              invoices={invoicesState.invoices}
              selectedInvoices={selectedInvoices}
              emptyState={true}
            /> 
            : (
              <InvoiceTable
              handleClickOpen={handleClickOpen}
              handleCollectiveDelete={handleCollectiveDelete}
              handleSelectAllInvoices={handleSelectAllInvoices}
              handleSelectInvoice={handleSelectInvoice}
              isAllSelected={isAllSelected}
              invoices={invoicesState.invoices}
              selectedInvoices={selectedInvoices}
            />  
            )
          }
     
      </>
    )}
      {selectedTab === 1 && (
        <>
        {packagesState.packages.length === 0
          ? <PackageTable
          handleClickOpen={handleClickOpen}
          handleCollectiveDelete={handleCollectiveDelete}
          handleSelectAllPackages={handleSelectAllPackages}
          handleSelectPackage={handleSelectPackage}
          isAllSelected={isAllSelected}
          packages={packagesState.packages}
          selectedPackages={selectedPackages}
          emptyState={true}
        />
  
          : (
       <PackageTable
       handleClickOpen={handleClickOpen}
       handleCollectiveDelete={handleCollectiveDelete}
       handleSelectAllPackages={handleSelectAllPackages}
       handleSelectPackage={handleSelectPackage}
       isAllSelected={isAllSelected}
       packages={packagesState.packages}
       selectedPackages={selectedPackages}
     />)}
        </>
        
       
      )}
      {selectedTab === 2 && (
        <>{billsState.bills.length === 0
          ? <ExpensesTable
          handleClickOpen={handleClickOpen}
          handleCollectiveDelete={handleCollectiveDelete}
          handleSelectAllExpenses={handleSelectAllExpenses}
          handleSelectExpense={handleSelectExpense}
          isAllSelected={isAllSelected}
          expenses={billsState.bills}
          selectedExpenses={selectedExpenses}
          emptyState={true}
        />
          : ( <ExpensesTable
            handleClickOpen={handleClickOpen}
            handleCollectiveDelete={handleCollectiveDelete}
            handleSelectAllExpenses={handleSelectAllExpenses}
            handleSelectExpense={handleSelectExpense}
            isAllSelected={isAllSelected}
            expenses={billsState.bills}
            selectedExpenses={selectedExpenses}
          />)}
        </>
      
      )}
     <DialogComponent
        openDialog={openDialog}
        handleClose={handleClose}
        formType={formType}
        selectedTab={selectedTab}
        sampleSuppliers={suppliersState.suppliers}
        supplier={supplier}
        setSupplier={setSupplier}
        handleOpenStockPicker={handleOpenStockPicker}
        currentInvoiceStocks={currentInvoiceStocks}
        handleStockChange={handleStockChange}
        handleDeleteStock={handleDeleteStock}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        reccurenceValue={recurrenceValue}
      />
      <StockPickerDialog 
        openStockPicker={openStockPicker} 
        handleCloseStockPicker={handleCloseStockPicker}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        stockState={stockState}
        handleToggleStock={handleToggleStock}
        selectedStocks={selectedStocks}
        handleAddStocksToInvoice={handleAddStocksToInvoice}
      />
    </Box>
      </Grid>
      
    </Grid>
    <ResponseModal show={showAnim} setShow={setSHowAnim} success={showSuccess} errorResponse={showError}/>
    </>
  );
};




export default ExpensesDashboard;
