import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useStockState } from "@reduxHooks/useStocksState";
import { useStockDataState } from "@reduxHooks/useStockDataState";
import { useInvoicesState } from "@reduxHooks/useInvoicesState";
import { format } from "date-fns";

interface StockItem {
  category: string;
  name: string;
  price: number;
  qty: number;
  quantity: number;
  stockId: string;
  unit: string; // add unit property here
}

interface StockChange {
  date: string;
  calculatedPrice: number;
  calculatedQuantity: number;
  unit: string;
}

interface OrganizedStockData {
  monthYear: string;
  stockId: string;
  changes: StockChange[];
}

const formatDate = (date: string, formatString: string) => {
  const invoiceDate = new Date(date);
  return format(invoiceDate, formatString);
};

export const organizeStockData = () => {
  const { invoicesState } = useInvoicesState();
  const invoices = invoicesState.invoices;

  if (!invoices || !Array.isArray(invoices)) return [];
  const organizedStockData: { [key: string]: OrganizedStockData } = {};

  invoices.forEach((invoice) => {
    const monthYear = formatDate(invoice.date, 'MMMM-yyyy');
    invoice.stock.forEach((stockItem: StockItem) => {
      const stockKey = `${stockItem.stockId}_${monthYear}`;
      if (!organizedStockData[stockKey]) {
        organizedStockData[stockKey] = { 
          monthYear, 
          stockId: stockItem.stockId, 
          changes: [{
            date: invoice.date,
            calculatedPrice: stockItem.price * stockItem.qty, // Add calculatedPrice
            calculatedQuantity: stockItem.quantity * stockItem.qty, // Add calculatedQuantity
            unit: stockItem.unit // Add unit
          }] 
        };
      } else {
        const group = organizedStockData[stockKey];
        if (!group.changes.some(change => change.date === invoice.date)) {
          group.changes.push({
            date: invoice.date,
            calculatedPrice: stockItem.price * stockItem.qty,
            calculatedQuantity: stockItem.quantity * stockItem.qty,
            unit: stockItem.unit,
          });
        }
      }
    });
  });

  return Object.values(organizedStockData).map((group) => ({
    ...group,
    changes: group.changes.sort((a, b) => new Date(b.date).getDate() - new Date(a.date).getDate()),
  }));
};

export const useFetchStock = () => {
  const { Parse } = useParseSDK();
  const { setStock } = useStockState();
  const { setStockData } = useStockDataState();
  const { invoicesState } = useInvoicesState();
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);
  const businessId = Userbusiness[0];
  const userId = userData[0];
  
  const organizedInvoices = organizeStockData();

  return {
    fetchStock: async () => {
      if (!navigator.onLine) {
      //  alert('You are offline. Fetching stock data from Redux state.');
        // Stock data should already be in the Redux state if fetched online previously
        if (invoicesState.invoices && invoicesState.invoices.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setStockData({});
          setStock({});
          return;
        }
      } else {
        try {
          const data = await Parse.Cloud.run(
            "fetchStocks",
            {
              businessId: businessId,
            },
            {
              user: userId,
            }
          );

          const stockDataSaved = {};
        
          for (const category in data) {
            data[category].forEach((stock: any) => {
              const matchedOrganizedStock = organizedInvoices.find(organizedStock => 
                organizedStock.stockId === stock.id
              );
          
              let calculatedPrice: string;
              let calculatedQuantity: string;
              let qty: number;
                
              if (matchedOrganizedStock) {
                const change = matchedOrganizedStock.changes[0];
                calculatedPrice = change.calculatedPrice.toFixed(2);
                calculatedQuantity = change.calculatedQuantity.toFixed(2);
                qty = change.calculatedQuantity;
              } else {
                qty = 1;  // Adjust based on your logic
                calculatedPrice = (stock.Price * qty).toFixed(2);
                calculatedQuantity = (stock.Quantity * qty).toFixed(2);
              }
              //@ts-ignore
              stockDataSaved[stock.Name] = {
                id: stock.id,
                qty,
                calculatedPrice,
                calculatedQuantity,
                unit: stock.Unit,
              };
            });
          }

          if (Object.keys(data).length > 0) {
            setStockData(stockDataSaved);
            setStock(data);
          } else {
            setStockData({});
            setStock({});
          }
        } catch (error) {
          console.error("Error fetching stock data", error);
          setStockData({});
          setStock({});
        }
      }
    },
  };
};
