import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store"; // Replace with your root state type
import {
  StockData,
  setStockData as setStockDataRedux,
 
  updateStockQty,
} from "../slices/stockData.slice"; // Replace with your slice actions

// Custom hook for managing stock data state
export const useStockDataState = () => {
  const dispatch = useDispatch();

  // Get the current state of stockData from the Redux store
  const stockData = useAppSelector((state) => state.stockData); // Replace `stockData` with your slice's actual name

  // set stock data
  const setStockData = useCallback(
    (data: Record<string, StockData>) => {
      dispatch(setStockDataRedux(data));
    },
    [dispatch],
  );

   // Update stock quantity
  const updateQty = useCallback(
    (name: string, newQty: number) => {
      dispatch(updateStockQty({ name, newQty }));
    },
    [dispatch],
  );

  return {
    stockData,
    setStockData,
    updateQty
  };
};
