import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAppSelector } from "../../redux/store";
import { useParseSDK } from "hooks/useParseSDK";
import { UserBusinessProfile } from "./BusinessInfo";
import { EditProfileForm } from "./EditProfileForm";
import { UserProfile } from "./UserInfo";
import { UsersList } from "./userList";
import { useFetchUserData } from "@api/user";
import InviteMembers from "./InviteWrapper";
import BusinessHoursForm from "./BusinessHoursForm";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ProfileScreen = () => {
  const { fetchUserData } = useFetchUserData();

  // States for Menu (Options Button)
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModeBusiness, setIsEditModeBusiness] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const prevIsEditMode = usePrevious(isEditMode);
  const prevIsEditBusinessMode = usePrevious(isEditModeBusiness);

  const { Parse } = useParseSDK();

  const [openInviteDialog, setOpenInviteDialog] = useState(false);

  const handleInviteDialogToggle = () => setOpenInviteDialog(!openInviteDialog);

  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user[0]?.business;
  const userData = user[0]?.user;
  // Yup schema for validation
  const Userbusinessid = user.map((user) => user.business.id);
  const businessId = Userbusinessid[0];
  const userId = userData[0];
  const fetchUserList = async () => {
    const data = await Parse.Cloud.run(
      "fetchUsers",
      {
        businessId: businessId,
      },
      {
        user: userId,
      }
    );
    if (data.length > 0) {
      //dispatch({ type: "INITIALIZE_FETCH", payload: data });
      setUsersList(data);
    } else {
      return;
    }
  };

  if (usersList?.length === 0) {
    try {
      fetchUserList();
    } catch (error) {
      console.log("Error", error);
     
    }
  }

  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };

  const handleEditToggleBusiness = () => {
    setIsEditModeBusiness(!isEditModeBusiness);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Define menu options and handlers
  const menuOptions = [
    { name: "Edit Profile", action: handleEditToggle },
    { name: "Edit Business Info", action: handleEditToggleBusiness },
    { name: "Invite Members", action: handleInviteDialogToggle },
  ];

  useEffect(() => {
    // This condition checks if either mode was true and has just been set to false
    if (
      (prevIsEditMode && !isEditMode) ||
      (prevIsEditBusinessMode && !isEditModeBusiness)
    ) {
      // Fetch user data logic here
      fetchUserData();
    }
  }, [isEditMode, isEditModeBusiness, prevIsEditMode, prevIsEditBusinessMode]);

  return (
    <Container maxWidth={false}>
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}> {/* Reduced bottom margin */}
      {/* Options Button with Dropdown Menu */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        size="small" // Smaller icon button
        sx={{ bgcolor: "#2a2a2a", marginRight: 1 }} // Reduced right margin
      >
        <MoreVertIcon style={{ color: '#fff', fontSize: '1.25rem' }} /> {/* Smaller icon */}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.name} onClick={() => {
            option.action();
            handleMenuClose();
          }}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
    <Grid container spacing={2}>
        {/* User Profile */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
            {isEditMode ? <EditProfileForm isProfile={true} user={user} Userbusiness={Userbusiness}userData={userData} setIsEditMode={setIsEditMode} handleEditToggle={handleEditToggle}/>:<UserProfile userData={userData} user={user}/>}
         </CardContent>
          </Card>
        </Grid>

        {/* Business Information */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
              {/* Conditional rendering based on isEditModeBusiness */}
              {isEditModeBusiness? <EditProfileForm isProfile={false} user={user} Userbusiness={Userbusiness}userData={userData} setIsEditMode={setIsEditModeBusiness} handleEditToggle={handleEditToggleBusiness}/>:<UserBusinessProfile Userbusiness={Userbusiness}/>}
           </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid container spacing={2} mt={2}>
        {/* Users List */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
            {openInviteDialog ?(<>
            <InviteMembers setInviteDialog={setOpenInviteDialog} setUserList={setUsersList}/>
              <Button size="large" variant='outlined' sx={{flexGrow:1, width:"100%"}} onClick={handleInviteDialogToggle}>Cancel</Button>
         </>):( <UsersList usersList={usersList} setUserList={setUsersList}/>)}  {/* Conditional rendering based on openInviteDialog */}
            </CardContent>
          </Card>
        </Grid>

        {/* Business Hours Form */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
            <BusinessHoursForm/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileScreen;
