import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setSuppliers as setSuppliersRedux } from "../slices/supplier.slice";

export const useSuppliersState = () => {
  const dispatch = useDispatch();
  const suppliersState = useAppSelector((state) => state.suppliersState);

  const setSuppliers = useCallback(
    (data: typeof suppliersState) => {
      dispatch(setSuppliersRedux({ suppliers: data.suppliers }));
    },
    [dispatch],
  );

  return {
    suppliersState,
    setSuppliers,
  };
};
