// Function to check if a year is a leap year
const formatDateToString = (date) => {
  if (!date) return ""; // Return an empty string if date is not provided
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

// Function to calculate week, fortnight, or month ranges
export const calculatePaymentCycles = (paymentCycle, payment_cycle_start) => {

  if (!payment_cycle_start) return []; // Return an empty array if start date is not provided

  const cycles = [];
  let currentDate = new Date(payment_cycle_start.getTime());

  const generateCycles = (startDate) => {
    if (!startDate) return; // Exit the function if start date is not provided

    let cycleDate = new Date(startDate.getTime());
    let cycleIndex = 1; // Initialize cycle index

    const numCycles =  paymentCycle === "weekly" ? 52 : paymentCycle === "fortnightly" ? 26 : 12;

    for (let i = 0; i < numCycles; i++) {
      // Generating cycles accordingly
      let cycleStart = new Date(cycleDate.getTime());
      let cycleEnd;

      switch (paymentCycle) {
        case "weekly":
          cycleEnd = new Date(cycleDate.getTime());
          cycleEnd.setDate(cycleEnd.getDate() + 6);
          cycleDate.setDate(cycleDate.getDate() + 7);
          break;
        case "fortnightly":
          cycleEnd = new Date(cycleDate.getTime());
          cycleEnd.setDate(cycleEnd.getDate() + 13);
          cycleDate.setDate(cycleDate.getDate() + 14);
          break;
        case "monthly":
          cycleEnd = new Date(
            cycleDate.getFullYear(),
            cycleDate.getMonth() + 1,
            0
          ); // Last day of current month
          cycleDate.setMonth(cycleDate.getMonth() + 1);
          break;
        default:
          return null;
      }

      cycles.push({
        cycleStart: formatDateToString(cycleStart),
        cycleEnd: formatDateToString(cycleEnd),
        label: `${
          paymentCycle.charAt(0).toUpperCase() + paymentCycle.slice(1)
        } ${cycleIndex} (${formatDateToString(
          cycleStart
        )} - ${formatDateToString(cycleEnd)})`,
      });
      cycleIndex++; // Increment the cycle index
    }
  };

  generateCycles(currentDate);

  // Check if today's date is in the last cycle, and if so, generate new cycles
  const today = new Date();
  const lastCycle = cycles[cycles.length - 1];
  if (
    today >= new Date(lastCycle.cycleStart) &&
    today <= new Date(lastCycle.cycleEnd)
  ) {
    const nextStartDate = new Date(lastCycle.cycleEnd);
    nextStartDate.setDate(nextStartDate.getDate() + 1);
    generateCycles(nextStartDate);
  }

  return cycles;
};
