export const calculateBaseCostPerServing = (recipeCosts) => {
  return recipeCosts?.map((recipeCost) => {
    const servings = recipeCost.servings;
    const cost = parseFloat(recipeCost.cost); // Convert string to float
    const costPerServe = cost / servings;

    let newCost = Number(costPerServe.toFixed(3));

    if (recipeCost.packaging && recipeCost.packaging.length > 0) {
      recipeCost.packaging.forEach((packaging) => {
        newCost +=
          Number(packaging.price) /
          Number(packaging.amount) /
          Number(packaging.serves);
      });
    }

    return {
      ...recipeCost,
      costPerServing: newCost.toFixed(2), // Convert back to string with 2 decimal places
    };
  });
};
