export const convertEmployeeInfo = (employeeInfo) => {
    // Convert entire employee structure, including nested objects like Business
    return {
      ...employeeInfo,
      Business: employeeInfo.Business ? {id: employeeInfo.Business.id }: null,
       Timesheets: employeeInfo.Timesheets?.map(ts => ({
        ...ts,
        clockInImage: ts.clockInImage ? { _url: ts.clockInImage._url } : null,
        breakImage: ts.breakImage ? { _url: ts.breakImage._url } : null,
        secondClockInImage: ts.secondClockInImage ? { _url: ts.secondClockInImage._url } : null,
        clockOutImage: ts.clockOutImage ? { _url: ts.clockOutImage._url } : null,
        // Include other transformations as necessary
      })),
    };
};