import React, { useState } from 'react';
import { Grid, Typography, Card, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CalculateIcon from '@mui/icons-material/Calculate';
import FetchIcon from '@mui/icons-material/Search';

const AnimatedBackground = styled('div')(({ theme }) => ({
  background: 'linear-gradient(45deg, #8b65c2, #E1BEE7)',
  backgroundSize: '400% 400%',
  animation: 'gradient 15s ease infinite',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  color: theme.palette.getContrastText('#8b65c2'),
  '@keyframes gradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
}));

const ActionCard = styled(Card)(({ theme }) => ({
  transform: 'translateZ(0)',
  transition: 'transform 0.25s ease-out',
  '&:hover': {
    transform: 'translateY(-8px) scale(1.05)',
    boxShadow: theme.shadows[10],
  },
}));

const DragDropArea = styled('div')(({ theme }) => ({
  border: '2px dashed #7045af',
  borderRadius: theme.shape.borderRadius,
  minHeight: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  margin: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const actions = [
  { id: 1, text: 'Update Recipe', icon: <UpdateIcon />, color: 'primary.main' },
  { id: 2, text: 'Delete Recipe', icon: <DeleteIcon />, color: 'error.main' },
  { id: 3, text: 'Save Recipe from chat', icon: <SaveIcon />, color: 'secondary.main' },
  { id: 4, text: 'Calculate Recipe Cost', icon: <CalculateIcon />, color: 'success.main' },
  { id: 5, text: 'Fetch Recipe Cost', icon: <FetchIcon />, color: 'warning.main' },
];

const ActionsAndPlanning = () => {
  const [comingSoonActive, setComingSoonActive] = useState(false);

  const handleClick = () => {
    setComingSoonActive(true);
    setTimeout(() => {
      setComingSoonActive(false);
    }, 5000);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AnimatedBackground>
        <Typography variant="h4" gutterBottom>
          Actions & Planning
        </Typography>
        {comingSoonActive ? (
          <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 4 }}>Coming Soon</Typography>
        ) : (
          <>
            <Grid container spacing={2}>
              {actions.map((action) => (
                <Grid item xs={6} sm={3} key={action.id}>
                  <ActionCard onClick={handleClick}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
                      <Box sx={{ color: action.color }}>{action.icon}</Box>
                      <Typography variant="subtitle1">{action.text}</Typography>
                    </Box>
                  </ActionCard>
                </Grid>
              ))}
            </Grid>
            <DragDropArea>
              <Typography variant="h6">Drag Commands Here</Typography>
            </DragDropArea>
          </>
        )}
      </AnimatedBackground>
    </Box>
  );
};

export default ActionsAndPlanning;
