// MainComponent.js
import React, { useState, useEffect } from 'react';
import ManagerAppBar from './ManagerBar'; // Adjust the import path as necessary
import ManagerTimesheetComponent from "./Manager"; // Adjust the import path as necessary
import PayrollComponent from './PayrollComponent'; // Adjust the import path as necessary
import { calculatePaymentCycles } from "./hooks/calculateEndDate";

import { useAppSelector } from "../../redux/store";
import { useParseSDK } from 'hooks/useParseSDK';
import { useEmployeeState } from '@reduxHooks/useEmployeeInfoState';
import { useEmployeeInfo } from '@api/employeeInfo';



const MainComponent = () => {
  const {Parse} =useParseSDK();
  const {timesheetState}=useEmployeeState();
 const {fetchEmployeeInfo}=useEmployeeInfo();

  const [isPayrollView, setIsPayrollView] = useState(false);
  const [filteringCycle, setFilteringCycle] = useState('');
  const [sortedCycles, setSortedCycles] = useState([]); // This should come from the logic that calculates cycles

  const [paymentCycle, setPaymentCycle] = useState("weekly");
  const [paymentCycleStartDate, setPaymentCycleStartDate] = useState(
    new Date()
  );
  

  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user?.map((user) => user.business.id);
  const business= user?.map((user)=> user.business);
  const businessId = Userbusiness[0];
  const userData = user?.map((u) => u.user);
  const userId = userData[0];
  const pc = business?.map((b)=>b.paymentCycle);
  const businessPaymentCycle= pc[0];
  const pd= business?.map((b)=>b.paymentCycleStartDate);
  const businessPaymentDate=new Date(pd[0]) ;


  // Logic to calculate payment cycles, this could be a function imported from elsewhere

  useEffect(() => {
    const paymentCycles = calculatePaymentCycles(
      businessPaymentCycle,
      businessPaymentDate
    );

    // const relevantCycles = getRelevantCycles(paymentCycles);
    const cycleLabels = paymentCycles?.map((cycle) => cycle.label);

    setSortedCycles(cycleLabels);
  }, [businessPaymentCycle]);


 const toggleView = () => {
    setIsPayrollView(!isPayrollView);
  };

  



useEffect(()=>{
  fetchEmployeeInfo();
},[]);


  return (
    <div>
      <ManagerAppBar
        onToggleView={toggleView}
        isPayrollView={isPayrollView}
        filteringCycle={filteringCycle}
        setFilteringCycle={setFilteringCycle}
        sortedCycles={sortedCycles}
        paymentCycle={businessPaymentCycle}
        paymentCycleStartDate={ businessPaymentDate}
        setPaymentCycle={setPaymentCycle}
        setPaymentCycleStartDate={setPaymentCycleStartDate}
      />
      {isPayrollView ? (
        <PayrollComponent 
        timesheetCycle={filteringCycle}
        employeeData={timesheetState.employeeInfo} />
      ) : (
        <ManagerTimesheetComponent
          filteringCycle={filteringCycle}
          setFilteringCycle={setFilteringCycle}
          sortedCycles={sortedCycles}
          employeeData={timesheetState.employeeInfo}
        />
      )}
     
    </div>
  );
};

export default MainComponent;
