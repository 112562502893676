import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
  Avatar,
} from "@mui/material";
// Import Icons
import BookIcon from "@mui/icons-material/Book";
import FolderIcon from "@mui/icons-material/Folder";
import PeopleIcon from "@mui/icons-material/People";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LogoutIcon from "@mui/icons-material/Logout";
import CashIcon from "@mui/icons-material/AttachMoney";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
//Jalal/ Import the close icon
//Jalal/ Are we changing this, or keeping it?
import { MdChevronLeft } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useParseSDK } from "hooks/useParseSDK";
import logoIcon from "assets/images/logo-icon.png"; // Adjust the path as necessary
import InstallPWAButton from "./InstallPWAButton";

const { Parse } = useParseSDK();

const drawerWidth = 50;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth * 5,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerWidth, //`calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth, // `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  offline?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "offline",
})<AppBarProps>(({ theme, open, offline }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: offline ? "#b71c1c" : "#2a2a2a",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth * 5,
    width: `calc(100% - ${drawerWidth * 5}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const MiniDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const [isOffline, setIsOffline] = React.useState(!navigator.onLine);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = async () => {
    await Parse.User.logOut();
    navigate("/");
  };

  React.useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOffline(!navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  // Function to get a random color
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} offline={isOffline}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}>
            <FiMenu style={{ color: "#fff", fontSize: 24 }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            OMAR {isOffline && "(Offline)"}
          </Typography>
          <InstallPWAButton></InstallPWAButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ bgcolor: "#2a2a2a" }}>
          <IconButton onClick={handleDrawerClose}>
            <MdChevronLeft style={{ color: "#fff", fontSize: 24 }} />
            {/* brandColor.gray */}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              text: "Recipe",
              path: "/dashboard",
              icon: <BookIcon />,
              color: "#005A9E", // Dark blue
            },
            {
              text: "Stock",
              path: "/dashboard/stock",
              icon: <FolderIcon />,
              color: "#2E8540", // Dark green
            },
            {
              text: "Supplier Management",
              path: "/dashboard/suppliers",
              icon: <TrendingUpIcon />,
              color: "#984F00", // Dark orange
            },
            {
              text: "Expenses",
              path: "/dashboard/expenses",
              icon: <WalletIcon />,
              color: "#4C2C69", // Dark purple
            },
            {
              text: "Timesheet Wizard",
              path: "/dashboard/timesheet-wizard",
              icon: <PeopleIcon />,
              color: "#C8102E", // Dark red
            },
            {
              text: "Cost Wizard",
              path: "/dashboard/cost-wizard",
              icon: <CashIcon />,
              color: "#385F71", // Dark teal
            },
            {
              text: "Production Screen",
              path: "/dashboard/production-list",
              icon: <ListIcon />,
              color: "#FFA500", // Gold
            },
            // Add more items as needed
          ].map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                component={Link}
                to={item.path}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: item.color,
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0, color: item.color }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Divider />
        <List>
          {[
            {
              text: "Profile Screen",
              path: "/dashboard/profile",
              icon: <PersonIcon />,
              color: "#777777", // Dark gray
            },
            {
              text: "OMAR Chatbot",
              path: "/dashboard/omar-ai",
              icon: <Avatar sx={{ bgcolor: "white" }} src={logoIcon} />,
              color: "#777777", // Dark gray
            },

            // Add more items as needed
          ].map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                component={Link}
                to={item.path}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: item.color,
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0, color: item.color }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Divider />
        {/* Logout Button at the bottom */}
        <Box>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                bgcolor: "red",
              }}
              onClick={logout} // Call the logout function when the button is clicked
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  color: "white",
                  justifyContent: "center",
                }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0, color: "white" }}
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
