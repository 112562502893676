import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Stock Interface as received from the database
export interface Stock {
  id: string;
  Category: string;
  Name: string;
  Price: number;
  Quantity: number;
  Unit: string;
  businessId: string;
}

// Initial State Type and Definition
type StockStateType = {
  stock: Record<string, Stock[]>;
};

const initialState: StockStateType = {
  stock: {},
};

// Stock Redux Slice
const stockSlice = createSlice({
  name: "stockState",
  initialState,
  reducers: {
    setStock: (state, action: PayloadAction<Record<string, Stock[]>>) => {
      state.stock = action.payload;
    },
    // Add more reducers as needed
  },
});

export const { setStock } = stockSlice.actions;
export default stockSlice.reducer;
