import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { Dialog, DialogActions, DialogContent, DialogTitle, AppBar,TextField,Button, Toolbar, Typography, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead,Link, TableRow, IconButton,  Menu, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useSuppliersState } from "@reduxHooks/useSupplierState";
import { useInvoicesState } from '@reduxHooks/useInvoicesState';
import axios from "axios";
import { ArrowBack } from '@mui/icons-material'; // Import ArrowBack icon
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import PropTypes from 'prop-types'; // Import PropTypes
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateSupplier } from '@api/CRUDOpSupplier';
import { useFetchSuppliers } from '@api/suppliers';
import ResponseModal from '@components/success/errorModal';



// Define a custom styled component for sticky table headers
const StickyTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper, // Ensure the background is not transparent
  position: 'sticky',
  top: 0,
  zIndex: 2, // Ensure it stays above other content when scrolling
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)', // Optional: adds a shadow to the sticky header
}));

const getUniqueStockItems = (stockItems) => {
  const uniqueItems = [];
  const uniqueKeys = new Set();

  stockItems.forEach(item => {
    // Create a unique key for each item. Adjust this based on which properties define uniqueness.
    const uniqueKey = `${item.name}_${item.category}`;

    if (!uniqueKeys.has(uniqueKey)) {
      uniqueItems.push(item);
      uniqueKeys.add(uniqueKey);
    }
  });

  return uniqueItems;
};


const calculateDifferences = (selectedItem, invoices) => {
  let allMatchingItems = [];

  // Collect all matching items across all invoices
  invoices.forEach(invoice => {
    invoice.stock.forEach(stockItem => {
      if (stockItem.name === selectedItem.name &&
          stockItem.category === selectedItem.category &&
          stockItem !== selectedItem) {
        allMatchingItems.push({ ...stockItem, invoiceDate: invoice.date });
      }
    });
  });

  // Sort all matching items by their invoice date in ascending order
  

  // The oldest matching item is now the first item in the sorted list, if any exist
  const oldestMatchingItem = allMatchingItems.length > 0 ? allMatchingItems.sort((a, b) => new Date(a.date) - new Date(b.date))[0] : null;

// The newest matching item is the last item in the sorted list, if any exist
const newestMatchingItem = allMatchingItems.length > 0 ? allMatchingItems[allMatchingItems.length - 1] : null;

if (oldestMatchingItem) {
    // Calculate the price difference as selected item's price minus the oldest matching item's price
    const priceDifference = newestMatchingItem.price - oldestMatchingItem.price;
    // Calculate the quantity difference
    const quantityDifference = newestMatchingItem.quantity - oldestMatchingItem.quantity;

    return { priceDifference, quantityDifference };
  } else {
    return { priceDifference: null, quantityDifference: null };
  }
};



const prepareChartData = (selectedItem, stockItems) => {
  // Filter stock items to get all instances of the selected item
  const itemInstances = stockItems.filter(item => 
    item.name === selectedItem.name && item.category === selectedItem.category
  );

  // Reverse the array to ensure the earliest instances are first
  // Only do this if your data is indeed in reverse chronological order
  const orderedInstances = [...itemInstances]; // Creates a reversed copy of the array

  const data = {
    labels: orderedInstances.map((_, index) => `Purchase ${index + 1}`),
    datasets: [
      {
        label: `${selectedItem.name} Price Changes`,
        data: orderedInstances.map(item => item.price),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  return data;
};

function SupplierProfile() {
  const { supplierId } = useParams(); // Use useParams to get the supplier ID from the URL
  const { invoicesState } = useInvoicesState();
  const { suppliersState } = useSuppliersState();
  const supplier = suppliersState.suppliers.find(supplier => supplier.id.toString() === supplierId);
  const hasFetchedData = useRef(false);
  const [showSuccess, setShowSuccess] = useState(false); // State for showing success animation
  const [showError, setShowError] = useState(false); // State for showing error animation
  const [showAnim,setSHowAnim] =useState(false);

  const { fetchSupplier } = useFetchSuppliers();

  const fetchData = useCallback(() => {
    if (!hasFetchedData.current) {
      fetchSupplier();
      hasFetchedData.current = true;
    }
  }, [fetchSupplier]);



  const [address, setAddress] = useState("");
  const [selectedStockItem, setSelectedStockItem] = useState(null);
  const [differences, setDifferences] = useState({ priceDifference: null, quantityDifference: null });
 // State for managing Menu open/close
 const [anchorEl, setAnchorEl] = useState(null);
 const isMenuOpen = Boolean(anchorEl);
 const { Name, ContactNumber, Email, SalesRep, Location, Invoices, StockItems, Certificates, AccountPaid } = supplier || {};
 const extractAndShortenFileName = (fileName, maxLength = 20) => {
  // Extract the descriptive part of the file name after the last underscore
  const descriptiveName = fileName;
  
  // Check if the descriptive name contains randomly generated characters at the beginning
  const pattern = /^[a-f\d]{32}_/; // Matches 32 hexadecimal characters followed by an underscore at the beginning
  const modifiedName = descriptiveName.replace(pattern, '');
  
  // Shorten the modified name if it exceeds the maxLength
  if (modifiedName.length > maxLength) {
    return `${modifiedName.substring(0, maxLength - 3)}...`;
  }
  
  return modifiedName;
};


function DisplayCertificates({ certificates }) {
  // Helper function to determine if the URL is for a PDF
  const isPdf = (url) => /\.pdf$/i.test(url);

  // Function to handle the download
  async function handleDownload(url, filename) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
      const blob = await response.blob();

      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); // Set the filename for the download
      document.body.appendChild(link); // Append to the document
      link.click(); // Trigger the download
      
      // Cleanup: remove the link and revoke the object URL to free up resources
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download error:', error);
      alert(`Error downloading the file: ${error.message}`);
    }
  }
  
  

  return (
    <div style={{ display: 'flex', overflowX: 'auto' }}>
      {certificates?.map((certificate, index) => {
        const originalFileName = certificate._name || `Certificate_${index + 1}`|| certificate.name;
        const fileName = extractAndShortenFileName(originalFileName);
        const fileUrl = certificate._url;
        const isPdfFile = isPdf(fileUrl);

        return (
          <div key={index} style={{ minWidth: '160px', margin: '8px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Display an icon based on the file type */}
            <div style={{ cursor: 'pointer' }} onClick={() => handleDownload(fileUrl, originalFileName)}>
              {isPdfFile ? (
                <PictureAsPdfIcon style={{ fontSize: '48px' }} />
              ) : (
                <ImageIcon style={{ fontSize: '48px' }} />
              )}
            </div>
            <div
              onClick={() => handleDownload(fileUrl, originalFileName)}
              style={{ marginTop: '8px', cursor: 'pointer', fontSize: '0.9rem', wordWrap: 'break-word', maxWidth: '140px' }}
            >
              {fileName}
            </div>
          </div>
        );
      })}
    </div>
  );
}

// Define prop types for DisplayCertificates
DisplayCertificates.propTypes = {
  certificates: PropTypes.arrayOf(PropTypes.shape({
    _url: PropTypes.string.isRequired,
    _name: PropTypes.string,
  })),
};

 const { handleSubmit, control, setValue, getValues } = useForm({
  defaultValues: {
    Name: Name,
    ContactNumber: ContactNumber,
    Email: Email,
    SalesRep: SalesRep,
    Certificates: Certificates, // Initialize with an empty array or existing files if needed
  }
});

// Function to handle file addition
const handleAddFiles = (files) => {
  const existingFiles = getValues('Certificates');
  const updatedFiles = [...existingFiles, ...files];
  setValue('Certificates', updatedFiles); // Update the form state with the new list of files
};

// Function to handle file removal (example: by index)
const handleRemoveFile = (index) => {
  const existingFiles = getValues('Certificates');
  const updatedFiles = existingFiles.filter((_, i) => i !== index);
  setValue('Certificates', updatedFiles); // Update the form state with the filtered list of files
};

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  // Other useState hooks and useEffects...

  // Handler to open the edit dialog
  const handleEditOpen = () => {
    setIsEditDialogOpen(true);
  };

  // Handler to close the edit dialog
  const handleEditClose = () => {
    setIsEditDialogOpen(false);
  };

  // Adjusted handleSave to log form data
  const handleSave =async (formData) => {
    // Assuming form fields are named according to your state (e.g., Name, ContactNumber, etc.)
   try{ 
    await updateSupplier(supplier.id,formData);
   
    handleEditClose(); 
    setSHowAnim(true);
    setShowSuccess(true);
    fetchSupplier();
    setTimeout(() => {
      setShowSuccess(false);
      setSHowAnim(false);
    }, 2000);
   
  }catch (error) {
    setSHowAnim(true);
    setShowError(true);
    setTimeout(() =>{ setShowError(false);  setSHowAnim(false);}, 2000);
  }
  // Close the dialog after logging data or saving
  };

 // Handler to open the menu
 const handleMenuOpen = (event) => {
   setAnchorEl(event.currentTarget);
 };

 // Handler to close the menu
 const handleMenuClose = () => {
   setAnchorEl(null);
 };

  const navigate = useNavigate(); // Hook for navigation

  const matchedInvoices = invoicesState.invoices.filter(invoice => Invoices && Invoices.includes(invoice.id));

  const googleSearchLink = (lat, long) => {
    if (lat !== null && long !== null) {
      return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    }
    return "#";
  };

  useEffect(() => {
    if (Location) {
      const fetchAddress = async () => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${Location.latitude}&lon=${Location.longitude}`
          );
          const fullAddress = response.data;
          const { house_number, road, suburb, state, postcode } =
            fullAddress.address;
          const simplifiedAddress = `${
            house_number ? house_number + ", " : ""
          }${road}, ${suburb}, ${state}, ${postcode}`;
          setAddress(simplifiedAddress);
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };
      fetchAddress();
    }
  }, [Location]);

  if (!supplier) {
    return <Typography>Supplier not found</Typography>;
  }

  return (
    <>
     <ResponseModal show={showAnim} setShow={setSHowAnim} success={showSuccess} errorResponse={showError}/>
     <AppBar position="static" sx={{ bgcolor: "#E1BEE7", borderRadius: 5 }}>
  <Toolbar>
    <IconButton aria-label="go back" onClick={() => navigate(-1)} edge="start">
      <ArrowBack />
    </IconButton>
    <Typography variant="h6" sx={{ flexGrow: 1 }}>
      {Name}
    </Typography>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleMenuOpen}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
      {/* Add more menu items here as needed */}
    </Menu>
  </Toolbar>
</AppBar>

      <Grid container spacing={2} padding={2}>
        {/* Contact Information */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Contact Information</Typography>
              <Typography sx={{ mt: 1 }}>Contact Number: {ContactNumber}</Typography>
              <Typography sx={{ mt: 1 }}>Email: {Email}</Typography>
              <Typography sx={{ mt: 1 }}>Sales Representative: {SalesRep}</Typography>
              <Typography sx={{ mt: 1 }}>
               Location:{" "}
               <Link href={googleSearchLink(Location.latitude,Location.longitude)} target="_blank">
                {address}
                 </Link>
               </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Location - Consider replacing with a map if applicable */}
        <Grid item xs={12} sm={5}>
          <Card sx={{maxHeight:200, height:200}}>
            <CardContent>
              <Typography variant="h5">Certificates</Typography>
             <DisplayCertificates certificates={Certificates}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{ maxHeight: 200, height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography gutterBottom variant="h6" component="div" sx={{ width: '100%', textAlign: 'center' }}>
                Account Paid
              </Typography>
              <Typography variant="h4" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                ${AccountPaid}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    
      
      <Grid container spacing={2} padding={2}>
        {/* Stock Items */}
        <Grid item xs={12} lg={selectedStockItem ? 4:6}>
          <Card  sx={{maxHeight:500, height:500,  overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },}}>
            <CardContent>
              <Typography variant="h5">Stock Items</Typography>
              <Table>
              <TableHead>
                <TableRow>
                  {/* Use StickyTableCell instead of TableCell for sticky headers */}
                  <StickyTableCell>Name</StickyTableCell>
                  <StickyTableCell>Category</StickyTableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  {getUniqueStockItems(StockItems)?.map((item) => (
                     <TableRow 
                     key={item.id} 
                     onClick={() => {
                      setSelectedStockItem(selectedStockItem === item ? null : item);
                       const diffs = calculateDifferences(item, invoicesState.invoices);
                       setDifferences(diffs); // Store the calculated differences
                       console.log(differences);
                     }}
                     style={{ cursor: 'pointer' }}
                   >
                     <TableCell>{item.name}</TableCell>
                     <TableCell>{item.category}</TableCell>
                   </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        {/* Conditional rendering based on selectedStockItem */}
      
        {selectedStockItem && (
        <Grid item xs={12} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Changes for {selectedStockItem.name}</Typography>
              <Typography>
                Price Change:{" "}
                <span style={{ color: differences.priceDifference > 0 ? 'lightgreen' : differences.priceDifference < 0 ? 'red' : 'black' }}>
                  {differences.priceDifference !== 0 ? (differences.priceDifference > 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
                  {Math.abs(differences.priceDifference).toFixed(2)}
                </span>
              </Typography>
              <Typography>
                Quantity Change:{" "}
                <span style={{ color: differences.quantityDifference > 0 ? 'lightgreen' : differences.quantityDifference < 0 ? 'red' : 'black' }}>
                  {differences.quantityDifference !== 0 ? (differences.quantityDifference > 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : null}
                  {Math.abs(differences.quantityDifference).toFixed(2)}
                </span>
              </Typography>
              <Card sx={{marginTop:5.5}}>
              <CardContent>
                <Typography variant="h5">Price Changes for {selectedStockItem.name}</Typography>
                {/* Render the line graph */}
                <Line data={prepareChartData(selectedStockItem, StockItems)} />
              </CardContent>
            </Card>
            </CardContent>
          </Card>
        </Grid>
      )}



      <Grid item xs={12} lg={selectedStockItem ? 4:6}>
          <Card sx={{maxHeight:500, height:500,  overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },}}>
            <CardContent>
              <Typography variant="h5">Invoices</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Invoice Number</TableCell>
                    <TableCell>Last Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matchedInvoices.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <StickyTableCell>{invoice.date}</StickyTableCell>
                      <StickyTableCell>{invoice.invNumber}</StickyTableCell>
                      <StickyTableCell>{invoice.lastDate}</StickyTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        </Grid>
        <Dialog open={isEditDialogOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Supplier Information</DialogTitle>
        <form onSubmit={handleSubmit(handleSave)}> 
        <DialogContent>
          <Grid container spacing={2}>
            {/* Form fields for editing */}
            <Grid item xs={12}>
  {/* Supplier Name Field */}
  <Controller
    name="Name"
    control={control}
    defaultValue={Name} // Use the state or prop value as the default value
    render={({ field }) => (
      <TextField
        {...field}
        autoFocus
        margin="dense"
        id="name"
        label="Supplier Name"
        type="text"
        fullWidth
      />
    )}
  />

  {/* Contact Number Field */}
  <Controller
    name="ContactNumber"
    control={control}
    defaultValue={ContactNumber} // Use the state or prop value as the default value
    render={({ field }) => (
      <TextField
        {...field}
        margin="dense"
        id="contactNumber"
        label="Contact Number"
        type="text"
        fullWidth
      />
    )}
  />

  {/* Email Address Field */}
  <Controller
    name="Email"
    control={control}
    defaultValue={Email} // Use the state or prop value as the default value
    render={({ field }) => (
      <TextField
        {...field}
        margin="dense"
        id="email"
        label="Email Address"
        type="email"
        fullWidth
      />
    )}
  />

  {/* Sales Representative Field */}
  <Controller
    name="SalesRep"
    control={control}
    defaultValue={SalesRep} // Use the state or prop value as the default value
    render={({ field }) => (
      <TextField
        {...field}
        margin="dense"
        id="salesRep"
        label="Sales Representative"
        type="text"
        fullWidth
      />
    )}
  />
  {/* Implement other fields as needed */}
          </Grid>

          <Grid item xs={12}>
            {/* Display already uploaded certificates
            {Certificates.map((certificate, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <Typography>{certificate._name}</Typography>
                {/* Add options to view/download or delete the certificate if needed
              </div>
            ))} */}

            {/* File Input for uploading new certificates */}
            <Controller
              name="Certificates"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleAddFiles([...e.target.files])} // Add selected files to the form state
                    style={{ display: 'block', marginBottom: '20px' }}
                  />
                  {field.value.map((file, index) => (
                    <div key={index}>
                      {file.name||extractAndShortenFileName(file?._name)} {/* Display file name */}
                      <button type="button" onClick={() => handleRemoveFile(index)}>
                        Remove
                      </button>
                    </div>
                  ))}
                </>
              )}
            />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default SupplierProfile;

