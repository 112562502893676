import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Grid, Divider, Paper, Typography,} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppSelector } from 'redux/store';
import { saveSupplierData } from '@api/CRUDOpSupplier';


// Define the validation schema using yup
const validationSchema = yup.object({
  name: yup.string().required('Supplier name is required'),
  contactNumber: yup.string().required('Contact number is required'),
  salesRep: yup.string().required('Sales Rep Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  unitNumber: yup.string(),
  streetAddress1: yup.string().required('Street address is required'),
  suburb: yup.string().required('Suburb is required'),
  state: yup.string().required('State is required'),
  postcode: yup.string().required('Postcode is required'),
});

const apiKey = process.env.REACT_APP_GOOGLE_GEO_CODE_API_ID; 

const SupplierForm = ({setDrawer, isSuccess,isError}) => {
  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const businessId = Userbusiness[0];

  // Function to handle form submission
  const onSubmit = async (data) => {
    // Destructure the needed fields from the form data
    const { contactNumber,salesRep, email, name, unitNumber, streetAddress1, suburb, state, postcode ,files} = data;
  
    // Combine address parts into a single string for geocoding
    const address = `${unitNumber ? unitNumber + ' ' : ''}${streetAddress1}, ${suburb}, ${state}, ${postcode}, Australia`;
  
    // Construct the URL for the Google Maps Geocoding API request

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const results = await response.json();
  
      if (results.status === "OK" && results.results.length > 0) {
        const { lat, lng } = results.results[0].geometry.location;
  
        // Construct the finalData object with only the specified fields and location
        const finalData = {
          contactNumber,
          email,
          name,
          salesRep,
          location: { lat, lng },
          files,
          businessId,
        };
  
     
        // Proceed to use finalData as needed, e.g., storing it in a database or sending to a server
        await saveSupplierData(finalData);
        isSuccess(true);
        setDrawer(false);
      } else {
        isError(true);
   
      }
    } catch (error) {
      console.error("Error fetching geocoding results:", error);
    }
  };
  
 
  
  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Add New Supplier
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Supplier Name"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
          <Controller
              name="salesRep"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sales Rep Name"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.salesRep}
                  helperText={errors.salesRep ? errors.salesRep.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contactNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.contactNumber}
                  helperText={errors.contactNumber ? errors.contactNumber.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Controller
              name="unitNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Unit Number (Optional)"
                  variant="outlined"
                  fullWidth
                  error={!!errors.unitNumber}
                  helperText={errors.unitNumber ? errors.unitNumber.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={9}>
            <Controller
              name="streetAddress1"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.streetAddress1}
                  helperText={errors.streetAddress1 ? errors.streetAddress1.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="suburb"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Suburb"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.suburb}
                  helperText={errors.suburb ? errors.suburb.message : ''}
                />
              )}
            />
          </Grid>

          {/* State field */}
          <Grid item xs={12} sm={3}>
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="State"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.state}
                  helperText={errors.state ? errors.state.message : ''}
                />
              )}
            />
          </Grid>

          {/* Postcode field */}
          <Grid item xs={12} sm={4}>
            <Controller
              name="postcode"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postcode"
                  variant="outlined"
                  fullWidth
                  required
                  error={!!errors.postcode}
                  helperText={errors.postcode ? errors.postcode.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
          <Controller
            name="files"
            control={control}
            defaultValue={[]} // Default to an empty array
            render={({ field: { onChange, value } }) => (
              <input
                type="file"
                onChange={(e) => {
                  // Handle file selection
                  onChange(e.target.files); // Update the form state
                }}
                multiple // Allow multiple file selection
                style={{ display: 'block', marginBottom: '20px' }}
              />
            )}
          />

          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={()=>setDrawer(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SupplierForm;
