import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from "assets/images/logo-icon.png";
 // Adjust the path as necessary
import Lottie from 'lottie-react';
import AI from "assets/lotties/OMAR_AI_LOOK.json";

const MobileOnlyView = () => (
  <Box
    sx={{
      textAlign: 'center',
      padding: 2,
      backgroundColor: '#2a2a2a',
      height: '100vh', // Adjust the height as necessary
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff' // Assuming white text color for contrast
    }}
  >
   <Box
    sx={{
      position: 'relative',
      width: 200, // Adjust based on your requirements
      height: 200, // Adjust based on your requirements
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={logo} alt="Logo" style={{ width: '200%', height: '200%', objectFit: 'cover' }} />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Lottie animationData={AI} loop style={{ width: '90%', height: '90%' }} />
    </Box>
  </Box>
    <Typography variant="h6" gutterBottom>
      Hey there, it&apos;s OMAR!
    </Typography>
    <Typography variant="body1">
      I&apos;ve noticed you&apos;re on a mobile device. To ensure you get the full experience of all the amazing features I offer, I kindly ask you to switch to a computer. Trust me, it&apos;s worth it for the extra magic we can create together on a larger screen. Thank you for your understanding and cooperation!
    </Typography>
  </Box>
);

export default MobileOnlyView;
