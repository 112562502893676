import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Image = {
  _url: string; // The URL of the image
};


type Location = {
  lat: number;
  long: number;
};

type Timesheet = {
  id: string;
  clockInTime: string;
  breakTime: string;
  secondClockInTime: string;
  clockOutTime: string;
  breakState: number;
  totalState: number;
  clockInImage: Image | null;
  breakImage: Image | null;
  secondClockInImage: Image | null;
  clockOutImage: Image | null;
  status: string;
  clockIn1Loc: Location | null;
  breakLoc: Location | null;
  clockIn2Loc: Location | null;
  clockOutLoc: Location | null;
  date: Date | string; // Use 'Date' or 'string' depending on how the date is handled
};

type Employee = {
  id: string;
  username: string;
  email: string;
  isVerified: boolean;
  firstName: string;
  lastName: string;
};

type Business = {
  id: string; // Assuming the Pointer type can be represented by an ID string

};

type EmployeeInfo = {
  id: string;
  employee: Employee;
  Wage: number;
  Timesheets: Timesheet[];
  Business: Business;
  Active: boolean;
};

type TimesheetStateType = {
  employeeInfo: EmployeeInfo[];
};

const initialState: TimesheetStateType = {
  employeeInfo: [],
};

const timesheetSlice = createSlice({
  name: "timesheetState",
  initialState,
  reducers: {
  setEmployeeInfo: (state, action: PayloadAction<EmployeeInfo[]>) => {
      state.employeeInfo = action.payload;
    },
    // Add more reducers as needed for your application
  },
});

export const { setEmployeeInfo } = timesheetSlice.actions;
export default timesheetSlice.reducer;
