import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./ManagerTimesheetComponent.css"; // Consider using SASS/SCSS for better style management
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Grid,
  Container,
  CardMedia,
  Link,
  Modal,
  Backdrop,
  Fade,
  Box,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  TextField,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { brandColors } from "../../brandColors"; // Ensure this is consistently used across the app for branding
import { useParseSDK } from "hooks/useParseSDK";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Cancel";
import { useEmployeeInfo } from "@api/employeeInfo";

const localizer = momentLocalizer(moment);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ManagerTimesheetComponent = ({
  filteringCycle,
  setFilteringCycle,
  sortedCycles,
  employeeData,
}) => {
  const { Parse } = useParseSDK();

  const { fetchEmployeeInfo } = useEmployeeInfo();
  // const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("name");
  const [selectedFilter, setSelectedFilter] = useState("All");

  const [selectedTimesheet, setSelectedTimesheet] = useState({});
  const [isEditingDate, setIsEditingDate] = useState(false);
  const prevIsEditingDate = usePrevious(isEditingDate);
 
  const [tempTime, setTempTime] = useState("");
  const [isEditingWage, setIsEditingWage] = useState(false);
  const prevIsEditingWage = usePrevious(isEditingWage);
  const [tempWage, setTempWage] = useState("");
  const [address, setAddress] = useState("");

  const [breakDuration, setBreakDuration] = useState({});
  const [totalWorkDuration, setTotalWorkDuration] = useState({});
  const [open, setOpen] = useState(false);
  const hasFetchedData = useRef(false);
  
  const fetchData = useCallback(() => {
    if (!hasFetchedData.current) {
      fetchEmployeeInfo();
      hasFetchedData.current = true;
    }
  }, [fetchEmployeeInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  
  const filteredData = employeeData?.filter(
    (employee) =>
      employee?.employee?.firstName?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      employee?.employee?.lastName?.toLowerCase()
        .includes(searchTerm.toLowerCase())||"employee"
  );

  const sortedData = filteredData?.sort((a, b) => {
    if (sortOption === "name") {
      // Provide default values to avoid undefined
      let nameA = a.employee?.firstName || "employee";
      let nameB = b.employee?.firstName || "employee";
      return nameA.localeCompare(nameB);
    } else if (sortOption === "wage") {
      return a.Wage - b.Wage;
    } else {
      return a.Active === b.Active ? 0 : a.Active ? -1 : 1;
    }
  });
  

  useEffect(() => {
    // This condition checks if either mode was true and has just been set to false
    if ((prevIsEditingDate && !isEditingDate)||(prevIsEditingWage && !isEditingWage) ){
      // Fetch user data logic here
      fetchEmployeeInfo();
   
    }
  }, [isEditingDate,  prevIsEditingDate,prevIsEditingWage , isEditingWage]);

  const selectEmployee = async (employee) => {
    setSelectedEmployee(employee);

    setSelectedTimesheet({});
  };

  const selectTimesheet = (timesheet) => {
    setSelectedTimesheet(timesheet);

    setOpen(true);
  };

  const handleEventClick = (timesheet) => {
    selectTimesheet(timesheet);
  };

  const onSelectEvent = useCallback((calEvent) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      handleEventClick(calEvent); // Trigger selectTimesheet function
    }, 250);
  }, []);

  const defaultDate = useMemo(() => new Date(), []);
  const clickRef = useRef(null); // Add this line to declare clickRef

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatEventsForCalendar = (timesheets) => {
    return timesheets?.map((timesheet) => ({
      id: timesheet.id,
      title: formatDate(timesheet.date),
      start: new Date(timesheet.date),
      end: new Date(timesheet.date),
      ...timesheet,
    }));
  };

  const [calendarDate, setCalendarDate] = useState(new Date()); // Calendar's current date
  const [startDate, setStartDate] = useState("");

  const handleNavigate = (date) => {
    // This function is called when the calendar's date changes
    setCalendarDate(date);
  };

  // When startDate or endDate changes, update the calendar view
  useEffect(() => {
    if (startDate) {
      // Logic to determine the appropriate date and view for the calendar
      // For example, set the view to the month that contains the startDate
      setCalendarDate(new Date(startDate));
      // Optionally, set the calendar view (day, week, month) based on your requirements
    }
  }, [startDate]);

  const renderTimesheets = (timesheets) => {
    const extractDatesFromCycle = (cycle) => {
      const regex = /\(([^)]+)\)/;
      const dates = regex.exec(cycle)[1].split(" - ");
      return dates?.map((dateStr) => new Date(dateStr));
    };

    const isDateWithinRange = (date, [startDate, endDate]) => {
      return date >= startDate && date <= endDate;
    };

    const filteredTimesheets = timesheets?.filter((timesheet) => {
      // Check if the status filter is applied and doesn't match the timesheet's status
      if (selectedFilter !== "All" && timesheet.status !== selectedFilter) {
        return false;
      }

      // Check if a specific cycle filter is applied and the timesheet date is within the cycle
      if (filteringCycle !== "All" && filteringCycle) {
        const [cycleStartDate, cycleEndDate] =
          extractDatesFromCycle(filteringCycle);
        if (
          !isDateWithinRange(new Date(timesheet.date), [
            cycleStartDate,
            cycleEndDate,
          ])
        ) {
          return false;
        }
      }

      // If none of the above conditions are met (meaning no filter is applied or the timesheet passes all applied filters), keep the timesheet
      return true;
    });

    const events = formatEventsForCalendar(filteredTimesheets);

    return (
      <div style={{ height: 500, marginTop: "20px" }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          date={calendarDate}
          onNavigate={handleNavigate}
          onSelectEvent={onSelectEvent}
          popup
        />
      </div>
    );
  };

  const saveWage = async (employee) => {
    const Employee_Info = Parse.Object.extend("Employee_Info");
    const query = new Parse.Query(Employee_Info);
    try {
      const result = await query.get(employee.id);
      result.set("Wage", Number(tempWage));
      await result.save();
      setIsEditingWage(false);
      setSelectedEmployee(null);
    } catch (error) {
      alert(`Error! ${error.message}`);
    }
  };

  const renderItem = (item) => (
    <>
      {item.employee.lastName ? (
        <Typography variant="h6">{`Employee: ${item.employee.firstName} ${item.employee.lastName}`}</Typography>
      ) : (
        <Typography variant="h6">{`Employee: ${item.employee.firstName} `}</Typography>
      )}

      <Typography>{`Username: ${item.employee.username}`}</Typography>
      <Typography>{`Email: ${item.employee.email}`}</Typography>
      <Typography>
        {item.Wage ? `Wage: ${item.Wage}$` : `Wage: N/A`}{" "}
        {isEditingWage ? (
          <>
            <TextField
              value={tempWage}
              onChange={(e) => setTempWage(e.target.value)}
              size="small"
              inputProps={{ maxLength: 6 }} // Restricts input to 6 characters (xxx.xx)
              style={{ width: "70px" }} // Set a specific width for the input
            />
            <IconButton
              onClick={() => {
                saveWage(item);
              }}
              size="small"
            >
              Save
            </IconButton>
            <IconButton onClick={() => setIsEditingWage(false)} size="small">
              Close
            </IconButton>
          </>
        ) : (
          <IconButton
            size="small"
            onClick={() => {
              setIsEditingWage(true);
              setTempWage(item.Wage || "");
            }}
          >
            <EditIcon size={20} color={brandColors.gray} />
          </IconButton>
        )}
      </Typography>
      <Typography variant="subtitle1">Timesheets:</Typography>
      <Button variant="outlined" onClick={() => setSelectedFilter("All")}>
        All
      </Button>
      <Button variant="outlined" onClick={() => setSelectedFilter("Confirmed")}>
        Confirmed
      </Button>
      <Button variant="outlined" onClick={() => setSelectedFilter("Pending")}>
        Pending
      </Button>
      <Button variant="outlined" onClick={() => setSelectedFilter("Rejected")}>
        Rejected
      </Button>
      <TextField
        label="Select Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ marginLeft: "10px" }}
      />

      {renderTimesheets(item.Timesheets)}
    </>
  );

  const renderEmployeeList = () => (
    <List component="nav">
      {sortedData?.map((item) => (
        <div key={item.id}>
          <ListItem button onClick={() => selectEmployee(item)}>
            <ListItemText
              primary={
                // If both firstName and lastName are available, use them; otherwise, use what's available or a default text
                item.employee.firstName || item.employee.lastName
                  ? `${item.employee.firstName || ""} ${item.employee.lastName || ""}`.trim()
                  : "Unnamed Employee"
              }
            />
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
  

  const renderSelectedEmployee = () => {
    if (!selectedEmployee) return <div>Select an employee to view details</div>;
    return renderItem(selectedEmployee);
  };

  useEffect(() => {
    if (selectedTimesheet && selectedTimesheet?.clockIn1Loc) {
      const fetchAddress = async () => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${selectedTimesheet?.clockIn1Loc.lat}&lon=${selectedTimesheet?.clockIn1Loc.long}`
          );
          const fullAddress = response.data;
          const { house_number, road, suburb, state, postcode } =
            fullAddress.address;
          const simplifiedAddress = `${
            house_number ? house_number + ", " : ""
          }${road}, ${suburb}, ${state}, ${postcode}`;
          setAddress(simplifiedAddress);
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };
      fetchAddress();
    }
  }, [selectedTimesheet]);

  const formatTime12Hour = (time) => {
    const formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");

    return formattedTime;
  };

  const saveTime = async (timesheet, label) => {
    const Timesheet = Parse.Object.extend("Timesheet");
    const query = new Parse.Query(Timesheet);

    // Map label to corresponding property
    const labelToProp = {
      "Clock In": "clockInTime",
      "Break Start": "breakTime",
      "Break Finish": "secondClockInTime",
      "Clock Out": "clockOutTime",
    };

    // Determine which property to update based on the label
    const labelProp = labelToProp[label];

    // Make sure the label provided is valid
    if (!labelProp) {
      alert("Invalid label provided.");
      return;
    }

    try {
      const result = await query.get(timesheet.id);
      result.set(labelProp, formatTime12Hour(tempTime)); 
      await result.save();
      setIsEditingDate(false);
    } catch (error) {
      alert(`Error! ${error.message}`);
    }
  };

  const calculateTimeDifference = (start, end) => {
    // Assuming the date is the same for both times and you're working with local times
    const currentDate = new Date().toDateString();

    // Convert 12-hour format to a full ISO string format
    const convertToISO = (timeStr) => {
      const [time, modifier] = timeStr.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return `${currentDate} ${hours}:${minutes}:00`;
    };

    const startDate = new Date(convertToISO(start));
    const endDate = new Date(convertToISO(end));
    const diffInMinutes = (endDate - startDate) / (1000 * 60);
    // Round to the nearest 15-minute interval
    const roundedDiffInMinutes = Math.round(diffInMinutes / 15) * 15;
    const diffInHours = roundedDiffInMinutes / 60;
    return diffInHours;
  };

  useEffect(() => {
    if (selectedEmployee && Array.isArray(selectedEmployee.Timesheets)) {
      // Temporary objects to hold the computed values
      const breakDurations = {};
      const totalWorkDurations = {};

      selectedEmployee.Timesheets.forEach((timesheet, index) => {
        // Compute break duration and total work duration
        const breakDurationComputed = calculateTimeDifference(
          timesheet.breakTime,
          timesheet.secondClockInTime
        );

        const totalWorkDurationComputed =
          calculateTimeDifference(
            timesheet.clockInTime,
            timesheet.clockOutTime
          ) - breakDurationComputed;

        // Use the index or a unique identifier from the timesheet as the key
        breakDurations[timesheet.id] = breakDurationComputed;
        totalWorkDurations[timesheet.id] = totalWorkDurationComputed;
      });

      // Set the computed values into states
      setBreakDuration(breakDurations);
      setTotalWorkDuration(totalWorkDurations);
    }
  }, [selectedEmployee]);

  const handleConfirmation = async (timesheet) => {
    const Timesheet = Parse.Object.extend("Timesheet");
    const query = new Parse.Query(Timesheet);
    try {
      const result = await query.get(timesheet.id);
      result.set("status", "Confirmed");
      result.set("breakState", breakDuration[timesheet.id]); // Assuming tempWage is defined in your scope
      result.set("totalState", totalWorkDuration[timesheet.id]);
      await result.save();
      setIsEditingDate(false);
      setSelectedTimesheet({});
      setSelectedEmployee(null);
      fetchEmployeeInfo();
    } catch (error) {
      alert(`Error! ${error.message}`);
    }
  };

  const handleRejection = async (timesheet) => {
    const Timesheet = Parse.Object.extend("Timesheet");
    const query = new Parse.Query(Timesheet);
    try {
      const result = await query.get(timesheet.id);
      result.set("status", "Rejected");

      await result.save();
      setIsEditingDate(false);
      setSelectedTimesheet({});
      setSelectedEmployee(null);
      fetchEmployeeInfo();
    } catch (error) {
      alert(error);
    }
  };

  const renderSelectedTimesheet = () => {
    const googleSearchLink = (lat, long) => {
      if (lat !== null && long !== null) {
        return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      }
      return "#"; // Return a placeholder or some default value if lat or long is null
    };

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    const handleConfirm = (idx) => {
      if (idx < 4) {
        if (sliderRef && sliderRef.current) {
          sliderRef.current.slickNext();
        }
      } else {
        handleConfirmation(selectedTimesheet);
      }
    };

    const handleReject = (idx) => {
      if (idx < 4) {
        setIsEditingDate(true);
      } else {
        handleRejection(selectedTimesheet);
      }
    };
    // Function to convert any time string to 'HH:mm' format
    const formatTimeToHHMM = (timeString) => {
      // This will handle both 24-hour and 12-hour formats automatically
      return moment(timeString, ["HH:mm:ss", "hh:mm A"]).format("HH:mm");
    };


    const handleEdit = (time) => {
      setIsEditingDate(true);
      setTempTime(formatTimeToHHMM(time)); // Initialize editing with the current time
    };

    const sliderRef = useRef(null);

    if (!selectedTimesheet || Object.keys(selectedTimesheet)?.length === 0) {
      return (
        <Typography variant="subtitle1" sx={{ p: 3 }}>
          Select a Timesheet to view details
        </Typography>
      );
    } else {
      return (
        <Box sx={{ p: 3, maxWidth: "600px", margin: "auto" }}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
            Timesheet for{" "}
            {selectedTimesheet?.date && formatDate(selectedTimesheet.date)}
          </Typography>

          <Slider {...settings} ref={sliderRef}>
            {[
              "Clock In",
              "Break Start",
              "Break Finish",
              "Clock Out",
              "Final Confirmation",
              // "Final Confirmation" is handled separately, as it doesn't display the same kind of data
            ].map((label, idx) => {
              let time, imgSrc, locationUrl;
              switch (idx) {
                case 0:
                  time = selectedTimesheet?.clockInTime;
                  imgSrc = selectedTimesheet?.clockInImage?._url;
                  locationUrl = selectedTimesheet?.clockIn1Loc
                    ? googleSearchLink(
                        selectedTimesheet?.clockIn1Loc.lat,
                        selectedTimesheet?.clockIn1Loc.long
                      )
                    : "Location unavailable";
                  break;
                case 1:
                  time = selectedTimesheet?.breakTime;
                  imgSrc = selectedTimesheet?.breakImage?._url;
                  locationUrl = selectedTimesheet?.breakLoc
                    ? googleSearchLink(
                        selectedTimesheet?.breakLoc.lat,
                        selectedTimesheet?.breakLoc.long
                      )
                    : "Location unavailable";
                  break;
                case 2:
                  time = selectedTimesheet?.secondClockInTime;
                  imgSrc = selectedTimesheet?.secondClockInImage?._url;
                  locationUrl = selectedTimesheet?.clockIn2Loc
                    ? googleSearchLink(
                        selectedTimesheet?.clockIn2Loc.lat,
                        selectedTimesheet?.clockIn2Loc.long
                      )
                    : "Location unavailable";
                  break;
                case 3:
                  time = selectedTimesheet?.clockOutTime;
                  imgSrc = selectedTimesheet?.clockOutImage?._url;
                  locationUrl = selectedTimesheet?.clockOutLoc
                    ? googleSearchLink(
                        selectedTimesheet?.clockOutLoc.lat,
                        selectedTimesheet?.clockOutLoc.long
                      )
                    : "Location unavailable";
                  break;
                default:
                // No default action
              }

              return (
                <Box
                  key={label}
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    {label}
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                  
                  {isEditingDate && idx !== 4 ? (
                    <>
                   
                      <TextField
                        label="Edit Time"
                        type="time"
                        value={tempTime}
                        onChange={(e) => setTempTime(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                      <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                        <IconButton
                          onClick={() => saveTime(selectedTimesheet, label)}
                          size="small"
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => setIsEditingDate(false)}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Location:{" "}
                        <Link href={locationUrl} target="_blank">
                          {address}
                        </Link>
                      </Typography>
                    </>
                  ) : (
                    <>
                      {idx !== 4 && (
                        <>
                      

                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Time: {time}
                          </Typography>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleEdit(time)}
                          >
                            Modify Time
                          </Button>
                          <Typography variant="body2" sx={{ mt: 1 }}>
                        Location:{" "}
                        <Link href={locationUrl} target="_blank">
                          {address}
                        </Link>
                      </Typography>
                        </>
                      )}
                    </>
                  )}
                  </Grid>
                  <Grid item xs={6}>
                  {idx < 4 && (
                   
                      <CardMedia
                        component="img"
                        image={
                          imgSrc || "default_timesheet_image_placeholder.jpg"
                        } // Placeholder if imgSrc is null
                        alt={`${label} snapshot`}
                        sx={{
                          height: "auto",
                          width: "100%",
                          maxWidth: "300px",
                          borderRadius: "4px",
                          mt: 1,
                        }}
                      />
                   )}
                </Grid>
                </Grid>
                  {idx === 4 ? (
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                      >
                        Confirm
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleReject}
                      >
                        Reject
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => sliderRef.current.slickNext()}
                      >
                        Next
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Slider>
        </Box>
      );
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} style={{ paddingTop: "20px" }}>
        {/* Employee List */}
        <Grid item xs={12} md={4} lg={2}>
          <Paper elevation={3} style={{ padding: "16px" }}>
            {renderEmployeeList()}
          </Paper>
        </Grid>

        {/* Selected Employee Details */}
        <Grid item xs={12} md={8} lg={10}>
          <Paper elevation={3} style={{ padding: "16px" }}>
            {renderSelectedEmployee()}
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={modalContentStyle}>{renderSelectedTimesheet()}</Box>
        </Fade>
      </Modal>
    </Container>
  );
};

const modalContentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%", // Adjust the width as per the design
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4, // Adjust the padding as per the design
  borderRadius: 2, // Optional: if you want rounded corners
  display: "flex",
  flexDirection: "column", // Stack children vertically
  alignItems: "center", // Center children horizontally
  gap: 2, // Space between children
  overflowY: "hidden", // Scroll inside modal if content is too long
  maxHeight: "80vh", // Adjust the max height as per the design
};

export default ManagerTimesheetComponent;