import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import RecipeCalendar from "./CalendarComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRecipeState } from "@reduxHooks/useRecipesState";
import { useInvoicesState } from "@reduxHooks/useInvoicesState";
import { convertUnits } from "utils/convertUnits";
import { Print } from "@mui/icons-material";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lotties/success_lottie.json";
import ErrorLottie from "../../assets/lotties/error_lottie.json";
import { handleSaveRecurringEvents , handleSaveSingleEvent, deleteEvents, deleteSingleEvent, updateScheduledRecipe} from "@api/CRUDOpSchedRecipes";
import { useAppSelector } from "../../redux/store";
import { useFetchSchedule } from "@api/scheduledRecipes";
import { useScheduledRecipesState } from "@reduxHooks/useScheduleSlice";

// Preprocess the stock data with convertUnits
function preprocessStockData(invoices) {
  return invoices.map((invoice) => {
    if (invoice.stock) {
      return {
        ...invoice,
        stock: invoice.stock.map((stockItem) => {
          // Convert the stockItem's unit and amount to the base unit
          const convertedAmount = convertUnits(
            stockItem?.quantity,
            stockItem?.unit,
            stockItem?.name
          );

          return {
            ...stockItem,
            quantity: convertedAmount?.value, // Update the amount to the converted value
            unit: convertedAmount?.unit, // Update the unit to the base unit
          };
        }),
      };
    }
    return invoice;
  });
}

// Define the deductIngredientsFromInvoices function here
function matchInvoicesToRecipes(recipes, invoices) {
  // Early return if recipes is empty or undefined
  if (!recipes || recipes.length === 0) {
    return {
      updatedInvoices: invoices, // Return the original invoices if there are no recipes
      deductions: [], // No deductions since there are no recipes
    };
  }

  // Deep copy of invoices to avoid modifying the original array
  let updatedInvoices = JSON.parse(JSON.stringify(invoices));

  // Initialize deductions array
  let deductions = [];

  // Define the deductIngredientsFromInvoices function here
  function deductIngredientsFromInvoices(recipe, ingredient, requiredAmount) {
    for (const invoice of updatedInvoices) {
      if (invoice.stock) {
        for (const stockItem of invoice.stock) {
          if (stockItem?.stockId === ingredient.stockId) {
            // Use the converted amount directly
            const convertedRequiredAmount = requiredAmount;

            // Calculate the remaining quantity
            const remainingQuantity =
              stockItem?.quantity - convertedRequiredAmount;

            // Ensure the remaining quantity doesn't go below zero
            const updatedQuantity = Math.max(0, remainingQuantity);

            // Update the stockItem amount
            stockItem.quantity = updatedQuantity;

            // Record the deduction in the deductions array
            deductions.push({
              ingredientName: ingredient.name,
              deductedAmount: convertedRequiredAmount,
              recipeId: recipe.id,
              unit: ingredient.unit,
            });
          }
        }
      }
    }
  }

  recipes.forEach((recipe) => {
    // Iterate over each recipe in the array
    recipe?.ingredients.forEach((ingredient) => {
      let requiredAmount = ingredient?.amount * recipe?.batches; // Adjust for batch size

      // Call the deductIngredientsFromInvoices function for each ingredient
      deductIngredientsFromInvoices(recipe, ingredient, requiredAmount);
    });
  });

  return {
    updatedInvoices: updatedInvoices,
    deductions: deductions,
  };
}

function createPDFHtmlTemplate(data, invoices) {
  const batchNumber = data.batchNumber;
  const batch = Number(data.batches);
  const date = data.date;
  const recipe = data.recipe;
  const ingredients = data.ingredients;

  // Check if batchNumber is defined and a string
  const validBatchNumber =
    batchNumber && typeof batchNumber === "string" ? batchNumber : "";

  // Calculate due dates
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const frozenDueDate = new Date(date);
  frozenDueDate.setMonth(frozenDueDate.getMonth() + 6);
  const formattedFrozenDueDate = frozenDueDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const refrigeratedDueDate = new Date(date);
  refrigeratedDueDate.setDate(refrigeratedDueDate.getDate() + 14);
  const formattedRefrigeratedDueDate = refrigeratedDueDate.toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  function getMatchingInvoices() {
    return invoices?.filter((invoice) => {
      const invoiceDate = new Date(invoice.date);
      const invoiceLastDate = new Date(invoice.lastDate);
      const dataDate = new Date(data.date);

      const isDateInRange =
        invoiceDate <= dataDate && dataDate <= invoiceLastDate;

      const isStockIdMatched = ingredients?.some((dataIngredient) =>
        invoice.stock?.some(
          (invoiceStock) => dataIngredient.stockId === invoiceStock.stockId
        )
      );

      return isDateInRange && isStockIdMatched;
    });
  }

  const matchingInvoices = getMatchingInvoices();
  // Get invoice numbers or display "N/A"
  const invoiceNumbers = matchingInvoices.length
    ? matchingInvoices?.map((invoice) => invoice.invNumber).join(", ")
    : "N/A";

  // Generate HTML
  const htmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          h1 {
            font-weight: bold;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          .details {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }
        </style>
      </head>
      <body>
        <h1>${recipe}</h1>
        <div class="details">
          <div class="date">
            Date: ${formattedDate}<br>
            Frozen Due Date: ${formattedFrozenDueDate}<br>
            Refrigerated Due Date: ${formattedRefrigeratedDueDate}
          </div>
          <div class="batchNumber">
            Batch Number: ${validBatchNumber}<br>
            Invoice Number: ${invoiceNumbers}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Ingredient</th>
              <th>Amount</th>
              <th>Amount(Used)</th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            ${ingredients
              ?.map(
                ({ name, amount, unit }) => `
                  <tr>
                    <td>${name}</td>
                    <td>${amount}</td>
                    <td>${amount * batch}</td>
                    <td>${unit}</td>
                  </tr>
                `
              )
              .join("")}
          </tbody>
        </table>
      </body>
    </html>
  `;

  return htmlContent;
}

function printToPDF(htmlContent) {
  const printWindow = window.open("", "_Production_List");
  printWindow.document.title = "Production List";
  printWindow.document.open();
  printWindow.document.write(htmlContent);

  //printWindow.document.write(css);
  printWindow.document.close();

  printWindow.onload = function () {
    // Wait for a short time to ensure the content is fully loaded
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 2000); // Adjust the delay as needed
  };
}

const ScheduledRecipesList = () => {
  const hasFetchedData = useRef(false);
  const { fetchSchedule } = useFetchSchedule();

  const fetchData = useCallback(() => {
    if (!hasFetchedData.current) {
      fetchSchedule();
      hasFetchedData.current = true;
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []); // Ensure dependencies are correctly set

  const { scheduledRecipesState } = useScheduledRecipesState();

  const { invoicesState } = useInvoicesState();
  const invoices = invoicesState.invoices;
  // Function call (assuming invoices array is filled with relevant data)
  const preprocessedInvoices = preprocessStockData(invoices);
  const result = matchInvoicesToRecipes(
    scheduledRecipesState.scheduledRecipes,
    preprocessedInvoices
  );

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [filteredEvents, setFilteredEvents] = useState(
    scheduledRecipesState.scheduledRecipes
  );
  const [selectedTab, setSelectedTab] = useState("In Progress");
  const [showSuccessAnim, setShowSuccessAnim] = useState(false);
  const [showErrorAnim, setShowErrorAnim] = useState(false);
  const [showAnim, setShowAnim] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentEventToDelete, setCurrentEventToDelete] = useState(null);
  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);

  const businessId = Userbusiness[0];

  const convertStringsToIsoDates = () => {
    return scheduledRecipesState.scheduledRecipes.map((recipe) => ({
      ...recipe,
      title: recipe.recipe,
      start: new Date(recipe?.start), // Convert Date to ISO string
      end: new Date(recipe?.end), // Convert Date to ISO string
    }));
  };

  const formattedData = convertStringsToIsoDates();

  useEffect(() => {
    const filtered =formattedData?.filter((event) => {
      const eventMonth = moment(event?.start).format("YYYY-MM");
      const selectedMonthFormatted = moment(selectedMonth).format("YYYY-MM");
      return eventMonth === selectedMonthFormatted;
    });
    const filteredProgress = filtered.filter((event) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time part to midnight for accurate day comparison
    
      const eventDate = new Date(event.start);
      eventDate.setHours(0, 0, 0, 0); // Reset time part to midnight
    
      if (selectedTab === "In Progress") {
        // Include events for today or in the future
        return eventDate.getTime() >= today.getTime();
      } else if (selectedTab === "Completed") {
        // Include events marked as done or whose start date is before today
        return event.isDone || eventDate < today;
      } else {
        // Include events not marked as completed
        return !event.isDone;
      }
    });
    
    setFilteredEvents(filteredProgress);
  }, [selectedMonth, selectedTab]);

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value + "-01"));
  };

  const { recipeState } = useRecipeState();

  const editEvent = async (selectedEvent) => {
    try {
      if (selectedEvent) {
        // Check if we are editing an existing event (it has an ID and exists in the list)
        const existingEventIndex =
          scheduledRecipesState.scheduledRecipes.findIndex(
            (event) => event.id === selectedEvent.id
          );

        if (existingEventIndex !== -1) {
          // Editing an existing event
          const updatedEvents = [...scheduledRecipesState.scheduledRecipes];
          updatedEvents[existingEventIndex] = {
            ...updatedEvents[existingEventIndex],
            ...selectedEvent,
          };
       
          await updateScheduledRecipe(updatedEvents[existingEventIndex].id,updatedEvents[existingEventIndex].batchNumber,updatedEvents[existingEventIndex].batches,updatedEvents[existingEventIndex].isDone);
          fetchSchedule();
          // Here, instead of creating new events, update the existing event in your store or backend
          // For demonstration, assume a hypothetical function updateScheduledEvent that updates the event
          //   await updateScheduledEvent(businessId, updatedEvents[existingEventIndex]);
          // fetchSchedule(); // Refresh the schedule to reflect the update
        } else {
          const eventToUpdate = { ...selectedEvent };

          // Start with an empty array for updatedEvents to ensure we're only adding what's necessary
          let updatedEvents = [];

          if (eventToUpdate?.isRecurring) {
            // Since it's a recurring event, we assume all child events will be regenerated
            // Add the updated parent event (if it's not a child event itself)
            if (!eventToUpdate.parentId) {
              updatedEvents.push(eventToUpdate); // Add the parent event back
            }
        
            let currentStartDate = moment(eventToUpdate.start);
            const recurrenceEnd = moment(eventToUpdate.lastDate); // Assuming end date defines the recurrence range
            const cookTimeInMinutes = parseInt(eventToUpdate.cookTime, 10); // Convert cookTime to integer
            const prepTimeInMinutes = eventToUpdate.prepTime ? parseInt(eventToUpdate.prepTime, 10) : 10; // Convert prepTime to integer, default to 10 if not available
            const totalTimeInMinutes = cookTimeInMinutes + prepTimeInMinutes; // Add both times together
        
            while (currentStartDate.isBefore(recurrenceEnd)) {
              // Logic for adjusting the start date based on the recurrence pattern
              switch (eventToUpdate.recurrence) {
                case "Daily":
                  currentStartDate.add(1, "days");
                  break;
                case "Weekly":
                  currentStartDate.add(1, "weeks");
                  break;
                case "Fortnightly":
                  currentStartDate.add(2, "weeks");
                  break;
                case "Monthly":
                  currentStartDate.add(1, "months");
                  break;
                // Add more cases as needed
              }
        
              if (currentStartDate.isBefore(recurrenceEnd)) {
                const startMoment = moment(currentStartDate); // Initialize moment object for the new start date
                const endMoment = startMoment.clone().add(totalTimeInMinutes, "minutes"); // Calculate new end time
                const childEvent = {
                  ...eventToUpdate,
                  id: Math.random(), // Generate a new ID for each child
                  parentId: eventToUpdate.id, // Set the parent ID for reference
                  start: startMoment.toDate(), // Set the new start date
                  end: endMoment.toDate(), // Set the new end date calculated based on total time
                };
                updatedEvents.push(childEvent); // Add the newly generated child event
              }
            }
        
            await handleSaveRecurringEvents(businessId, updatedEvents); // Save all the updated events
            fetchSchedule(); // Refresh the schedule to reflect the changes
          } else {
            // For non-recurring events, simply add or update the event
           await handleSaveSingleEvent(businessId,eventToUpdate);
           fetchSchedule();
          }
        }

        setShowAnim(true);
        setShowSuccessAnim(true);
        setTimeout(() => {
          setShowSuccessAnim(false);
          setShowAnim(false); // Close the modal
        }, 3000);
      }
    } catch (error) {
      setShowErrorAnim(true);
      setTimeout(() => {
        setShowErrorAnim(false);
        setShowAnim(false); // Close the modal
      }, 3000);
    }
  };

  // Assuming events have a 'startDate' or similar field to determine their sequence
  const deleteEvent =   async (eventId, deleteAllFollowing = false) => {
      const eventToDelete = scheduledRecipesState.scheduledRecipes.find(
        (event) => event.id === eventId
      );
      if (!eventToDelete) {
        return; // Exit the function if event not found
      }
      if (deleteAllFollowing) {
        // For recurring events, determine if the eventToDelete is a parent or a child
        if (eventToDelete.parentId) {
          // If it's a child, find all events in the series that come after it, including the parent
          const parentEvent = scheduledRecipesState.scheduledRecipes.find(
            (event) => event.id === eventToDelete.parentId
          );
          if (parentEvent) {
            const eventsToDelete = scheduledRecipesState.scheduledRecipes.filter(
              (event) => {
                // Identify events that are part of the series (linked by parentId)
                if (event.id === parentEvent.id || event.parentId === parentEvent.id) {
                  // From the series, select the parent event (if applicable) and any child events that start at the same time or after eventToDelete
                  return !moment(event.start).isBefore(moment(eventToDelete.start));
                }
                return false; // Exclude events that are not part of the series
              }
            );
            // eventsToDelete now contains all events that would be deleted based on the criteria
          
            await deleteEvents(eventsToDelete);
            fetchSchedule();

          } else {
            console.error("Parent event not found for child event:", eventToDelete.parentId);
            return []; // Return an empty array if the parent event is not found
          }
        } else {
          // If it's a parent, remove it and all its children that start after the selected event
          const eventsToDelete = scheduledRecipesState.scheduledRecipes.filter(
            (event) => {
              // Select the parent event itself for deletion
              if (event.id === eventId) return true;
              // Select child events of the parent that start at the same time or after the parent
              if (event.parentId === eventId && !moment(event.start).isBefore(moment(eventToDelete.start))) {
                return true;
              }
              return false; // Exclude events that do not match the deletion criteria
            }
          );
             await deleteEvents(eventsToDelete);
            fetchSchedule();
      
        }
      } else {
        // Deleting only the selected event, regardless of its parent or child status
        console.log(eventId);
       await deleteSingleEvent(eventId);
       fetchSchedule();
      }

      // Update state and UI as necessary
      // setEvents(updatedEvents);
      setShowAnim(true);
      setShowSuccessAnim(true);
      setTimeout(() => {
        setShowSuccessAnim(false);
        setShowAnim(false); // Optionally close the modal
      }, 3000);
      setOpenConfirmDialog(false); // Close the confirmation dialog
    };

  
  const handleDeleteClick = (event) => {
    setCurrentEventToDelete(event);
    setOpenConfirmDialog(true);
  };

  function handlePrintPDFs() {
    const htmlTemplates = filteredEvents.map((event) =>
      createPDFHtmlTemplate(event, result.updatedInvoices)
    );

    const consolidatedTemplate = htmlTemplates.join(
      '<div style="page-break-after: always"></div>'
    );

    printToPDF(consolidatedTemplate);
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "transparent",
    boxShadow: 0,
    p: 4,
  };

  return (
    <Box overflowX="none">
      {" "}
      {/* Add Box to make the container scrollable */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper>
            <RecipeCalendar
              recipes={recipeState.recipes}
              events={formattedData}
              editEvent={editEvent}
              deleteEvent={handleDeleteClick}
              // generateRecurringEvents={generateRecurringEvents}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Month</InputLabel>
            <Select
              label="Month"
              value={moment(selectedMonth).format("YYYY-MM")}
              onChange={handleMonthChange}>
              {Array.from({ length: 12 }, (_, i) => {
                const month = moment().month(i).startOf("month");
                return (
                  <MenuItem key={i} value={month.format("YYYY-MM")}>
                    {month.format("MMMM YYYY")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Paper
            elevation={3}
            sx={{
              margin: 2,
              padding: 2,
              maxHeight: "80vh",
              overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },
            }}>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setSelectedTab(newValue)}>
              <Tab label="To Do List" value={"To Do"} />
              <Tab label="Today's List" value={"In Progress"} />
              <Tab label="Completed" value={"Completed"} />
            </Tabs>
            {filteredEvents.length > 0 ? (
              <>
                {selectedTab === "Completed" && (
                  <Button
                    color="secondary"
                    sx={{ m: 2 }}
                    endIcon={<Print />}
                    variant="contained"
                    onClick={() => handlePrintPDFs()}>
                    Print Month&apos;s Tracibility Sheets
                  </Button>
                )}
                <List>
                  {filteredEvents.map((event) => (
                    <ListItem key={event.id} divider>
                      <ListItemText
                        primary={event.title}
                        secondary={`Date: ${moment(event.start).format(
                          "MMMM Do YYYY"
                        )}`}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteClick(event)} // Pass the full event object
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <Typography variant="body1" sx={{ mb: 2 }}>
                Opps! It seems like you&apos;ve got nothing scheduled for today{" "}
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete all following events or just the selected
            event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteEvent(currentEventToDelete.id, true); // Delete all following events
              setOpenConfirmDialog(false);
            }}>
            Delete All Following
          </Button>
          <Button
            onClick={() => {
              deleteEvent(currentEventToDelete.id); // Delete only this event
              setOpenConfirmDialog(false);
            }}
            autoFocus>
            Delete Only This
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={showAnim}
        onClose={() => setShowAnim(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ backgroundColor: "transparent" }}>
        <Box sx={style}>
          {showSuccessAnim && (
            <Lottie
              animationData={SuccessLottie}
              height={200}
              width={200}
              loop={false}
              style={{ backgroundColor: "transparent" }}
            />
          )}
          {showErrorAnim && (
            <Lottie
              animationData={ErrorLottie}
              height={200}
              width={200}
              loop={false}
              style={{ backgroundColor: "transparent" }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ScheduledRecipesList;
