import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type File={
  _url:string|"";
  _name:string|"";
};

type Supplier = {
  id: string;
  name: string;
  location: {
    _latitude: number;
    _longitude: number;
  };
  invoices: Invoice[];
  stockItems: Stock[];
  accountPaid: number;
  email: string;
  salesRep: string;
  contactNumber: string;
  Certificates: File[] | null;
};

type Stock = {
  stockId: string;
  price: number;
  qty: number;
  name: string;
  category: string;
  unit: string;
  quantity: number;
};

type Invoice = {
  id: string;
  invNumber: string;
  stock: Stock[];
  date: string;
  lastDate: string;
  supplier: string;
};

type SuppliersStateType = {
  suppliers: Supplier[];
};

const initialState: SuppliersStateType = {
  suppliers: [],
};

export const suppliersSlice = createSlice({
  name: "suppliersState",
  initialState,
  reducers: {
    setSuppliers: (
      state: SuppliersStateType,
      action: PayloadAction<{ suppliers: Supplier[] }>,
    ) => {
      state.suppliers = action.payload.suppliers;
    },
  },
});

export const { setSuppliers } = suppliersSlice.actions;
export default suppliersSlice.reducer;
