import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRollbar } from "@rollbar/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import RecipeForm from "./AddRecipeForm";

import { useFetchPackages } from "@api/packaging";
import { useRecipeState } from "@reduxHooks/useRecipesState";
import { useFetchRecipes } from "@api/recipe";
import { handleUpdateRecipe } from "@api/CRUDOp"; // Adjust the path to where your CRUDOp.js is located
import CircularProgress from "@mui/material/CircularProgress";

import RecipeModal from "./RecipeModal";

const SuccessDialog = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Update Successful</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The recipe has been updated successfully.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const RecipeCard = ({ recipe, onSelect, isSaving }) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <CardActionArea
      sx={{ flexGrow: 1 }}
      onClick={() => onSelect(recipe)}
      disabled={isSaving}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {recipe.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`Serves: ${recipe.servings} | Price: $${recipe.retailPrice}`}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const RecipeTableView = ({ recipes, onSelect, isSaving }) => (
  <TableContainer
    component={Paper}
    sx={{ maxHeight: "75vh", overflowY: "auto" }}
  >
    {" "}
    {/* Adjust as needed */}
    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Serves</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell align="right">Cook Time</TableCell>
          <TableCell align="right">Temperature (°C)</TableCell>
          <TableCell align="right">Part of Another Recipe</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {recipes?.map((recipe, index) => (
          <TableRow
            key={`recipe-table-${index}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            onClick={() => onSelect(recipe)}
            hover
            role="checkbox"
            disabled={isSaving}
          >
            <TableCell component="th" scope="row">
              {recipe.name}
            </TableCell>
            <TableCell align="right">{recipe.servings}</TableCell>
            <TableCell align="right">{`$${recipe.retailPrice.toFixed(
              2
            )}`}</TableCell>
            <TableCell align="right">{recipe.cookTime}</TableCell>
            <TableCell align="right">{recipe.cookTemp}</TableCell>
            <TableCell align="right">
              {recipe.isIngredient ? "Yes" : "No"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const RecipeList = () => {
  const rollbar=useRollbar();
  const { recipeState } = useRecipeState();
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState("card"); // Initial view mode
  const [searchTerm, setSearchTerm] = useState("");
  const [addPressed, setAddPressed] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  const [isSaving, setIsSaving] = useState(false); // New state to track saving progress

  const [isEditing, setIsEditing] = useState(false);

  const isObjectNotEmpty = (obj) => {
    return obj && Object.keys(obj).length > 0 && obj.constructor === Object;
  };

  const handleSave = async (updatedRecipe) => {
    setIsSaving(true);
   console.log(updatedRecipe);
    // Validate ingredients, toppings, base, and packaging to ensure they are not empty
    // const validIngredients = updatedRecipe.ingredients.every(isObjectNotEmpty);
    // const validToppings = updatedRecipe.topping.every(isObjectNotEmpty);
    // const validBase = updatedRecipe.base.every(isObjectNotEmpty);
    // const validPackaging = updatedRecipe.packaging.every(isObjectNotEmpty);

    // if (!validIngredients || !validToppings || !validBase || !validPackaging) {
    //   // Handle the invalid case, perhaps alert the user or log a warning
    //   // Optionally stop the save process or handle it differently
    //   
    //   return;
    // } else {
      try {
       
        await handleUpdateRecipe(
          updatedRecipe.id,
          updatedRecipe.name,
          updatedRecipe.cookTime,
          updatedRecipe.prepTime,
          updatedRecipe.cookTemp,
          updatedRecipe.method,
          updatedRecipe.servings,
          updatedRecipe.ingredients,
          updatedRecipe.base,
          updatedRecipe.topping,
          updatedRecipe.isIngredient,
          updatedRecipe.packaging,
          updatedRecipe.retailPrice
        );
        setIsSuccessDialogOpen(true); // Open success dialog on successful update
        await fetchRecipes();
        setIsSaving(false);
        handleClose();
        // End the saving process
      } catch (error) {
        rollbar.log("Error updating recipe: ", error);
        setError("ERR_SAVE_RECIPE");
        setIsSaving(false); // End the saving process on error as well
      }
      // Proceed with updating the recipe only if all validations pass
    
  };

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
    // any additional state reset or actions after closing the dialog
  };

  const handleViewModeChange = (event, nextView) => {
    if (nextView !== null) {
      setViewMode(nextView);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredRecipes = recipeState.recipes?.filter((recipe) =>
    recipe.name.toLowerCase().includes(searchTerm)
  );

  const handleOpen = (recipe) => {
    setSelectedRecipe(recipe);
    setModalOpen(true);
    setIsEditing(false);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedRecipe(null); // Clear selection when closing the modal
  };
  const handleAddPressed = () => {
    setAddPressed(!addPressed); // Toggle the addPressed state
  };

  const { fetchRecipes } = useFetchRecipes();
  const { fetchPackages } = useFetchPackages();

  useEffect(() => {
    fetchRecipes();
    fetchPackages();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search Recipes"
          fullWidth
          margin="normal"
          size="medium"
          sx={{ maxWidth: "400px", bgcolor: "white" }} // Set max width and background color for the search field
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
        />
        <Box
          sx={{
            display: "flex", // Set display to flex to enable flexbox properties
            justifyContent: "center", // Center content horizontally
            alignItems: "center", // Center content vertically
            p: 2, // Add padding for visual spacing
          }}
        >
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
            sx={{ mr: 2 }}
            disabled={isSaving}
          >
            <ToggleButton
              value="list"
              aria-label="list view"
              sx={{ border: "1px solid", borderColor: "primary.main" }}
            >
              <ViewListIcon color="secondary" />
            </ToggleButton>
            <ToggleButton
              value="card"
              aria-label="card view"
              sx={{ border: "1px solid", borderColor: "primary.main" }}
            >
              <ViewModuleIcon color="secondary" />
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            color="secondary"
            aria-label={addPressed ? "close form" : "add recipe"}
            sx={{ "&:hover": { bgcolor: "lightgray" } }}
            onClick={handleAddPressed}
            disabled={isSaving}
          >
            {addPressed ? (
              <CloseIcon sx={{ fontSize: 40 }} />
            ) : (
              <AddCircleOutlineIcon sx={{ fontSize: 40 }} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={addPressed ? 9 : 12}>
          {viewMode === "card" ? (
            <Grid
              container
              spacing={4}
              sx={{ padding: 4, maxHeight: "80vh", overflowY: "auto" }}
            >
              {filteredRecipes?.map((recipe, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <RecipeCard
                    recipe={recipe}
                    onSelect={handleOpen}
                    isSaving={isSaving}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <RecipeTableView
              recipes={filteredRecipes}
              onSelect={handleOpen}
              isSaving={isSaving}
            />
          )}
        </Grid>
        <SuccessDialog
          open={isSuccessDialogOpen}
          onClose={handleCloseSuccessDialog}
        />

        {addPressed && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <RecipeForm />
          </Grid>
        )}
      </Grid>

      {selectedRecipe &&
        (isSaving ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <RecipeModal
            recipe={selectedRecipe}
            open={handleOpen}
            onClose={handleClose}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onSave={handleSave}
          />
        ))}
    </>
  );
};

export default RecipeList;
