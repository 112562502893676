import React from "react";
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const EditablePackagingList = ({
  title,
  items, // Items in the recipe
  allPackages, // All available packaging options
  isEditing,
  onAddNewItem,
  onCancelNewItem,
  selectedItems, // IDs of selected packaging items
  setSelectedItems, // Function to update selected items
}) => {
  // Function to check if the item is selected
  const isSelected = (id) => selectedItems.includes(id);

  // Function to handle selection change
  const handleSelectionChange = (id) => {
    if (isSelected(id)) {
      // If already selected, remove it
      setSelectedItems(selectedItems.filter((selectedId) => selectedId !== id));
      onCancelNewItem(id); // Handle any additional cancellation logic
    } else {
      // If not selected, add it
      setSelectedItems([...selectedItems, id]);
      onAddNewItem(id); // Handle any additional add logic
    }
  };

  // Function to render each row of the table
  const renderRow = (item, index) => (
    <TableRow key={`packaging-${index}`}>
      {isEditing && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected(item.id)}
            onChange={() => handleSelectionChange(item.id)}
            color="primary"
          />
        </TableCell>
      )}

      {/* Name Cell */}
      <TableCell>
        <Typography>{item.name}</Typography>
      </TableCell>

      {/* Amount Cell */}
      <TableCell align="right">
        <Typography>{item.amount}</Typography>
      </TableCell>

      {/* Price Cell */}
      <TableCell align="right">
        <Typography>{item.price}</Typography>
      </TableCell>

      {/* Serves Cell */}
      <TableCell align="right">
        <Typography>{item.serves}</Typography>
      </TableCell>

      {/* Actions Cell */}
      <TableCell align="right">
        {isEditing && (
          <>
            {selectedItems.includes(item.id) && (
              <IconButton onClick={() => onCancelNewItem(item.id)}>
                <CancelIcon />
              </IconButton>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label={`${title.toLowerCase()} table`}
        >
          <TableHead>
            <TableRow>
              {isEditing && <TableCell padding="checkbox"></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Serves</TableCell>
              {isEditing && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEditing
              ? allPackages.map((item, index) => renderRow(item, index))
              : items.map((item, index) => renderRow(item, index))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
