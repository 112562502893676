import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';

const PieChartComponent = ({ pieData }) => {

    const COLORS = [
      '#0088FE', // Vivid blue
      '#00C49F', // Soft teal
      '#FFBB28', // Sunny yellow
      '#FF8042', // Warm orange
      '#A4DE6C', // Light green
      '#D0ED57', // Lime green
      '#8884D8', // Gentle purple
      '#83A6ED', // Sky blue
      '#8DD1E1', // Soft cyan
      '#82CA9D', // Pastel green
      '#A4D3EE', // Powder blue
      '#CDBE70', // Muted gold
      '#CDA776', // Almond brown
      '#99C1B9', // Faded jade
      '#B0A8B9', // Grayish lavender
    ];
     // Function to determine the font size based on the chart size and value
     const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
      const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
  
      const fontSize = (outerRadius - innerRadius) / 10; // Adjust the font size based on the radius
  
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={`${fontSize}px`}>
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  
    
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {
              pieData?.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
          <Legend layout="vertical" align="right" verticalAlign="middle" />
        </PieChart>
      </ResponsiveContainer>
    );
  };
  
  export default PieChartComponent;