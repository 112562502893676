import { useParseSDK } from "hooks/useParseSDK";


const {Parse}=useParseSDK();

//////////////////////////////////////////////////CRUD Operations for Recipes///////////////////////////////////////////
export const handleUpdateRecipe = async (
  recipeId,
  recipeName,
  recipeCookTime,
  recipePrepTime,
  recipeCookTemp,
  recipeMethod,
  recipeServings,
  ingredients,
  base,
  topping,
  isIngredient,
  packaging,
  retailPrice
) => {
  try {
  const Recipe = Parse.Object.extend("Recipes");
  const query = new Parse.Query(Recipe);
  const Ingredients = Parse.Object.extend("Ingredients");

  const packagesToSave = [];

  for (let j = 0; j < packaging.length; j++) {
    const singlePackage = packaging[j]; // Renamed variable
    const packagingPointers = {
      __type: "Pointer",
      className: "Packages",
      objectId: singlePackage.id, // Updated to use the renamed variable
    };
    packagesToSave.push(packagingPointers);
  }

   // Fetch the recipe object to update
   const object = await query.get(recipeId);

   // Update basic recipe properties
   object.set("name", recipeName ?? "");
   object.set("cookTime", recipeCookTime ?? "");
   object.set("prepTime", recipePrepTime ?? "");
   object.set("cookTemp", recipeCookTemp ?? "");
   object.set("method", recipeMethod ?? "");
   object.set("servings", Number(recipeServings));
   object.set("Is_Ingredient", isIngredient);
   object.set("Packaging", packagesToSave);
   object.set("Retail_Price", Number(retailPrice));

  // Function to update or create new ingredients, base, or topping items
  const updateOrCreateItems = async (items, className) => {
    const ItemClass = Parse.Object.extend(className);
    const updates = [];
    const news = [];
  
    for (const item of items) {
      // Determine if the ID is a temporary 2-digit ID
      const isTemporaryId = /^\d{2}$/.test(item.id);
  
      if (item.id && !isTemporaryId) {
        updates.push(item); // Existing items for update
      
      } else {
        // New item creation
        const newItem = new ItemClass();
        newItem.set("name", item.name);
        newItem.set("amount", item.amount);
        newItem.set("unit", item.unit);
        // Set the stock pointer using the original method
        newItem.set("stock", {
          __type: "Pointer",
          className: "Stocks",
          objectId: item.stockId
        });
        // Add any other necessary fields for new items
        news.push(newItem); // Queue new items to be saved
       
      }
    }
  
    // Fetch and update existing items
    if (updates.length > 0) {
      const itemQuery = new Parse.Query(ItemClass);
      itemQuery.containedIn("objectId", updates.map(item => item.id));
      const existingItems = await itemQuery.find();
  
      existingItems.forEach(itemObj => {
        const itemUpdate = updates.find(item => item.id === itemObj.id);
        if (itemUpdate) {
          itemObj.set("amount", itemUpdate.amount);
          itemObj.set("unit", itemUpdate.unit);
          itemObj.set("stock", {
            __type: "Pointer",
            className: "Stocks",
            objectId: itemUpdate.stockId
          });
          // Update any other fields as needed
        }
      });
      await Parse.Object.saveAll(existingItems); // Save updates
    }
  
    // Save new items
    if (news.length > 0) {
      await Parse.Object.saveAll(news);
    }
  
    // Return updated and newly saved items as is
    return [...updates, ...news];
  };
  
  
  // Process ingredients, base, and toppings
  const updatedIngredients = await updateOrCreateItems(ingredients, "Ingredients");
  const updatedBases = await updateOrCreateItems(base, "Ingredients");
  const updatedToppings = await updateOrCreateItems(topping, "Ingredients");

  // Associate updated/created items with the recipe
  object.set("ingredients", updatedIngredients.map(ing => ({__type: "Pointer", className: "Ingredients", objectId: ing.id || ing.stockId})));
  object.set("Base", updatedBases.map(b => ({__type: "Pointer", className: "Base", objectId: b.id || b.stockId})));
  object.set("Topping", updatedToppings.map(top => ({__type: "Pointer", className: "Topping", objectId: top.id || top.stockId})));

  // Save the updated recipe
  await object.save();

 } catch (error) {
   console.error("Error updating Recipe, Ingredients, Base, Toppings, and Packaging: ", error);
 }
};

export const addRecipes = async (recipe) => {
 
  const Recipe = Parse.Object.extend("Recipes");
  const Ingredient = Parse.Object.extend("Ingredients");

  try {
    const newRecipe = new Recipe();
    newRecipe.set("name", recipe.name.toString());
    newRecipe.set("cookTime", recipe.cookTime.toString());
    newRecipe.set("prepTime",recipe.prepTime.toString());
    newRecipe.set("cookTemp", recipe.cookTemp.toString());
    newRecipe.set("method", recipe.method.toString());
    newRecipe.set("servings", Number(recipe.servings));
    newRecipe.set("Retail_Price", Number(recipe.retailPrice));
    newRecipe.set("Business", {
      __type: "Pointer",
      className: "Business",
      objectId: recipe.businessId,
    });
    newRecipe.set("Is_Ingredient", recipe.isIngredient);

    // Create an array to hold all the ingredient objects to be saved for this recipe
    const ingredientsToSave = [];

    // Loop through each ingredient and create a new Ingredient object
    // and add it to the ingredientsToSave array
    for (let j = 0; j < recipe.ingredients.length; j++) {
      const ingredient = recipe.ingredients[j];
      const newIngredient = new Ingredient();
      newIngredient.set("stock", {
        __type: "Pointer",
        className: "Stocks",
        objectId: ingredient.stock,
      });
      newIngredient.set("amount", ingredient.amount.toString());
      newIngredient.set("unit", ingredient.unit.toString());
      newIngredient.set("Business", {
        __type: "Pointer",
        className: "Business",
        objectId: ingredient.businessId,
      });

      ingredientsToSave.push(newIngredient);
    }

    // Use saveAll to save all the Ingredient objects in a single API request
    await Parse.Object.saveAll(ingredientsToSave);

    // Set the recipe's ingredients property to the array of Ingredient objects
    newRecipe.set("ingredients", ingredientsToSave);

    const toppingToSave = [];

    // Loop through each ingredient and create a new Ingredient object
    // and add it to the ingredientsToSave array
    for (let j = 0; j < recipe.topping.length; j++) {
      const topping = recipe.topping[j];
      const newTopping = new Ingredient();
      newTopping.set("stock", {
        __type: "Pointer",
        className: "Stocks",
        objectId: topping.stock,
      });
      newTopping.set("amount", topping.amount.toString());
      newTopping.set("unit", topping.unit.toString());
      newTopping.set("Business", {
        __type: "Pointer",
        className: "Business",
        objectId: topping.businessId,
      });

      toppingToSave.push(newTopping);
    }

    // Use saveAll to save all the Ingredient objects in a single API request
    await Parse.Object.saveAll(toppingToSave);

    // Set the recipe's ingredients property to the array of Ingredient objects
    newRecipe.set("Topping", toppingToSave);

    const baseToSave = [];

    // Loop through each ingredient and create a new Ingredient object
    // and add it to the ingredientsToSave array
    for (let j = 0; j < recipe.base.length; j++) {
      const base = recipe.base[j];
      const newBase = new Ingredient();
      newBase.set("stock", {
        __type: "Pointer",
        className: "Stocks",
        objectId: base.stock,
      });
      newBase.set("amount", base.amount.toString());
      newBase.set("unit", base.unit.toString());
      newBase.set("Business", {
        __type: "Pointer",
        className: "Business",
        objectId: base.businessId,
      });

      baseToSave.push(newBase);
    }

    // Use saveAll to save all the Ingredient objects in a single API request
    await Parse.Object.saveAll(baseToSave);

    // Set the recipe's ingredients property to the array of Ingredient objects
    newRecipe.set("Base", baseToSave);
    // Save the new Recipe object
    await newRecipe.save();

  } catch (error) {
    console.error("Error while adding Recipe: ", error, recipe.businessId);
  }
};

export const handleDeleteRecipe = async (recipeId) => {
 
 
  try {
    const Recipe = Parse.Object.extend("Recipes");
    const Ingredients = Parse.Object.extend("Ingredients");
    const recipeQuery = new Parse.Query(Recipe);

    const recipe = await recipeQuery.get(recipeId);
    const relatedIngredients = recipe.get("ingredients");
    const relatedToppings = recipe.get("Topping");
    const relatedBases = recipe.get("Base");

    // Delete related ingredients
    const ingredientsQuery = new Parse.Query(Ingredients);
    ingredientsQuery.containedIn(
      "objectId",
      relatedIngredients.map((ingredient) => ingredient.id)
    );
    const deletedIngredients = await ingredientsQuery.find();

    const toppingsQuery = new Parse.Query(Ingredients);
    toppingsQuery.containedIn(
      "objectId",
      relatedToppings.map((top) => top.id)
    );
    const deletedToppings = await toppingsQuery.find();

    const basesQuery = new Parse.Query(Ingredients);
    basesQuery.containedIn(
      "objectId",
      relatedBases.map((b) => b.id)
    );
    const deletedBases = await basesQuery.find();

    const deletedObjects = [
      ...deletedIngredients,
      ...deletedToppings,
      ...deletedBases,
    ];
    await Parse.Object.destroyAll(deletedObjects);

    // Delete recipe
    await recipe.destroy();

    
  } catch (error) {
    console.error(
      "Error while deleting recipe and related ingredients:",
      error,{recipeId}
    );
  }
};

export const deleteIngredient = async (ingredientId) => {
 
  try {
    const Ingredient = Parse.Object.extend("Ingredients");
    const ingredientQuery = new Parse.Query(Ingredient);
    const ingredient = await ingredientQuery.get(ingredientId);
    await ingredient.destroy();
  } catch (error) {
    console.error("Error while deleting ingredient:", error,{ingredientId});
  }
};
