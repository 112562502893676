import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setEmployeeInfo as setTimesheets } from "redux/slices/timesheet.slice";

export const useEmployeeState = () => {
  const dispatch = useDispatch();

  const timesheetState = useAppSelector((state) => state.timesheetState);
  const setEmployeeInfo = useCallback(
    (data: any) => dispatch(setTimesheets(data)),
    [],
  );

  return {
    timesheetState,
    setEmployeeInfo,
  };
};
