import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Invoice = {
  id: string;
  invNumber: string;
  stock: Stock[];
  date: string;
  lastDate: string;
  supplier: string;
};

type Stock ={
  stockId:string;
  price:number;
  qty:number;
  name: string;
  category: string;
  unit:string;
  quantity:number;
    // Retrieve the quantity from the Stocks class
}
type InvoiceStateType = {
  invoices: Invoice[];
};

const initialState: InvoiceStateType = {
  invoices: [],
};

export const invoicesSlice = createSlice({
  name: "invoicesState",
  initialState,
  reducers: {
    setInvoices: (
      state: InvoiceStateType,
      action: PayloadAction<{ invoices: Invoice[] }>,
    ) => {
      state.invoices = action.payload.invoices;
    },
  },
});

export const { setInvoices } = invoicesSlice.actions;
export default invoicesSlice.reducer;
