import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store"; // Replace `RootState` with your actual root state type
import { setRecipes as setRecipesRedux } from "../slices/recipes.slice"; // Import actions from your slice

// Custom hook for managing recipe state
export const useRecipeState = () => {
  const dispatch = useDispatch();

  // Get current recipe state from Redux store
  const recipeState = useAppSelector((state) => state.recipeState); // Replace `recipeState` with your slice's actual name

  // Initialize recipes
const setRecipes = useCallback(
  (data: typeof recipeState) => {
    dispatch(setRecipesRedux(data)); // The payload must contain a "recipes" key
  },
  [dispatch],
);


  // Here, you can also add more methods as per your requirements, like `addRecipe`, `deleteRecipe`, etc.

  return {
    recipeState,
    setRecipes,
  };
};
