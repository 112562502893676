import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define your Sales type
interface Sale {
  id: string;
  recipe: string;
  sales: number;
  businessId: string;
  month: string;
}

// Define the initial state type
type SalesStateType = {
  sales: Sale[];
};

// Define the initial state
const initialState: SalesStateType = {
  sales: [],
};

// Create the sales slice
const salesSlice = createSlice({
  name: "salesState", // Name of the slice
  initialState,
  reducers: {
    // Initialize the sales array
    setSales: (state, action: PayloadAction<{ sales: Sale[] }>) => {
      state.sales = action.payload.sales;
    },
    // Add more reducers as needed
  },
});

// Export actions and reducer
export const { setSales } = salesSlice.actions;
export default salesSlice.reducer;
