import { convertUnits } from "../utils/convertUnits";
import { matchStockIds } from "../utils/stockMatchingIds";

const stockDataProcessor = () => {
  const preprocessRecipeAndStockData = (stockData) => {
    // Preprocess recipe and stock data using matchStockNames and updatedStockData functions
    const updatedStockData = {};

    Object.keys(stockData).forEach((key) => {
      const item = stockData[key];
      const updatedItem = { ...item };
      if (item.unit !== "pcs") {
        const convertedQuantity = convertUnits(
          item.calculatedQuantity,
          item.unit,
          item.name
        );
        updatedItem.calculatedQuantity = convertedQuantity.value;
        updatedItem.unit = convertedQuantity.unit;

        updatedItem.calculatedPrice =
          item.calculatedPrice / updatedItem.calculatedQuantity;
      } else {
        updatedItem.calculatedPrice =
          item.calculatedPrice / item.calculatedQuantity;
      }
      updatedStockData[key] = updatedItem;
    });

    return {
      updatedStockData,
      matchStockIds,
    };
  };

  const calculateRecipeCost = (recipe, stockData) => {
    const { updatedStockData, matchStockNames } =
      preprocessRecipeAndStockData(stockData);

    const recipeIngredients = matchStockIds(
      recipe.ingredients,
      updatedStockData
    ).map((ingredient) => {
      if (!ingredient.stockItem) {
        throw new Error(
          `Ingredient "${ingredient.name}" not found in stock data.`
        );
      }

      let amount = ingredient.amount;
      let unit = ingredient.unit;

      if (ingredient.name === "Eggs") {
        if (unit.toLowerCase() === "kg") {
          amount = (amount * 1000) / 50; // convert to grams and divide by 50
          unit = "pcs";
        } else if (
          unit.toLowerCase() === "g" ||
          unit.toLowerCase() === "grams"
        ) {
          amount /= 50; // divide by 50
          unit = "pcs";
        }
      }

      const cost =
        convertUnits(amount, unit).value * ingredient.stockItem.calculatedPrice;

      return { ...ingredient, cost };
    });

    const recipeCost = recipeIngredients.reduce(
      (totalCost, ingredient) => totalCost + ingredient.cost,
      0
    );

    return recipeCost;
  };

  const calculateRecipeWeight = (recipe, stockData) => {
    const { updatedStockData } =
      preprocessRecipeAndStockData(stockData);

    const recipeIngredients = matchStockIds(
      recipe.ingredients,
      updatedStockData
    ).map((ingredient) => {
      if (!ingredient.stockItem) {
       return;
      }

      let amount = ingredient.amount;
      let unit = ingredient.unit;

      if (ingredient.name === "Eggs") {
        if (unit.toLowerCase() === "pcs") {
          amount = amount * 50; // convert to grams and divide by 50
          unit = "g";
        }
      }
      if (ingredient.name === "Woolworth Chocolate Cake Mix") {
        if (unit.toLowerCase() === "bag") {
          amount = 340; // convert to grams and divide by 50
          unit = "g";
        }
      }

      // console.log("Name:", ingredient.name, "Amount:", amount, "Unit:", unit);

      return { ...ingredient, amount, unit };
    });

    const recipeWeightInGrams = recipeIngredients.reduce(
      (totalWeight, ingredient) => {
        const weight = convertUnits(
          ingredient.amount,
          ingredient.unit,
          ingredient.name
        ).value;

        return Number(totalWeight) + Number(weight);
      },
      0
    );

    const recipeWeightInKg = recipeWeightInGrams / 1000; // Convert grams to kilograms

    return recipeWeightInKg;
  };

  return {
    calculateRecipeCost,
    calculateRecipeWeight,
  };
};

export default stockDataProcessor;
