import { convertUnits } from "./convertUnits";
import { matchStockIds } from "./stockMatchingIds";

export const calculateRecipeCosts = (recipes, updatedStockData) => {
  return recipes?.map((recipe) => {
 
    const recipeIngredients = [
      ...matchStockIds(recipe.ingredients, updatedStockData),
      ...matchStockIds(recipe.topping, updatedStockData),
      ...matchStockIds(recipe.base, updatedStockData),
    ]?.map((ingredient) => {
      if (!ingredient.stockItem) {
      //  console.log(`Ingredient ${ingredient.name} not found in stock data.`);
        return;
      }
      let amount = ingredient.amount;
      if (
        ingredient.name.toLowerCase() === "eggs" ||
        ingredient.name.toLowerCase() === "egg"
      ) {
        if (ingredient.unit.toLowerCase() === "kg") {
          amount = (amount * 1000) / 50; // convert to grams and divide by 50
          ingredient.unit = "pcs";
          ingredient.amount = amount;
        } else if (
          ingredient.unit.toLowerCase() === "gram" ||
          ingredient.unit.toLowerCase() === "grams" ||
          ingredient.unit.toLowerCase() === "g"
        ) {
          amount = amount / 50; // divide by 50
          ingredient.unit = "pcs";
          ingredient.amount = amount;
        } else if (ingredient.unit.toLowerCase() === "pcs") {
          ingredient.unit = "pcs";
        }
      }

      amount = convertUnits(amount, ingredient.unit).value;
      const cost = amount * ingredient.stockItem.calculatedPrice;
      return { ...ingredient, cost };
    });

    const recipeCost = recipeIngredients?.reduce(
      (totalCost, ingredient) => totalCost + ingredient?.cost,
      0
    );
    return {
      ...recipe,
      cost: recipeCost.toFixed(2),
      ingredients: recipeIngredients,
    };
  });
};
