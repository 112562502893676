// TODO: refactor the animation part to be in a separate shared Component
import React, { useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { useParseSDK } from "../../../hooks/useParseSDK";
import { Loader } from "../components/Loder";
import Lottie from "lottie-react";
import SuccessLottie from "../.././../assets/lotties/success_lottie.json";
import ErrorLottie from "../.././../assets/lotties/error_lottie.json";

export const ConfirmOTPWrapper = () => {
  const [otp, setOtp] = useState("");
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validOTP, setValidOTP] = useState(true);
  const nextHandler = useRef<any>(undefined);

  const { Parse } = useParseSDK();

  const validateOTP = async (email: string, NextHandler: any) => {
    if (otp.length === 4 && typeof parseInt(otp) === "number") {
      nextHandler.current = NextHandler;
      !validOTP && setValidOTP(true);
      try {
        setLoading(true);
        const resp = await Parse.Cloud.run("verifyOtp", {
          email,
          otp: otp,
        });

        resp === "OTP verified successfully"
          ? setIsSuccess(true)
          : setIsError(resp);
      } catch (err: any) {
        setIsError("Error validating OTP");
      } finally {
        setLoading(false);
      }
    } else {
      setValidOTP(false);
    }
  };

  return {
    OtpForm: () => {
      if (!loading && !Boolean(isError) && !isSuccess) {
        return (
          <Box
            minHeight="600px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography fontSize="20px" fontWeight={500} marginBottom="3rem">
              Please Enter the OTP to verify your account
            </Typography>
            <OtpInput
              inputType="number"
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span style={{ margin: "0 5px" }}>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{
                    width: "70px",
                    height: "70px",
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: 600,
                    borderColor: validOTP ? "#000" : "#ff3333",
                    borderWidth: "1px",
                    borderRadius: "5px",
                  }}
                />
              )}
            />
            {!validOTP && (
              <Typography
                style={{
                  color: "#ff3333",
                  marginTop: "10px",
                }}>
                Please Enter a valid OTP
              </Typography>
            )}
          </Box>
        );
      }

      if (loading) return <Loader />;

      if (Boolean(isError) || isSuccess) {
        return (
          <Box
            sx={{
              minHeight: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Lottie
              animationData={isSuccess ? SuccessLottie : ErrorLottie}
              loop={false}
              autoplay
              style={{
                width: "120px",
                height: "120px",
              }}
              onComplete={() => {
                if (isSuccess) {
                  setIsSuccess(false);
                  nextHandler.current && nextHandler.current();
                  return;
                }
                if (isError) {
                  setTimeout(() => {
                    setIsError("");
                    console.log(
                      "ERROR HAPPENED , reset states  and show error message"
                    );
                  }, 1000);
                }
              }}
            />
            {Boolean(isError) && (
              // TODO: replace hex color with one from theme later
              <Typography
                color="#ff3333"
                fontSize="16px"
                marginTop="18px"
                fontWeight={900}
                maxWidth="300px"
                textAlign="center">
                {isError}
              </Typography>
            )}
          </Box>
        );
      }
    },
    validateOTP,
  };
};
