import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useEmployeeState } from '@reduxHooks/useEmployeeInfoState';
import { convertEmployeeInfo } from "utils/modifyURI";

export const useEmployeeInfo = () => {
  const { Parse } = useParseSDK();
  const { timesheetState, setEmployeeInfo } = useEmployeeState();
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);
  const businessId = Userbusiness[0];
  const userId = userData[0];

  return {
    fetchEmployeeInfo: async () => {
      if (!navigator.onLine) {
       // alert('You are offline. Fetching employee info from Redux state.');
        // Employee info should already be in the Redux state if fetched online previously
        if (timesheetState.employeeInfo && timesheetState.employeeInfo.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setEmployeeInfo([]);  // Provide fallback if no employee info is in state
          return;
        }
      } else {
        try {
          const data = await Parse.Cloud.run(
            "fetchEmployeeInfo",
            { businessId: businessId },
            { sessionToken: userId }
          );

          if (data && Array.isArray(data) && data.length > 0) {
            const formattedData = data?.map(convertEmployeeInfo);
            setEmployeeInfo(formattedData);
          } else {
            setEmployeeInfo([]);
          }
        } catch (error) {
          console.error("Error fetching employee info:", error);
          setEmployeeInfo([]);  // Handle error by setting empty or fallback data
        }
      }
    },
  };
};
