import { useParseSDK } from "hooks/useParseSDK";
import { useUserState } from "@reduxHooks/useLoginState"; // Assuming this hook exists

export const useFetchUserData = () => {
  const { Parse } = useParseSDK();
  const { setLoginState, loginState } = useUserState(); // Assuming this hook exists

  return {
    fetchUserData: async () => {
      if (!navigator.onLine) {
        //alert('You are offline. Fetching user data from Redux state.');
        // User data should already be in the Redux state if fetched online previously
        if (loginState.user && loginState.user.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setLoginState({ user: [] });  // Provide fallback if no user data is in state
          return;
        }
      } else {
        try {
          const currentUser = Parse.User.current();
          if (currentUser) {
            const userData = []; // Array to store user data

            // Retrieve the user's role
            const userRoleQuery = new Parse.Query(Parse.Role);
            userRoleQuery.equalTo("users", currentUser);
            const userRole = await userRoleQuery.first();
            const roleName = userRole ? userRole.get("name") : "No Role Assigned";
            const userId = currentUser.id;
            // Retrieve the user's role in the Business object
            const businessQuery = new Parse.Query("Business");
            businessQuery.equalTo("Business_Users", userId);
            const business = await businessQuery.first();
            const businessOwner = business
              ? business.get("Business_Owner")
              : "Unknown Business";
            const ownerUserQuery = new Parse.Query("_User");
            ownerUserQuery.equalTo("objectId", businessOwner.id);
            const ownerUser = await ownerUserQuery.first();
            const ownerEmail = ownerUser.get("email");
            const ownerUsername = ownerUser.get("username");
            const businessUsers = business
              ? business.get("Business_Users")
              : "Unknown Business";
            userData.push({
              role: roleName,
              business: {
                id: business.id,
                name: business.get("Business_Name"),
                phone: business.get("Business_Number"),
                owner: {
                  id: businessOwner.id,
                  username: ownerUsername,
                  email: ownerEmail,
                },
                address: business.get("Business_Address"),
                users: businessUsers,
                abn: business.get("Business_ABN"),
                paymentCycle:
                  business.get("payment_cycle_frequency") || "weekly",
                paymentCycleStartDate:
                  business.get("payment_cycle_start")?.toISOString() ||
                  new Date().toISOString(),
                accountantName: business.get("Accountant_Name") || "",
                accountantEmail: business.get("Accountant_Email") || "",
                businessHours: business.get("businessHours") ? business.get("businessHours") : [],
              },
              user: {
                id: currentUser.id,
                username: currentUser.get("username"),
                firstName: currentUser.get("First_Name"),
                lastName: currentUser.get("Last_Name"),
                email: currentUser.get("email"),
                isAuto: currentUser.get("isAuto"), // Assuming this is a boolean
              },
            });

            // Dispatch the userData to your Redux store or update context state
            setLoginState({ user: userData });
          }
        } catch (error) {
          console.error("Error fetching user and business data:", error);
          setLoginState({ user: [] });  // Handle error by setting empty or fallback data
        }
      }
    },
  };
};
