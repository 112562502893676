import React from 'react';
import { Paper, Typography, Divider, Grid, ListItem, ListItemText, useTheme } from '@mui/material';
import { format } from 'date-fns';

export const UserBusinessProfile = ({ Userbusiness }) => {
  const theme = useTheme();

  // Format date using date-fns or similar library
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'PPP');
    } catch (error) {
      return dateString; // return original string if formatting fails
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: theme.spacing(3), marginTop: theme.spacing(3), backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h4" gutterBottom component="div">
        Business Information
      </Typography>
      <Divider sx={{ margin: theme.spacing(1, 0) }} />
      <Grid container spacing={2}>
        {/* Check each property before rendering */}
        {Userbusiness && (
          <>
            <Grid item xs={12} md={6}>
              <ListItem>
                <ListItemText primary="Name" secondary={Userbusiness.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Phone" secondary={Userbusiness.phone} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Address" secondary={Userbusiness.address} />
              </ListItem>
              <ListItem>
                <ListItemText primary="ABN" secondary={Userbusiness.abn} />
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItem>
                <ListItemText primary="Payment Cycle" secondary={Userbusiness.paymentCycle} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Payment Cycle Start Date" secondary={formatDate(Userbusiness.paymentCycleStartDate)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Accountant Name" secondary={Userbusiness.accountantName} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Accountant Email" secondary={Userbusiness.accountantEmail} />
              </ListItem>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
