// MainComponent.js
import React from 'react';
import { Grid } from '@mui/material';
import OmarChatbot from './ChatbotUI'; // Adjust the path as necessary
import ActionsAndPlanning from './Actions&Planning'; // Adjust the path as necessary

const MainChatComponent = () => {
  return (
    <Grid container spacing={2} sx={{ p: 3, }}>
      <Grid item xs={12} md={6}>
        <OmarChatbot />
      </Grid>
      <Grid item xs={12} md={6}>
        <ActionsAndPlanning />
      </Grid>
    </Grid>
  );
};

export default MainChatComponent;
