import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAppSelector } from "../../redux/store";
import { useParseSDK } from "hooks/useParseSDK";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const { Parse } = useParseSDK();

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        boxWidth: 20,
        padding: 20,
      },
    },
    title: {
      display: true,
      text: "Payroll Summary",
      font: {
        size: 20,
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: "#F2F2F2",
      titleColor: "#000",
      bodyColor: "#000",
      borderWidth: 1,
      borderColor: "#CCC",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Timeframe", // Changed from 'Employees' to 'Payroll Cycles'
        font: {
          size: 16,
        },
      },
    },
    y: {
      beginAtZero: true, // Start the scale at zero
      title: {
        display: true,
        text: "Payment Amount",
        font: {
          size: 16,
        },
      },
      ticks: {
        // Include a dollar sign in the ticks and ensure the y-axis begins at 0
        callback: function (value, index, values) {
          return "$" + value;
        },
      },
      // You can use the suggestedMax property if you want to set a buffer
      // suggestedMax: maxValue + bufferValue
    },
  },
  elements: {
    bar: {
      borderRadius: 5,
      borderWidth: 2,
      hoverBackgroundColor: "rgba(0, 123, 255, 0.4)",
    },
  },
};

const PaidDataGraph = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [error, setError] = useState("");
  const [paidData, setPaidData] = useState([]);

  const theme = useTheme(); // Use the theme for consistent styling

  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const business = user.map((user) => user.business);
  const businessId = Userbusiness[0];
  const userData = user.map((u) => u.user);
  const userId = userData[0];
  const pc = business.map((b) => b.paymentCycle);
  const businessPaymentCycle = pc[0];
  const pd = business.map((b) => b.paymentCycleStartDate);
  const businessPaymentDate = pd[0];
  const [loading, setLoading] = useState(true); // New state to handle loading

  const fetchDataAndCleanup = async () => {
    setLoading(true); // Start loading
    try {
      const data = await Parse.Cloud.run(
        "fetchAllPaidData",
        { businessId: businessId },
        { user: userId }
      );
      processDataForGraph(data);
      const cleanedData = cleanupPaidClass(data); // Call cleanup function with the fetched data

      setPaidData(cleanedData); // Update state with the cleaned data
    } catch (error) {
      console.error("Error fetching paid data:", error);
      setError("Failed to fetch payment data. Please try again later.");
    }
    setLoading(false); // Finish loading
  };

  useEffect(() => {
    fetchDataAndCleanup();
  }, []);

  // cleanupPaidClass now takes the fetched data as a parameter and returns cleaned data
  const cleanupPaidClass = (data) => {
    let uniqueData = [];
    let duplicateItems = [];
    const query = new Parse.Query("Paid"); // Replace 'YourClassName' with your actual class name

    data.forEach((item) => {
      // Check if the item is already in uniqueData
      const isDuplicate = uniqueData.some(
        (uniqueItem) =>
          uniqueItem.hoursWorked === item.hoursWorked &&
          uniqueItem.employeeId === item.employeeId &&
          uniqueItem.payrollCycle === item.payrollCycle &&
          uniqueItem.employeeName === item.employeeName
      );

      if (isDuplicate) {
        duplicateItems.push(item);
      } else {
        uniqueData.push(item);
      }
    });

    duplicateItems.forEach(async (duplitcates) => {
      let id = duplitcates.id;
      try {
        const object = await query.get(id);
        try {
          await object.destroy();
        } catch (error) {
          console.error("Error while deleting ParseObject", error);
        }
      } catch (error) {
        console.error("Error while retrieving ParseObject", error);
      }
    });
    return uniqueData;
  };

  const processDataForGraph = (data) => {
    // Function to parse payrollCycle and return the start and end dates
    const parseDatesFromCycle = (cycle) => {
      const regex = /\((\d{1,2} \w+ \d{4}) - (\d{1,2} \w+ \d{4})\)/;
      const match = cycle.match(regex);
      return match
        ? { startDate: new Date(match[1]), endDate: new Date(match[2]) }
        : null;
    };

    const accumulatedData = {};
    const processedCycles = new Set(); // To track processed payrollCycles

    data.forEach((item) => {
      const cycleDates = parseDatesFromCycle(item.payrollCycle);
      if (!cycleDates) {
        console.error("Invalid payroll cycle format:", item.payrollCycle);
        return;
      }

      // Create a composite key of employeeId and payrollCycle
      const compositeKey = `${item.employeeId}_${item.payrollCycle}`;

      // Check if this combination of employeeId and payrollCycle has already been processed
      if (processedCycles.has(compositeKey)) {
        return; // Skip this item if its combination is already processed
      }
      processedCycles.add(compositeKey);

      // Use the endDate to determine the month for accumulation
      const monthYearKey = cycleDates.endDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      if (!accumulatedData[monthYearKey]) {
        accumulatedData[monthYearKey] = {
          totalPayment: 0,
          cashPayment: 0,
          sortDate: cycleDates.endDate,
        };
      }

      accumulatedData[monthYearKey].totalPayment += item.totalPayment;
      accumulatedData[monthYearKey].cashPayment += item.cashPayment;
    });

    // Sort labels chronologically based on the endDate
    const labels = Object.keys(accumulatedData).sort((a, b) => {
      return (
        new Date(accumulatedData[a].sortDate) -
        new Date(accumulatedData[b].sortDate)
      );
    });

    const cashPayments = labels.map((key) => accumulatedData[key].cashPayment);
    const totalPayments = labels.map(
      (key) => accumulatedData[key].totalPayment
    );

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Cash Payments",
          data: cashPayments,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Total Payments",
          data: totalPayments,
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
       <Grid container>
        <Grid item xs={12}>
          {" "}
          {/* Use the full width for the graph */}
          <Card raised>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                Employee Payment Analysis
              </Typography>
              {error ? (
                <Typography color="error">{error}</Typography>
              ) : loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="300px"
                >
                  <CircularProgress />
                </Box>
              ) : (
                // Expand the graph to take the full width of the grid item
                <Bar data={chartData} options={chartOptions} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaidDataGraph;
