import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Package = {
  id: string;
  name: string;
  amount: string;
  price: string;
  recurrence: string;
};

type PackageStateType = {
  packages: Package[];
};

const initialState: PackageStateType = {
  packages: [],
};

export const packagesSlice = createSlice({
  name: "packagesState",
  initialState,
  reducers: {
    setPackages: (
      state: PackageStateType,
      action: PayloadAction<{ packages: Package[] }>,
    ) => {
      state.packages = action.payload.packages;
    },
  },
});

export const { setPackages } = packagesSlice.actions;
export default packagesSlice.reducer;
