import React from 'react';
import { Paper, Typography, Divider, Grid, Card, CardContent, Avatar, useTheme, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParseSDK } from 'hooks/useParseSDK';
import { useAppSelector } from "../../redux/store";


export const UsersList = ({ usersList, setUserList }) => {
  const theme = useTheme(); // Use theme for consistent styling
  const {Parse}=useParseSDK();
  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user[0]?.business;
  const userData = user[0]?.user;
  // Yup schema for validation
  const Userbusinessid = user.map((user) => user.business.id);
  const businessId = Userbusinessid[0];
  const userId = userData[0];


  const fetchUserList = async () => {
   const data=await Parse.Cloud.run(
      "fetchUsers",
      {
        businessId: businessId,
      },
      {
        user: userId,
      }
    );
    setUserList(data);
  };

  const onDelete = async (userId) => {
  try {
    // Replace 'userId' with the actual user ID you wish to delete
    await Parse.Cloud.run('deleteUser', { userId: userId });
    fetchUserList();
  } catch (error) {
    console.error('Error while deleting user:', error);
  }
  };
  
  return (
    <Paper elevation={3} sx={{ padding: theme.spacing(3), marginTop: theme.spacing(3), backgroundColor: theme.palette.background.paper }}>
      <Typography variant="h4" gutterBottom>
        Users List
      </Typography>
      <Divider sx={{ margin: theme.spacing(1, 0) }} />
      <Grid container spacing={2}>
        {usersList?.map((userItem) => {
          const initial = userItem?.firstName?.[0]?.toUpperCase() || userItem?.email?.[0]?.toUpperCase() || "E";
          const avatarBgColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={userItem.id}>
              <Card sx={{ 
                position: 'relative', // Added for positioning the delete icon
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[3],
                transition: '0.3s',
                '&:hover': {
                  boxShadow: theme.shadows[10],
                  transform: 'translateY(-5px)',
                },
                height: '100%',
                backgroundColor: theme.palette.background.default,
              }}>
                <IconButton 
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    color: theme.palette.grey[500], // Adjust color based on your theme
                  }}
                  onClick={() => onDelete(userItem.id)} // Assuming onDelete is a prop function that handles the delete logic
                >
                  <DeleteIcon />
                </IconButton>
                <Avatar sx={{ width: 66, height: 66, marginBottom: 2, bgcolor: avatarBgColor }}>
                  {initial}
                </Avatar>
                <CardContent>
                  <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold' }}>
                    {userItem?.firstName || userItem?.email?.[0]?.toUpperCase()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

