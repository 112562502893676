import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Bill = {
  id: string;
  name: string;
  amount: string;
  recurrence: string;
};

type billsStateType = {
  bills: Bill[];
};

const initialState: billsStateType = {
  bills: [],
};

export const billsSlice = createSlice({
  name: "billsState",
  initialState,
  reducers: {
    setBills: (
      state: billsStateType,
      action: PayloadAction<{ bills: Bill[] }>,
    ) => {
      state.bills = action.payload.bills;
    },
  },
});

// Export the new action along with the existing one
export const { setBills } = billsSlice.actions;
export default billsSlice.reducer;
