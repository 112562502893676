import { useParseSDK } from "hooks/useParseSDK";

const {Parse}=useParseSDK();

// Utility function to set properties for Parse Object from event
const setEventProperties = (eventObj, event, businessId, parentId = null) => {
  // Set properties as before
  eventObj.set("Business", { __type: "Pointer", className: "Business", objectId: businessId });
  eventObj.set("date", event.date);
  eventObj.set("start", new Date(event.start));
  eventObj.set("end", new Date(event.end));
  eventObj.set("isRecurring", event.isRecurring);
  eventObj.set("recurrence", event.recurrence || "");
  eventObj.set("lastDate", event.lastDate ? event.lastDate : "");
  eventObj.set("recipe", { __type: "Pointer", className: "Recipes", objectId: event.recipe });
  if (parentId) {
    eventObj.set("parentId", { __type: "Pointer", className: "Scheduled_Recipes", objectId: parentId });
  }
  eventObj.set("ingredients", event.ingredients.map(ingredientId => ({
    __type: "Pointer",
    className: "Ingredients",
    objectId: ingredientId.id,
  })));
  eventObj.set("isDone", event.isDone || false);
  eventObj.set("batchNumber",event.batchNumber ? event.batchNumber.toString():"");
  eventObj.set("batches", event.batches? event.batches.toString():"");
  // Add any other necessary properties here
};

export const handleSaveRecurringEvents = async (businessId, events) => {
  try {
    // Separate the single parent event and child events
    const parentEvent = events.find(event => event.isRecurring && !event.parentId);
    const childEvents = events.filter(event => event.parentId);

    if (!parentEvent) {
      throw new Error("No parent event found");
    }

    // Prepare Parse object for the parent event
    const parentEventObj = new Parse.Object("Scheduled_Recipes");
    setEventProperties(parentEventObj, parentEvent, businessId);

    // Save the parent event to get its ID
    const savedParentEvent = await parentEventObj.save();
    const parentId = savedParentEvent.id; // Get the saved parent ID

    // Prepare Parse objects for child events, now that we have the parent ID
    const childEventObjects = childEvents.map(childEvent => {
      const childEventObj = new Parse.Object("Scheduled_Recipes");
      // Here, directly use the parentId for all child events
      setEventProperties(childEventObj, childEvent, businessId, parentId);
      return childEventObj;
    });

    // Save child events in a single batch
    await Parse.Object.saveAll(childEventObjects);
  } catch (error) {
    console.error("Error saving recurring events", error);
  }
};

export const handleSaveSingleEvent=async (businessId,event)=>{
  try{
    const singleEventObj = new Parse.Object("Scheduled_Recipes");
  setEventProperties(singleEventObj, event, businessId);
  await singleEventObj.save();
  }catch (error){
    console.error("Error saving single events", error);

  }
};



// export const handleSaveScheduledRecipes = async (
//   scheduledRecipes,
//   recipes,
//   businessId
// ) => {
//   // Save the scheduled recipes to the "Scheduled_Recipes" class on Back4App

//   const ScheduledRecipes = Parse.Object.extend("Scheduled_Recipes");
//   const scheduledRecipesObjects = scheduledRecipes.map((scheduledRecipe) => {
//     const scheduledRecipeName = scheduledRecipe.recipe;
//     const selectedRecipe = recipes.find(
//       (recipe) => recipe.name === scheduledRecipeName
//     );

//     const selectedIngredients = selectedRecipe.ingredients;
//     const selectedIngredientsIds = selectedIngredients.map(
//       (ingredient) => ingredient.id
//     );

//     const scheduledRecipeObj = new ScheduledRecipes();

//     scheduledRecipeObj.set("recipe", {
//       __type: "Pointer",
//       className: "Recipes",
//       objectId: selectedRecipe.id,
//     });
//     scheduledRecipeObj.set(
//       "ingredients",
//       selectedIngredientsIds.map((ingredientId) => {
//         return {
//           __type: "Pointer",
//           className: "Ingredients",
//           objectId: ingredientId,
//         };
//       })
//     );
//     scheduledRecipeObj.set("batches", scheduledRecipe.batches);
//     scheduledRecipeObj.set("isRecurring", scheduledRecipe.isRecurring);
//     scheduledRecipeObj.set("recurrence", scheduledRecipe.recurrence);
//     scheduledRecipeObj.set("lastDate", scheduledRecipe.lastDate);
//     scheduledRecipeObj.set("date", scheduledRecipe.date);
//     scheduledRecipeObj.set("start", scheduledRecipe.start);
//     scheduledRecipeObj.set("end",scheduledRecipe.end);
//     scheduledRecipeObj.set("Business", {
//       __type: "Pointer",
//       className: "Business",
//       objectId: businessId,
//     });

//     return scheduledRecipeObj;
//   });

//   try {
//     const savedScheduledRecipes = await Parse.Object.saveAll(
//       scheduledRecipesObjects
//     );
//     console.log("Scheduled recipes saved successfully:", savedScheduledRecipes);
//     alert("Recipes Scheduled");
//   } catch (error) {
//     console.log("Error saving scheduled recipes:", error);
//   }
// };

export const updateScheduledRecipe = async (objectId, batchNumber, batches, isDone) => {
  try {
    const ScheduledRecipes = Parse.Object.extend("Scheduled_Recipes");
    const query = new Parse.Query(ScheduledRecipes);
    query.equalTo("objectId", objectId);
    const scheduledRecipeObject = await query.first();

    if (scheduledRecipeObject) {
      scheduledRecipeObject.set("batchNumber", batchNumber);
      scheduledRecipeObject.set("batches", batches);
      scheduledRecipeObject.set("isDone", isDone);
      await scheduledRecipeObject.save();
    } else {
      alert("Scheduled recipe not found.");
    }
  } catch (error) {
    alert("Error updating scheduled recipe:", error);
  }
};

export async function deleteEvents(eventsToDelete) {
  // Ensure there are events to delete
  if (!eventsToDelete || eventsToDelete.length === 0) {
    alert("No events to delete.");
    return;
  }
  // Convert event info to Parse Objects
  const objectsToDelete = eventsToDelete.map(event => {
    const ScheduledRecipes = Parse.Object.extend("Scheduled_Recipes");
    const parseObject = ScheduledRecipes.createWithoutData(event.id);
    return parseObject;
  });

  try {
    // Use Parse's destroyAll to delete the objects
    await Parse.Object.destroyAll(objectsToDelete);
  } catch (error) {
    console.error("Failed to delete events from Parse:", error);
  }
}

export const deleteSingleEvent=async (parseObjectId)=>{
 // Ensure there is an object ID provided
 if (!parseObjectId) {
  alert("No Parse object ID provided for deletion.");
  return;
}

try {
  // Create a Parse object reference for the event to be deleted
  const ScheduledRecipes = Parse.Object.extend("Scheduled_Recipes");
  const eventToDelete = new ScheduledRecipes();
  eventToDelete.id = parseObjectId; // Directly set the object ID to the reference

  // Use Parse's destroy method to delete the object
  await eventToDelete.destroy();
} catch (error) {
  alert("Failed to delete the event from Parse:", error);
}
};