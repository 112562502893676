import { useInvoicesState } from "@reduxHooks/useInvoicesState";
import {format} from "date-fns";


interface StockItem {
  category: string;
  name: string;
  price: number;
  qty: number;
  quantity: number;
  stockId: string;
  unit: string; // add unit property here
}

interface StockChange {
  date: string;
  calculatedPrice: number;
  calculatedQuantity: number;
  unit: string;
}

interface OrganizedStockData {
  monthYear: string;
  stockId: string;
  changes: StockChange[];
}

const formatDate = (date: string, formatString: string) => {
  const invoiceDate = new Date(date);
  return format(invoiceDate, formatString);
};

export const organizeStockData = () => {
 const {invoicesState} = useInvoicesState();
 const invoices =invoicesState.invoices;

 if (!invoices || !Array.isArray(invoices)) return [];
  const organizedStockData: { [key: string]: OrganizedStockData } = {};

  invoices.forEach((invoice) => {
    const monthYear = formatDate(invoice.date, 'MMMM-yyyy');
    invoice.stock.forEach((stockItem: StockItem) => {
      const stockKey = `${stockItem.stockId}_${monthYear}`;
      if (!organizedStockData[stockKey]) {
        // Ensure the first entry matches the StockChange type
        organizedStockData[stockKey] = { 
          monthYear, 
          stockId: stockItem.stockId, 
          changes: [{
            date: invoice.date,
            calculatedPrice: stockItem.price * stockItem.qty, // Add calculatedPrice
            calculatedQuantity: stockItem.quantity * stockItem.qty, // Add calculatedQuantity
            unit: stockItem.unit // Add unit
          }] 
        };
      } else {
        const group = organizedStockData[stockKey];
        if (!group.changes.some(change => change.date === invoice.date)) {
          group.changes.push({
            date: invoice.date,
            calculatedPrice: stockItem.price * stockItem.qty,
            calculatedQuantity: stockItem.quantity * stockItem.qty,
            unit: stockItem.unit,
          });
        }
      }
    });
  });

  return Object.values(organizedStockData).map((group) => ({
    ...group,
    changes: group.changes.sort((a, b) => new Date(b.date).getDate() - new Date(a.date).getDate()),
  }));
};
