import React, {useState,useEffect} from"react";
import {Modal,Box} from "@mui/material";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lotties/success_lottie.json";
import ErrorLottie from "../../assets/lotties/error_lottie.json";

 const ResponseModal =({show,setShow, success,errorResponse,})=>{
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'transparent',
        boxShadow: 0,
        p: 4,
      };
    
    return(
        <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ backgroundColor: 'transparent'}}
      >
  
    <Box sx={style}>
      {success && (
        <Lottie
          animationData={SuccessLottie}
          height={200}
          width={200}
          loop={false}
          style={{ backgroundColor: 'transparent' }}
        />
      )}
      {errorResponse && (
        <Lottie
          animationData={ErrorLottie}
          height={200}
          width={200}
          loop={false}
          style={{ backgroundColor: 'transparent' }}
        />
      )}
    </Box>
  
</Modal>
    );
};

export default ResponseModal;