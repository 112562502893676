import { useParseSDK } from "hooks/useParseSDK";

export const handleAddStocks = async (
  category,
  name,
  price,
  quantity,
  unit,
  businessId
) => {
  const { Parse } = useParseSDK();
  // Create a new Stocks object
  const Stocks = Parse.Object.extend("Stocks");
  try {
    const newStock = new Stocks();

    // Set the values for the new stock
    newStock.set("Category", category);
    newStock.set("Name", name);
    newStock.set("Price", price);
    newStock.set("Quantity", quantity);
    newStock.set("Unit", unit);
    newStock.set("Business", {
      __type: "Pointer",
      className: "Business",
      objectId: businessId,
    });

   await newStock.save();
   
  } catch (error) {
    console.error(`Error while saving stock: ${error}`);
    throw error;
  }
};

export const handleUpdateStocks = async (
  stockId,
  Name,
  Price,
  Quantity,
  Unit,
  businessId
) => {
  const { Parse } = useParseSDK();
  const Stocks = Parse.Object.extend("Stocks");
  try {
    const query = new Parse.Query(Stocks);

    // Retrieve the stock object
    const stock = await query.get(stockId);

    // Update the stock object
    stock.set("Name", Name);
    stock.set("Price", Price);
    stock.set("Quantity", Quantity);
    stock.set("Unit", Unit);

    // Save the updated stock object to the backend
    await stock.save();

    console.log("Stock updated successfully!");
  } catch (error) {
    console.error("Error while updating stock: ", error);
  }
};

// handleDeleteStocks function
export const handleDeleteStocks = async (stockId) => {
  const { Parse } = useParseSDK();
  const Stocks = Parse.Object.extend("Stocks");
  try {
    const query = new Parse.Query(Stocks);

    // Retrieve the stock object
    const stock = await query.get(stockId);

    // Delete the stock object from the backend
    await stock.destroy();

  } catch (error) {
    console.error("Error while deleting stock: ", error);
  }
};
