import React, { useState } from "react";
import {
  Box,
  Grid,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useRollbar } from "@rollbar/react";
import CloseIcon from "@mui/icons-material/Close";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import { handleDeleteRecipe, deleteIngredient } from "@api/CRUDOp"; // Adjust the path to where your CRUDOp.js is located
import { useStockState } from "@reduxHooks/useStocksState";
import { Cancel, DeleteForever } from "@mui/icons-material";
import ErrorCodes from "@components/ErrorCode";
import { EditableIngredientsList } from "./EditableIngredientList";
import { EditableTextField } from "./EditableTextField";
import { v4 as uuidv4 } from "uuid";
import { EditablePackagingList } from "./EditablePackagingList";
import { usePackagesState } from "@reduxHooks/usePackagesState";
import { useFetchRecipes } from "@api/recipe";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lotties/success_lottie.json"; // Update the path as necessary
import ErrorLottie from "../../assets/lotties/error_lottie.json"; // Update the path as necessary

// Define the styles for the Details section
const detailStyles = {
  recipeName: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  detailItem: {
    marginBottom: "0.25rem",
  },
  detailLabel: {
    color: "text.secondary",
    display: "block",
    marginBottom: "0.25rem",
  },
  detailValue: {
    color: "text.primary",
    fontWeight: "normal",
  },
  methodText: {
    whiteSpace: "pre-line",
    marginTop: "1rem",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "90vh",
    borderRadius: 2,
    padding: "2rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "grey.500",
  },
};

// New components to handle the tabbed interface
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ErrorDialog = ({ open, errorCode, onClose }) => {
  const errorMessage = ErrorCodes[errorCode] || ErrorCodes.ERR_UNKNOWN;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RecipeModal = ({
  recipe,
  open,
  onClose,
  isEditing,
  setIsEditing,
  onSave,
}) => {
  const rollbar=useRollbar();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [error, setError] = useState(null);
  const [newItemIds, setNewItemIds] = useState([]); // State to track new items

  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDeleteError, setShowDeleteError] = useState(false);

  const requestDelete = (item) => {
    setItemToDelete(item);
    setIsDeleteDialogOpen(true);
  };
  const { fetchRecipes } = useFetchRecipes();

  const handleDelete = async () => {
    if (itemToDelete) {
      try {
        await handleDeleteRecipe(itemToDelete.id); // Adjust this if necessary
        setIsDeleteDialogOpen(false);
        setItemToDelete(null);
        fetchRecipes(); // Refresh recipes list
        setShowDeleteSuccess(true);
        setTimeout(() => {
          setShowDeleteSuccess(false);
          onClose(); // Close the modal
        }, 3000); // Hide success animation after a delay and close the modal
      } catch (error) {
        rollbar.log('Deleting Recipe error', error);
        setShowDeleteError(true);
        setTimeout(() => setShowDeleteError(false), 3000); // Hide error animation after a delay
        setError("ERR_DELETE_ITEM");
      }
    }
  };

  const modalContentStyle = {
    ...detailStyles.modalContent,
    width: "auto", // Allow automatic width adjustment
    minWidth: "300px", // Minimum width
    maxWidth: "80%", // Maximum width, adjust as needed
    // Add any additional styling as required
  };

  const [editedRecipe, setEditedRecipe] = useState({ ...recipe });

  const handleEditChange = (prop) => (event) => {
    setEditedRecipe({ ...editedRecipe, [prop]: event.target.value });
  };

  const handleItemChange = (index, field, value, type) => {
    const newList = [...editedRecipe[type]];
    newList[index] = { ...newList[index], [field]: value };
    setEditedRecipe({ ...editedRecipe, [type]: newList });
  };

  const handleDeleteItem = async (id) => {
    try {
      const updatedItems = editedRecipe.ingredients.filter(
        (item) => item.id !== id
      );
      setEditedRecipe({ ...editedRecipe, ingredients: updatedItems });
      await deleteIngredient(id);
      fetchRecipes();
    } catch (error) {
      setError("ERR_DELETE_ITEM");
    }
  };

  const handleSave = () => {
    onSave(editedRecipe);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const { stockState } = useStockState();

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const addNewItem = (type) => {
    const generateTwoDigitId = () => Math.floor(Math.random() * 90 + 10); // Generates a number between 10 and 99
  
    if (type === "packaging") {
      const newItem = { name: "", amount: "", price: "", serves: "" };
      // Generate a temporary unique ID for the new item using a 2-digit random number
      const newId = generateTwoDigitId();
      const updatedItems = [...editedRecipe[type], { ...newItem, id: newId }];
      setEditedRecipe({ ...editedRecipe, [type]: updatedItems });
      // Add the new ID to the newItemIds state
      setNewItemIds([...newItemIds, newId]);
    } else {
      const newItem = { stockId: "", amount: "", unit: "", category: "" };
      // Generate a temporary unique ID for the new item using a 2-digit random number
      const newId = generateTwoDigitId();
      const updatedItems = [...editedRecipe[type], { ...newItem, id: newId }];
      setEditedRecipe({ ...editedRecipe, [type]: updatedItems });
      // Add the new ID to the newItemIds state
      setNewItemIds([...newItemIds, newId]);
    }
  };
  

  const onCancelNewItem = (id, type) => {
    // Remove the new item from the edited recipe's list based on the type (ingredients, topping, etc.)
    const updatedItems = editedRecipe[type].filter((item) => item.id !== id);
    setEditedRecipe({ ...editedRecipe, [type]: updatedItems });

    // Also remove the ID from the newItemIds state
    setNewItemIds(newItemIds.filter((newId) => newId !== id));
  };
  const [selectedPackaging, setSelectedPackaging] = useState(
    recipe.packaging.map((pkg) => pkg.id)
  );

  // Handler for when a new package is added
  const handleAddNewPackage = (packageToAdd) => {
    setSelectedPackaging([...selectedPackaging, packageToAdd]);
    setEditedRecipe({
      ...editedRecipe,
      packaging: [...editedRecipe.packaging, { id: packageToAdd }],
    });
  };

  // Handler for when a package is deleted
  const handleDeletePackage = (packageId) => {
    setSelectedPackaging(selectedPackaging.filter((id) => id !== packageId));
    setEditedRecipe({
      ...editedRecipe,
      packaging: editedRecipe.packaging.filter((pkg) => pkg.id !== packageId),
    });
  };

  // Handler for when a new package is canceled (deselected)
  const handleCancelNewPackage = (packageId) => {
    setSelectedPackaging(selectedPackaging.filter((id) => id !== packageId));
    setEditedRecipe({
      ...editedRecipe,
      packaging: editedRecipe.packaging.filter((pkg) => pkg.id !== packageId),
    });
  };

  const { packagesState } = usePackagesState();
  return (
    <Modal
      open={open}
      onClose={() => {
        setIsEditing(false);
        onClose();
      }}
      closeAfterTransition
    >
      
        <Box sx={modalContentStyle}>
          {showDeleteSuccess ? (
            <Box display="flex" justifyContent="center" my={2}>
              <Lottie
                animationData={SuccessLottie}
                height={200}
                width={200}
                loop={false}
              />
            </Box>
          ) : showDeleteError ? (
            <Box display="flex" justifyContent="center" my={2}>
              <Lottie
                animationData={ErrorLottie}
                height={200}
                width={200}
                loop={false}
              />
            </Box>
          ) : (
            <>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={detailStyles.closeButton}
              >
                <CloseIcon />
              </IconButton>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="recipe tabs"
                centered
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Ingredients" {...a11yProps(1)} />
                <Tab label="Toppings" {...a11yProps(2)} />
                <Tab label="Base" {...a11yProps(3)} />
                <Tab label="Packaging" {...a11yProps(4)} />
              </Tabs>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                {isEditing ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      startIcon={<Cancel />}
                      onClick={handleCancel}
                      color="error"
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => setIsEditing(true)}
                      color="secondary"
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<DeleteForever />}
                      onClick={() => requestDelete(recipe)}
                      color="error"
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <EditableTextField
                      label="Recipe Name"
                      value={editedRecipe.name}
                      onChange={handleEditChange("name")}
                      isEditing={isEditing}
                    />
                    <EditableTextField
                      label="Cook Time (min)"
                      value={editedRecipe.cookTime}
                      onChange={handleEditChange("cookTime")}
                      isEditing={isEditing}
                    /> 
                    <EditableTextField
                    label="Prep Time (min)"
                    value={editedRecipe.prepTime}
                    onChange={handleEditChange("prepTime")}
                    isEditing={isEditing}
                  />
                    <EditableTextField
                      label="Temperature"
                      value={editedRecipe.cookTemp}
                      onChange={handleEditChange("cookTemp")}
                      isEditing={isEditing}
                    />
                    <EditableTextField
                      label="Servings"
                      value={editedRecipe.servings}
                      onChange={handleEditChange("servings")}
                      isEditing={isEditing}
                    />
                    <EditableTextField
                      label="Price"
                      value={editedRecipe.retailPrice}
                      onChange={handleEditChange("retailPrice")}
                      isEditing={isEditing}
                    />
                    <EditableTextField
                      label="Method"
                      value={editedRecipe.method}
                      onChange={handleEditChange("method")}
                      isEditing={isEditing}
                      multiline
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <ErrorDialog
                open={!!error}
                error={error}
                onClose={() => setError(null)}
              />

              <TabPanel value={tabValue} index={1}>
                <EditableIngredientsList
                  title="Ingredients"
                  items={editedRecipe.ingredients}
                  onChange={(index, field, value) =>
                    handleItemChange(index, field, value, "ingredients")
                  }
                  isEditing={isEditing}
                  onAddNewItem={() => addNewItem("ingredients")}
                  onDeleteItem={handleDeleteItem}
                  onCancelNewItem={(id) => onCancelNewItem(id, "ingredients")}
                  stockOptions={stockState.stock}
                  newItems={newItemIds}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <EditableIngredientsList
                  title="Toppings"
                  items={editedRecipe.topping}
                  onChange={(index, field, value) =>
                    handleItemChange(index, field, value, "topping")
                  }
                  isEditing={isEditing}
                  onAddNewItem={() => addNewItem("topping")}
                  onDeleteItem={handleDeleteItem}
                  onCancelNewItem={(id) => onCancelNewItem(id, "topping")}
                  stockOptions={stockState.stock}
                  newItems={newItemIds}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <EditableIngredientsList
                  title="Base"
                  items={editedRecipe.base}
                  onChange={(index, field, value) =>
                    handleItemChange(index, field, value, "base")
                  }
                  isEditing={isEditing}
                  onAddNewItem={() => addNewItem("base")}
                  onDeleteItem={handleDeleteItem}
                  onCancelNewItem={(id) => onCancelNewItem(id, "base")}
                  stockOptions={stockState.stock}
                  newItems={newItemIds}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <EditablePackagingList
                  title="Packaging"
                  items={editedRecipe.packaging}
                  allPackages={packagesState.packages} // Pass the full list of available packages
                  onChange={handleItemChange}
                  isEditing={isEditing}
                  onAddNewItem={handleAddNewPackage}
                  onDeleteItem={handleDeletePackage}
                  onCancelNewItem={handleCancelNewPackage}
                  selectedItems={selectedPackaging}
                  setSelectedItems={setSelectedPackaging}
                />
              </TabPanel>
              <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setIsDeleteDialogOpen(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleDelete()}
                    color="primary"
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Box>
  
    </Modal>
  );
};

export default RecipeModal;
