import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store"; // Import your custom selector
import { setScheduleData as setScheduleDataRedux } from "../slices/schedule.slice";

export const useScheduledRecipesState = () => {
  const dispatch = useDispatch();
  const scheduledRecipesState = useAppSelector(
    (state) => state.scheduledRecipesState,
  );

  const setScheduleData = useCallback(
    (data: typeof scheduledRecipesState) => {
      dispatch(
        setScheduleDataRedux({ scheduledRecipes: data.scheduledRecipes }),
      );
    },
    [dispatch],
  );

  return {
    scheduledRecipesState,
    setScheduleData,
  };
};
