import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Loader } from "../components/Loder";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SuccessLottie from "../.././../assets/lotties/success_lottie.json";
import ErrorLottie from "../.././../assets/lotties/error_lottie.json";
import MembersLottie from "../.././../assets/lotties/members.json";
import Lottie from "lottie-react";
import { v4 as uuidv4 } from "uuid";
import { useUserState } from "@reduxHooks/useLoginState";
import { handleInviteUsers } from "@api/handleUserCRUD";
import { useNavigate } from "react-router-dom";

const ROLES = ["Manager", "Employee"];

export const InviteMembersWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [role, setRole] = useState(ROLES[0]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { loginState } = useUserState();
  // const nextHandler = useRef<any>(undefined);

  const Userbusiness = loginState.user.map((user) => user.business.id);
  const businessId = Userbusiness[0];

  const handleSubmitInvite = async () => {
    try {
      setLoading(true);
      await handleInviteUsers(users, businessId);
      // nextHandler.current = NextHandler;
      setIsSuccess(true);
    } catch (error: any) {
      setIsError("Failed to invite users.");
      setIsError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const ListItem = ({
    email,
    role,
    id,
  }: {
    email: string;
    role: string;
    id: string;
  }) => {
    return (
      <Box
        sx={{
          padding: "7px 16px",
          margin: "8px 0",
          borderRadius: "5px",
          backgroundColor: "#DEDEDE70",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Typography
          fontSize="14px"
          fontWeight={900}
          letterSpacing="2px"
          color="#fff"
          flexGrow={1}
          sx={{
            backgroundColor: "gray",
            padding: "2px 10px",
            borderRadius: 2,
          }}>
          {email}
        </Typography>
        <Box
          sx={{
            backgroundColor: "red",
            borderRadius: 2,
            marginLeft: "30px",
            padding: "2px 10px",
          }}>
          <Typography
            fontSize="12px"
            fontWeight={900}
            letterSpacing="2px"
            color="#f4f4f4"
            textTransform="uppercase">
            {role}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", width: "60px" }}>
          <Box
            onClick={() => {
              setUsers(users.filter((item: any) => item.id !== id));
            }}
            sx={{
              marginRight: "1rem",
              height: "24px",
              borderRadius: "2px",
              margin: "0  2rem",
            }}>
            <DeleteForeverIcon
              sx={{
                height: "24px",
                width: "24px",
                "&:hover": {
                  color: "red",
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return {
    InviteMembers: () => {
      if (!loading && !isError && !isSuccess) {
        return (
          <Box
            sx={{
              minHeight: "600px",
              backgroundColor: "#dcdcdc25",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
            }}>
            {/* title */}
            <Typography fontSize="18px" letterSpacing="2px" fontWeight={600}>
              Add Your Team Members
            </Typography>
            {/* inpput + role dropdown + add btn */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                marginTop: "1rem",
                gap: 2,
              }}>
              <TextField
                label="Member Email"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  flexGrow: 1,
                }}
              />

              <Select
                value={role}
                size="small"
                title="Role"
                sx={{
                  minWidth: "200px",
                }}
                onChange={(e) => setRole(e.target.value)}>
                {ROLES.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                size="small"
                startIcon={<PersonAddAltIcon />}
                sx={{ minWidth: "120px", textTransform: "none" }}
                onClick={() => {
                  if (!role || !email) return;
                  setUsers((v: any) => [
                    ...v,
                    {
                      email,
                      role,
                      id: uuidv4(),
                    },
                  ]);
                  setEmail("");
                  setRole(ROLES[0]);
                }}>
                Add
              </Button>
            </Box>
            {/* show added items + edit / delete on hover */}
            <Box
              sx={{
                flexGrow: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {users.length > 0 ? (
                <Box
                  sx={{
                    height: "350px",
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}>
                  {users.map((item: any) => (
                    <ListItem
                      email={item.email}
                      role={item.role}
                      id={item.id}
                      key={item.id}
                    />
                  ))}
                </Box>
              ) : (
                <Lottie
                  animationData={MembersLottie}
                  autoPlay
                  loop
                  style={{
                    width: "60%",
                    height: "60%",
                    opacity: 0.3,
                  }}
                />
              )}
            </Box>
          </Box>
        );
      }

      if (loading) return <Loader />;

      if (Boolean(isError) || isSuccess) {
        return (
          <Box
            sx={{
              minHeight: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Lottie
              animationData={isSuccess ? SuccessLottie : ErrorLottie}
              loop={false}
              autoplay
              style={{
                width: "120px",
                height: "120px",
              }}
              onComplete={() => {
                if (isSuccess) {
                  setIsSuccess(false);
                  // nextHandler.current && nextHandler.current();
                  navigate("/dashboard");
                  return;
                }
                if (isError) {
                  setTimeout(() => {
                    setIsError("");
                    console.log(
                      "ERROR HAPPENED , reset states  and show error message"
                    );
                  }, 1000);
                }
              }}
            />
            {Boolean(isError) && (
              // TODO: replace hex color with one from theme later
              <Typography
                color="#ff3333"
                fontSize="16px"
                marginTop="18px"
                fontWeight={900}
                maxWidth="300px"
                textAlign="center">
                {isError}
              </Typography>
            )}
          </Box>
        );
      }
    },
    inviteSubmit: handleSubmitInvite,
  };
};
