import { useCallback } from 'react';
import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useSuppliersState } from "@reduxHooks/useSupplierState";

interface Certificate {
  _name: string;
  _url: string;
  // Include any other properties that might be present in a Certificate object
}

export const useFetchSuppliers = () => {
  const { Parse } = useParseSDK();
  const { suppliersState,setSuppliers } = useSuppliersState();
  const user = useAppSelector((state) => state.loginState.user);

  const businessId = user.map((user) => user.business.id)[0];
  const userId = user.map((user) => user.user)[0];

  const fetchSupplier = useCallback(async () => {
    if (!navigator.onLine) {
     // alert('You are offline. Fetching suppliers from Redux state.');
      // Suppliers should already be in the Redux state if fetched online previously
      if (suppliersState.suppliers && suppliersState.suppliers.length > 0) {
        return;  // No need to update state as it should already be set
      } else {
        setSuppliers({ suppliers: [] });  // Provide fallback if no suppliers are in state
        return;
      }
    } else {
      try {
        const data = await Parse.Cloud.run("fetchSuppliers", { businessId }, { user: userId });
        if (data && Array.isArray(data) && data.length > 0) {
          const modifiedSuppliers = data.map((supplier) => ({
            ...supplier,
            Certificates: Array.isArray(supplier.Certificates)
              ? supplier.Certificates.map((certificate: Certificate) => {
                  if (!certificate) return null;
                  const _name = certificate?._name ?? 'Unknown Name';
                  const _url = certificate?._url ?? 'Unknown URL';
                  return { _name, _url };
                }).filter((certificate: Certificate) => certificate !== null)
              : [],
          }));

          setSuppliers({ suppliers: modifiedSuppliers });
        } else {
          setSuppliers({ suppliers: [] });
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
        setSuppliers({ suppliers: [] });
      }
    }
  }, [Parse, businessId, userId, setSuppliers]);

  return { fetchSupplier };
};
