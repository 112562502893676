import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  TableBody,
  Button,
  Modal,
  Box,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextField,
  Grid,
} from "@mui/material";
import { useParseSDK } from "hooks/useParseSDK";
import PaidDataGraph from "./PayrollGraph";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEmployeeInfo } from "@api/employeeInfo";
import { useAppSelector } from "redux/store";
import ResponseModal from "@components/success/errorModal";

// Styling for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const PayrollComponent = ({ timesheetCycle, employeeData }) => {
  const { fetchEmployeeInfo } = useEmployeeInfo();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { Parse } = useParseSDK();
  const [selected, setSelected] = useState([]);
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const business = user.map((user) => user.business);
  const businessId = Userbusiness[0];
  const accountantName=business[0].accountantName;
  const accountantEmail=business[0].accountantEmail;
  const [showAnim, setSHowAnim]=useState(false);
  const [showSuccess,setShowSuccess]=useState(false);
  const [showError, setShowError]=useState(false);

  // Inside your component
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Adding a state to hold and update the sortedData
  const [data, setData] = useState([]);

  useEffect(() => {
    // Initialize data state with sortedData
    setData(calculateTotalWorkedHours(employeeData, timesheetCycle) || []);
  }, [employeeData, timesheetCycle]);

  useEffect(() => {
    fetchEmployeeInfo();
  }, []);

  // Function to handle cash payment change
  const handleCashPaymentChange = (id, value) => {
    setData(
      data?.map((item) => {
        if (item.id === id) {
          return { ...item, cash: Number(value) };
        }
        return item;
      })
    );
  };

  // Function to check if a row is selected
  const isRowSelected = (id) => selected.includes(id);

  // Simplified function to toggle select all
  const toggleSelectAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      setSelected(data?.map((n) => n.id || n.employee));
    }
  };

  // Function to handle the selection of a row
  const toggleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected?.filter((selectedId) => selectedId !== id);
    }

    setSelected(newSelected);
  };

  function calculateTotalWorkedHours(employeeDataArray, filteringCycle) {
    let startDate, endDate;

    // Verify and parse the date range if filteringCycle is provided
    if (filteringCycle) {
      const dateRegex = /\((\d{1,2}) (\w+) (\d{4}) - (\d{1,2}) (\w+) (\d{4})\)/;
      const matches = filteringCycle.match(dateRegex);

      if (matches) {
        startDate = parseDateString(matches[1], matches[2], matches[3]);
        endDate = parseDateString(matches[4], matches[5], matches[6]);
      } else {
        throw new Error(
          "filteringCycle format is incorrect. Expected format: 'Cycle Name (DD MMM YYYY - DD MMM YYYY)'"
        );
      }
    }

    function parseDateString(day, monthName, year) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      const monthIndex = monthNames.indexOf(monthName);
      if (monthIndex === -1) {
        throw new Error(`Invalid month name: ${monthName}`);
      }
      // We need to handle the case where day and month could be single digit
      return new Date(parseInt(year, 10), monthIndex, parseInt(day, 10));
    }

    // Function to parse the date strings in Timesheets
    const parseDate = (dateString) => {
      // Split the date and time parts
      const [datePart, timePart] = dateString.split(", ");

      // Extract the month, day, and year from the date part
      const [month, day, year] = datePart
        .split("/")
        .map((num) => parseInt(num, 10));

      // Create a new Date object using the extracted values
      // Note: Month is 0-indexed in JavaScript Dates, so we subtract 1 from the month
      const date = new Date(year, month - 1, day);

      // Set time to 00:00:00
      date.setHours(0, 0, 0, 0);

      return date;
    };

    // Filter and process each employee data
    return employeeDataArray?.flatMap((employeeInfo) => {
      if (
        !employeeInfo ||
        !employeeInfo.Timesheets ||
        employeeInfo.Timesheets.length === 0
      ) {
        return []; // Skip if no timesheet data
      }

      const filteredTimesheets = employeeInfo.Timesheets?.filter((sheet) => {
        if (sheet.status !== "Confirmed") return false;

        // Include all confirmed timesheets if filteringCycle is not provided
        if (!filteringCycle) return true;

        const sheetDate = parseDate(sheet.date);

        return startDate <= sheetDate && sheetDate <= endDate;
      });

      if (filteredTimesheets?.length === 0) return []; // No timesheets in the date range for this employee

      const totalWorkedHours = filteredTimesheets?.reduce(
        (acc, sheet) => acc + sheet.totalState,
        0
      );

      const employee = employeeInfo.employee;
      const employeeNameOrEmail = employee
        ? `${employee.firstName || ""}${employee.firstName ? " " : ""}${
            employee.lastName || ""
          }`.trim() || employee.email
        : "";

      return [
        {
          id: employeeInfo.id,
          employee: employeeNameOrEmail,
          totalWorkedHours: totalWorkedHours,
          payrollCycle: filteringCycle || "N/A",
          cash: 0,
          wage: employeeInfo.Wage,
          totalPayment: employeeInfo.wage * totalWorkedHours,
        },
      ];
    });
  }

  const handleCloseErrorModal = () => setIsErrorModalOpen(false);

  // Error Modal Component
  const ErrorModal = ({ open, handleClose, errorMessage }) => (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="error-modal-title"
    >
      <Box sx={style}>
        <Typography id="error-modal-title" variant="h6" component="h2">
          Error
        </Typography>
        <Typography sx={{ mt: 2 }}>{errorMessage}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );

  const sendEmail = (selectedEmployees, filteringCycle) => {
    const emailSubject = `Payroll Details for ${filteringCycle}`;
  const emailBody = `Dear ${accountantName},\n\n` +
    `Please find below the payroll details for the ${filteringCycle} cycle:\n\n` +
    selectedEmployees.map(employee => 
      `Employee Name: ${employee.employee},\t\t\t\t` +
      `Total Worked Hours: ${employee?.totalWorkedHours - employee?.cash / employee?.wage},\t\t\t\t` + // Removed < after the expression
      `Payroll Cycle: ${employee.payrollCycle},\t\t\t\t` + // Removed > after the label
      `Wage: $${(employee?.totalWorkedHours * employee?.wage) - employee?.cash},\n\n` // Removed < after the expression
    ).join('') +
    `Should you have any queries or require further clarification, please do not hesitate to contact the HR department.\n\n` +
    `Best regards,`;

  // Include the accountantEmail in the mailto link
  return `mailto:${encodeURIComponent(accountantEmail)}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
};  

  const handleSendEmail = async () => {
    if (selected.length === 0 || timesheetCycle === "") {
      setErrorMessage("Please select a payment cycle.");
      setIsErrorModalOpen(true);
      return;
    } else {
      const selectedEmployees = selected?.map((id) =>
        data?.find((data) => data.id === id)
      );

      // Array to hold all the new Parse objects
      const paidObjects = [];

      for (const employee of selectedEmployees) {
        const Paid = new Parse.Object("Paid");
        // Set the fields you want to save
        Paid.set("employeeId", employee.id);
        Paid.set("employeeName", employee.employee);
        Paid.set("hoursWorked", employee.totalWorkedHours);
        Paid.set("cashPayment", employee.cash);
        Paid.set("totalPayment", employee.wage * employee.totalWorkedHours);
        Paid.set("payrollCycle", timesheetCycle);
        Paid.set("Business", {
          __type: "Pointer",
          className: "Business",
          objectId: businessId,
        }); 
        // Add the object to the array
        paidObjects.push(Paid);
      }

      try {
        // Save all objects in a single batch
        await Parse.Object.saveAll(paidObjects);
        // Delay the redirection to the email client to allow the Snackbar to be seen
        setSHowAnim(true);
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          setSHowAnim(false);
        }, 2000);
          const mailtoLink = sendEmail(selectedEmployees, timesheetCycle);
          window.location.href = mailtoLink; // This will redirect to the email client
        
        // Waits 2 seconds before navigating to give the user time to see the Snackbar
      } catch (error) {
        // Show snackbar with error message
        setSHowAnim(true);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setSHowAnim(false);
        }, 2000);
      }
    }
  };

  return (
    <>
      <Typography variant="h4" component="h1" sx={{ margin: 4 }}>
        Payroll Management
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-start", margin: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={selected.length === 0}
          onClick={handleSendEmail}
        >
          Send Email
        </Button>
      </Box>

      <ErrorModal
        open={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorMessage}
      />

      <ResponseModal show={showAnim} setShow={setSHowAnim} success={showSuccess} errorResponse={showError} />    
        {/* List on the left */}
        <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Paper>
           <Table aria-label="employee list table">
            <TableHead>
              <TableRow>
                <CustomTableHeadCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === data.length && data.length > 0}
                    onChange={toggleSelectAll}
                    color="primary"
                  />
                </CustomTableHeadCell>
                <CustomTableHeadCell>Employee Name</CustomTableHeadCell>
                <CustomTableHeadCell>Total Worked Hours</CustomTableHeadCell>
                <CustomTableHeadCell>Total Payable Hours</CustomTableHeadCell>
                <CustomTableHeadCell>Payroll Cycle</CustomTableHeadCell>
                <CustomTableHeadCell>Cash Payment</CustomTableHeadCell>
                <CustomTableHeadCell>Total Payment</CustomTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ margin: 2, padding: 2, maxHeight:"80vh", overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              }, }}>
              {data?.map((cycle, index) => (
                <TableRow
                  key={cycle.id || cycle.employee || index}
                  selected={isRowSelected(cycle.id || cycle.employee)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isRowSelected(cycle.id)}
                      onChange={() => toggleSelect(cycle.id)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{cycle?.employee}</TableCell>
                  <TableCell>{cycle?.totalWorkedHours}</TableCell>
                  <TableCell>
                    {cycle?.totalWorkedHours - cycle?.cash / cycle?.wage}
                  </TableCell>
                  <TableCell>{cycle?.payrollCycle}</TableCell>
                  <TableCell>
                    <TextField
                      value={cycle?.cash || ""}
                      onChange={(e) =>
                        handleCashPaymentChange(cycle.id, e.target.value)
                      }
                      type="number"
                      placeholder="Cash"
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>${cycle?.totalWorkedHours * cycle?.wage}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
   
     
        {/* <CustomTableContainer component={Paper}>
      
        </CustomTableContainer> */}
      </Grid>
        {/* Graph on the right */}
        <Grid item xs={12} lg={6}>   
             <PaidDataGraph />
   
        </Grid>
        </Grid>
     
    </>
  );
};


const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.action.hover,
}));

export default PayrollComponent;
