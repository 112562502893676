// Importing Stock from slice file
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store"; // Replace with your root state type
import { setStock as setStockRedux, Stock } from "../slices/stock.slice"; // Import Stock along with slice actions

// Custom hook for managing stock state
export const useStockState = () => {
  const dispatch = useDispatch();

  // Get the current state of stock from the Redux store
  const stockState = useAppSelector((state) => state.stockState); // Replace `stockState` with your slice's actual name

  // Initialize stock
  const setStock = useCallback(
    (data: Record<string, Stock[]>) => {
      dispatch(setStockRedux(data));
    },
    [dispatch],
  );
  // Here, you can also add more utility functions like `addStock`, `deleteStock`, etc.

  return {
    stockState,
    setStock,
  };
};
