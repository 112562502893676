// styles.js

import styled from "styled-components";
// TODO : remove this when integrating the MUI theme
const brandColors = {
  primary: "#7045af",
  secondary: "#8b65c2",
  tertiary: "#59378a",
  black: "#111111",
  white: "#ffffff",
  gray: "#2a2a2a",
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const LeftContainer = styled.div`
  flex: 1;
  height: 100vh;
  background-color: ${brandColors.gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
`;

export const RightContainer = styled.div`
  flex: 1;
  height: 100vh;
  background-color: ${brandColors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${brandColors.gray};
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
    border-color: ${brandColors.primary};
  }
`;

export const LoginButton = styled.button`
  height: 50px;
  width: 80%;
  background-color: ${brandColors.primary};
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${brandColors.gray};
    transform: scale(1.05);
  }
`;

export const ResetButton = styled.button`
  background: none;
  border: none;
  color: ${brandColors.gray};
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${brandColors.black};
  }
`;

export const SButton = styled.button`
  background: none;
  border: none;
  color: ${brandColors.black};
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${brandColors.black};
  }
`;

export const Logo = styled.img`
  width: 300px;
  height: 300px;
  background: #2a2a2a;
`;

export const ErrorText = styled.p`
  color: red;
  width: 80%;
  text-align: left;
`;

export const QuoteContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 48px;
  color: ${brandColors.white};
  margin-top: 20px;
  text-align: center;
`;

export const DynamicQuoteText = styled.p`
  font-size: 16px;
  color: ${brandColors.white};
  margin-bottom: 10px;
  text-align: center;
  padding: 0 20px;
`;

export const Header = styled.header`
  background-color: ${brandColors.gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:before {
    content: "";
    display: ${(props) => (props.isOpen ? "inline-block" : "none")};
    margin-right: 10px;
  }

  &:after {
    content: ${(props) => (props.isOpen ? `"${props.name}"` : "")};
  }
`;
export const NavButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${brandColors.white};
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover {
    color: ${brandColors.black};
  }
`;

// ...

export const SidebarContainer = styled.div`
  background-color: ${brandColors.gray};
  width: ${(props) => (props.isOpen ? "20%" : "5%")};
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: width 0.3s ease;
  align-items: ${(props) => (props.isOpen ? "none" : "center")};
  height: calc(100vh - 66.67px);
`;

// ...

export const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.isOpen ? "space-between" : "flex-start"};
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  color: ${brandColors.white}; // change icon colors to white

  &:hover {
    background-color: ${brandColors.secondary};
    border-bottom-right-radius: 20px;
  }
`;

export const UserName = styled.p`
  color: ${brandColors.white};
  font-size: 20px;
  align-self: "center";
`;

export const ContentContainer = styled.div`
  width: 100%;
  /* your other styles here */
`;

export const HomeContainer = styled.div``;

export const HsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
