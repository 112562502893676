import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Paper,
  IconButton,
  Stack,
  Avatar,
} from "@mui/material";
import Lottie from "lottie-react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SuccessLottie from "../../assets/lotties/success_lottie.json";
import ErrorLottie from "../../assets/lotties/error_lottie.json";
import MembersLottie from "../../assets/lotties/members.json";
import { useUserState } from "@reduxHooks/useLoginState";
import { handleInviteUsers } from "@api/handleUserCRUD";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../../redux/store";
import { useParseSDK } from "hooks/useParseSDK";


const ROLES = ["Manager", "Employee"];

const InviteMembers = ({setInviteDialog, setUserList}) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState(ROLES[0]);
  const [email, setEmail] = useState("");
  const [name, setName]=useState("");
  const { loginState } = useUserState();
  const {Parse}=useParseSDK();
  const businessId = loginState.user.map((user) => user.business.id)[0];

  const user = useAppSelector((state) => state.loginState.user);

  const userData = user[0]?.user;
  const userId = userData[0];


  const fetchUserList = async () => {
    const data=  await Parse.Cloud.run(
      "fetchUsers",
      {
        businessId: businessId,
      },
      {
        user: userId,
      }
    );
    setUserList(data);
  };

  const handleSubmitInvite = async () => {
    setLoading(true);
    try {
      await handleInviteUsers(users, businessId);
      setIsSuccess(true);
      fetchUserList();
      setTimeout(() => {setIsSuccess(false);setInviteDialog(false);}, 3000);
      setUsers([]);
      setEmail("");
      setRole(ROLES[0]);
    } catch (error) {
      console.error("Failed to invite users:", error);
      setIsError("Failed to invite users. " + error.message);
      setTimeout(() => setIsError(""), 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  return (
    <Paper elevation={3} sx={{ p: 4, my: 2, width: '100%', maxWidth: 700, mx: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Invite Team Members
      </Typography>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={4}>
          <TextField
            label="Member Email"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="First Name"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            value={role}
            size="small"
            onChange={(e) => setRole(e.target.value)}
            fullWidth
          >
            {ROLES.map((role, index) => (
              <MenuItem key={index} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            startIcon={<PersonAddAltIcon />}
            onClick={() => {
              if (!role || !email) return;
              setUsers((prevUsers) => [
                ...prevUsers,
                { email, name, role, id: uuidv4() },
              ]);
              setEmail("");
              setRole(ROLES[0]);
            }}
            fullWidth
          >
            Add
          </Button>
        </Grid>
      </Grid>

      {users.map((user, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2, bgcolor: 'background.paper', p: 2, borderRadius: '8px' }}
        >
          <Avatar>{user.email[0].toUpperCase()}</Avatar>
          <Typography flexGrow={1}>
            {user.email} - {user.role} - {user.name}
          </Typography>
          <IconButton onClick={() => handleRemoveUser(user.id)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </Stack>
      ))}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {loading && <Lottie animationData={MembersLottie} loop style={{ width: 150, height: 150 }} />}
        {isError && (
          <>
            <Typography color="error" gutterBottom>{isError}</Typography>
            <Lottie animationData={ErrorLottie} loop={false} style={{ width: 150, height: 150 }} />
          </>
        )}
        {isSuccess && <Lottie animationData={SuccessLottie} loop={false} style={{ width: 150, height: 150 }} />}
        {!loading && !isError && !isSuccess && users.length === 0 && (
          <Lottie animationData={MembersLottie} loop style={{ width: 300, height: 300, opacity: 0.5 }} />
        )}
        <Button variant="outlined" sx={{ mt: 2 }} onClick={handleSubmitInvite} disabled={loading || users.length === 0}>
          Submit Invites
        </Button>
      </Box>
    </Paper>
  );
};

export default InviteMembers;
