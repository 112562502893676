import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useBillsState } from "@reduxHooks/useBillsState";

export const useFetchBills = () => {
  const { Parse } = useParseSDK();
  const { billsState, setBills } = useBillsState();
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);
  const businessId = Userbusiness[0];
  const userId = userData[0];

  return {
    fetchBills: async () => {
      if (!navigator.onLine) {
       // alert('You are offline. Fetching bills from Redux state.');
        // Bills should already be in the Redux state if they were fetched online previously
        if (billsState.bills && billsState.bills.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setBills({ bills: [] });  // Provide fallback if no bills are in state
          return;
        }
      }
     else{
      try {
        const data = await Parse.Cloud.run(
          "fetchBills",
          { businessId: businessId },
          { user: userId }
        );

        if (data && Array.isArray(data) && data.length > 0) {
          setBills({ bills: data });
        } else {
          setBills({ bills: [] });
        }
      } catch (error) {
        console.error('Failed to fetch bills:', error);
        setBills({ bills: [] });  // Handle error by setting empty or fallback data
      }}
    },
  };
};
