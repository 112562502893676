/* 
  Import necessary components from Material-UI to structure the layout.
*/
import React from 'react';
import { List, ListItem, Grid, Typography } from "@mui/material";

const ReadOnlyStockList = ({ currentInvoiceStocks }) => {
  return (
    <List dense>
      {currentInvoiceStocks?.map((stockItem, index) => (
        <ListItem key={index} divider>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{`Stock Name: ${stockItem.name}`}</Typography>
              <Typography variant="body2">{`Category: ${stockItem.category}`}</Typography>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={3}>
                <Typography variant="body1">{`Amount: ${stockItem.qty}`}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1">X</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{`Quantity: ${stockItem.quantity}`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">{`Unit: ${stockItem.unit}`}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{`Price: ${stockItem.price}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default ReadOnlyStockList;
