import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type initialStateType = {
  isAuth: boolean;
};

const initialState: initialStateType = {
  isAuth: false,
};

export const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setIsAuth: (state: initialStateType, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
  },
});

export const { setIsAuth } = globalSlice.actions;
export default globalSlice.reducer;
