import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import { setInvoices as setInvoicesRedux } from "../slices/invoices.slice";

export const useInvoicesState = () => {
  const dispatch = useDispatch();
  const invoicesState = useAppSelector((state) => state.invoicesState);

  const setInvoices = useCallback(
    (data: typeof invoicesState) => {
      dispatch(setInvoicesRedux({ invoices: data.invoices }));
    },
    [dispatch],
  );

  return {
    invoicesState,
    setInvoices,
  };
};
