import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const detailStyles = {
  recipeName: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  detailItem: {
    marginBottom: "0.25rem",
  },
  detailLabel: {
    color: "text.secondary",
    display: "block",
    marginBottom: "0.25rem",
  },
  detailValue: {
    color: "text.primary",
    fontWeight: "normal",
  },
  methodText: {
    whiteSpace: "pre-line",
    marginTop: "1rem",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxWidth: "600px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "90vh",
    borderRadius: 2,
    padding: "2rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "grey.500",
  },
};

export const EditableTextField = ({
  label,
  value,
  onChange,
  isEditing,
  multiline = false,
}) => (
  <Box sx={detailStyles.detailItem}>
    <Typography variant="h6" gutterBottom sx={detailStyles.detailLabel}>
      {label}
    </Typography>
    {isEditing ? (
      <TextField
        fullWidth
        value={value}
        onChange={onChange}
        size="small"
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 4 : 1}
      />
    ) : (
      <Typography sx={detailStyles.detailValue}>{value}</Typography>
    )}
  </Box>
);
