import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Paper, Typography, Divider, TextField, Button , FormControl,InputLabel,MenuItem,Select, FormHelperText} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'redux/store';
import * as yup from 'yup';
import { updateUserProfile, updateBusinessData } from '@api/handleUserCRUD';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation

export const EditProfileForm = ({isProfile, user, Userbusiness,userData, setIsEditMode, handleEditToggle}) => {
  const userState = useAppSelector((state) => state.loginState.user);
  const navigate = useNavigate();
  const userId = userState[0]?.user.id;
  // Yup schema for validation
  const businessId = userState[0]?.business.id;
 
  const validationSchema = yup.object().shape({
    ...(isProfile ? {
      firstName: yup.string(),
      lastName:yup.string(),
      userEmail: yup.string().email('Invalid email').required('User email is required'),
    } : {
      businessName: yup.string().required('Business name is required'),
      businessPhone: yup.string().required('Business phone is required'),
      businessAddress: yup.string().required('Business address is required'),
      businessABN: yup.string().required('Business ABN is required'),
      paymentCycle: yup.string().required('Payment cycle is required'),
      paymentCycleStartDate: yup.date().required('Payment cycle start date is required'),
      accountantName: yup.string().required('Accountant name is required'),
      accountantEmail: yup.string().email('Invalid email').required('Accountant email is required'),
    })
  });

  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // Set your default values based on `isProfile`
      ...(isProfile ? {
        firstName: userData?.firstName,
        lastName:userData?.lastName,
        userEmail: userData?.email,
      } : {
        businessName: Userbusiness?.name,
        businessPhone: Userbusiness?.phone,
        businessAddress: Userbusiness?.address,
        businessABN: Userbusiness?.abn,
        paymentCycle: Userbusiness?.paymentCycle,
        paymentCycleStartDate: Userbusiness?.paymentCycleStartDate,
        accountantName: Userbusiness?.accountantName,
        accountantEmail: Userbusiness?.accountantEmail,
      })
    }
  });

  const onSubmit =async (data) => {
 if (isProfile) {
      // Assuming 'userName' and 'userEmail' are correct field names
      const updateUserData = {
        id: userId,
        firstName: data.firstName, // Ensure this name matches the form field
        lastName:data.lastName,
        userEmail: data.userEmail, // Ensure this name matches the form field
      };
      await updateUserProfile(updateUserData);
      
      // Add your update logic here, such as calling an API or updating state
      setIsEditMode(false);
    } else {
      const updateBusiness = {
        id: businessId,
        businessName: data.businessName,
        businessPhone: data.businessPhone,
        businessAddress: data.businessAddress,
        businessABN: data.businessABN,
        paymentCycle: data.paymentCycle,
        paymentCycleStartDate: data.paymentCycleStartDate,
        accountantName: data.accountantName,
        accountantEmail: data.accountantEmail,
      };
      await updateBusinessData(updateBusiness);
     
      // Add your update logic here, such as calling an API or updating state
    }
   
   
  };
  
  return (
    <>
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h4">Edit Profile</Typography>
      <Divider style={{ margin: '10px 0' }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Controller components for each field */}
        {/* Role */}
        {isProfile ? (<>
          <Typography variant="h6">Role:</Typography>
          <Typography paragraph>{user[0]?.role}</Typography> 
          <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="First Name" error={!!errors.firstName} helperText={errors.firstName ? errors.firstName.message : ''} fullWidth margin="normal" />
          )}
        />
         <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Last Name" error={!!errors.lastName} helperText={errors.lastName ? errors.lastName.message : ''} fullWidth margin="normal" />
          )}
        />
        {/* User Email */}
        <Controller
          name="userEmail"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="User Email" error={!!errors.userEmail} helperText={errors.userEmail ? errors.userEmail.message : ''} fullWidth margin="normal" />
          )}
        />
        <Button  onClick={()=>navigate("/reset-password")}>
         Reset Password
        </Button>
       </>):(
          <>
            {/* Business Name */}
        <Controller
          name="businessName"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Business Name" error={!!errors.businessName} helperText={errors.businessName ? errors.businessName.message : ''} fullWidth margin="normal" />
          )}
        />
        {/* Business Phone */}
        <Controller
          name="businessPhone"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Business Phone" error={!!errors.businessPhone} helperText={errors.businessPhone ? errors.businessPhone.message : ''} fullWidth margin="normal" />
          )}
        />
        {/* Business Address */}
        <Controller
          name="businessAddress"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Business Address" error={!!errors.businessAddress} helperText={errors.businessAddress ? errors.businessAddress.message : ''} fullWidth margin="normal" />
          )}
        />
        {/* Business ABN */}
        <Controller
          name="businessABN"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Business ABN" error={!!errors.businessABN} helperText={errors.businessABN ? errors.businessABN.message : ''} fullWidth margin="normal" />
          )}
        />
        {/* Payment Cycle */}
        <FormControl fullWidth margin="normal" error={!!errors.paymentCycle}>
      <InputLabel id="payment-cycle-label">Payment Cycle</InputLabel>
      <Controller
        name="paymentCycle"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            labelId="payment-cycle-label"
            label="Payment Cycle"
          >
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="fortnightly">Fortnightly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        )}
      />
      {errors.paymentCycle && <FormHelperText>{errors.paymentCycle.message}</FormHelperText>}
    </FormControl>
        {/* Payment Cycle Start Date */}
        <Controller
          name="paymentCycleStartDate"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Payment Cycle Start Date" type="date" InputLabelProps={{ shrink: true }} error={!!errors.paymentCycleStartDate} helperText={errors.paymentCycleStartDate ? errors.paymentCycleStartDate.message : ''} fullWidth margin="normal" />
          )}
        />
         <Controller
          name="accountantName"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Accountant Name" error={!!errors.accountantName} helperText={errors.accountantName ? errors.accountantName.message : ''} fullWidth margin="normal" />
          )}
        />
        <Controller
          name="accountantEmail"
          control={control}
          render={({ field }) => (
            <TextField {...field} label="Accountant Email" error={!!errors.accountantEmail} helperText={errors.accountantEmail ? errors.accountantEmail.message : ''} fullWidth margin="normal" />
          )}
        />
        </>
       )}
        <Button type="submit" variant="contained" color="primary">Save Changes</Button>
        <Button variant="outlined" onClick={()=>{handleEditToggle(); reset();}} style={{ marginLeft: '10px' }}>Cancel</Button>
      </form>
    </Paper>
    </>
    
  );
};
