import { useParseSDK } from "hooks/useParseSDK";
import { useAppSelector } from "../redux/store";
import { useInvoicesState } from "@reduxHooks/useInvoicesState";

export const useFetchInvoices = () => {
  const { Parse } = useParseSDK();
  const { invoicesState, setInvoices } = useInvoicesState();

    const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);
  const userData = user.map((user) => user.user);

  const businessId = Userbusiness[0];
  const userId = userData[0];
  
 return {
    fetchInvoices: async () => {
      if (!navigator.onLine) {
      //  alert('You are offline. Fetching employee info from Redux state.');
        // Employee info should already be in the Redux state if fetched online previously
        if (invoicesState.invoices && invoicesState.invoices.length > 0) {
          return;  // No need to update state as it should already be set
        } else {
          setInvoices({invoices:[]}); // Provide fallback if no employee info is in state
          return;
        }
      } else {
      try {
        const data = await Parse.Cloud.run(
          "fetchInvoices",
          {
            businessId: businessId,
          },{
         
            user: userId,
          }
        );
       
        // Assuming the data is an array of Recipe objects
        if (data && Array.isArray(data) && data.length > 0) {
          // Dispatch the action with the correct shape
          setInvoices({ invoices: data });
          
        }
      } catch (error) {
        setInvoices({invoices:[]});
        return;
      }
    }
    },
  };
};
