import React, {useState} from 'react';
import{
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, IconButton, TextField,
  }from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const PackageTable = ({
    handleClickOpen, handleCollectiveDelete, handleSelectAllPackages, handleSelectPackage, 
    isAllSelected, packages, selectedPackages, emptyState
  }) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
    };
  
    const filteredPackages= searchText
    ? packages.filter(pckg => {
        // Convert search text to lower case and split into keywords
        const searchKeywords = searchText.toLowerCase().split(/\s+/);
        // Convert invoice number to lower case for case-insensitive comparison
        const packageNumberLower = pckg.name.toLowerCase();
  
        // Check if any keyword is included in the invoice number
        return searchKeywords.some(keyword => packageNumberLower.includes(keyword));
      })
    : packages;
    const noDataMessage = (entityName) => (
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
        No {entityName} found. Please add some.
      </Typography>
    );
    return (
      <> 
      <TextField
        label="Search by Package Name"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        style={{ marginBottom: '10px' }}
      />
        <Button onClick={() => handleClickOpen("add")}>Add Package</Button>
        <Button color="secondary" onClick={handleCollectiveDelete} disabled={selectedPackages.length === 0}>
          Delete Selected
        </Button>
        {emptyState ?(
              noDataMessage("packages")
            ):(
        <TableContainer component={Paper} sx={{ maxHeight:"60vh" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllSelected(packages)}
                    onChange={() => handleSelectAllPackages(packages)}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Serves</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPackages.map((pkg) => (
                <TableRow key={pkg.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedPackages.includes(pkg.id)}
                      onChange={() => handleSelectPackage(pkg.id)}
                    />
                  </TableCell>
                  <TableCell>{pkg.name}</TableCell>
                  <TableCell>{pkg.amount}</TableCell>
                  <TableCell>${pkg.price}</TableCell>
                  <TableCell>{pkg.serves}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen('edit', pkg)}><EditIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)
      }
      </>
    );
  };
  
  export default PackageTable;