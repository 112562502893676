import { combineReducers } from "@reduxjs/toolkit";
import globalState from "./slices/global.slice";
import billsState from "./slices/bills.slice";
import packagesState from "./slices/packages.slice";
import invoicesState from "./slices/invoices.slice";
import loginState from "./slices/login.slice";
import recipeState from "./slices/recipes.slice";
import salesState from "./slices/sales.slice";
import scheduledRecipesState from "./slices/schedule.slice";
import stockState from "./slices/stock.slice";
import stockData from "./slices/stockData.slice";
import timesheetState from "./slices/timesheet.slice";
import suppliersState from "./slices/supplier.slice";

const rootReducer = combineReducers({
  globalState: globalState,
  billsState: billsState,
  packagesState: packagesState,
  invoicesState: invoicesState,
  loginState: loginState,
  recipeState: recipeState,
  salesState: salesState,
  scheduledRecipesState: scheduledRecipesState,
  stockState:stockState,
  stockData:stockData,
  timesheetState: timesheetState,
  suppliersState:suppliersState,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
