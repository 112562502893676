import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Business = {
  id: string;
  name: string;
  phone: string;
  owner: Owner;
  address: string;
  users: string[];
  abn: string;
  paymentCycle:string;
  paymentCycleStartDate: string;
  accountantName: string;
  accountantEmail:string;
  businessHours:BusinessHours[];
};

type BusinessHours={
  day:string;
  startTime:string;
  endTime:string;
};

type Owner={id:string;
   username:string; 
   email:string;};
// TODO: check late why we need table here (based on API response)

type User = {
  role: string;
  business: Business;
  user: {
    id: string;
    username: string;
    email: string;
    isAuto: boolean;
    firstName:string;
    lastName:string;
  };
};

type UserStateType = {
  user: User[];
};

const initialState: UserStateType = {
  user: [],
};

const userSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    setLoginState: (state, action: PayloadAction<{ user: User[] }>) => {
      state.user = action.payload.user;
    },
  },
});

export const { setLoginState } = userSlice.actions;
export default userSlice.reducer;
