import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Grid, Box } from '@mui/material';
import { useParseSDK } from 'hooks/useParseSDK';
import { useAppSelector } from "redux/store";
import { useFetchUserData } from '@api/user';

const {Parse}=useParseSDK();


const BusinessHoursForm = () => {
  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);

  const businessId = Userbusiness[0];
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const {fetchUserData}= useFetchUserData();


  const handleSubmit = async (e) => {
    e.preventDefault();
    const businessHours = [
        { day: 'monday', startTime, endTime },
        { day: 'tuesday', startTime, endTime },
        { day: 'wednesday', startTime, endTime },
        { day: 'thursday', startTime, endTime },
        { day: 'friday', startTime, endTime },
      ];

    try {
     // Assuming you want to save the entire array as a single object in Back4App
      const BusinessHoursArray = Parse.Object.extend("Business");
      const businessToUpdate= new Parse.Query(BusinessHoursArray);
      const object = await businessToUpdate.get(businessId);
      object.set("businessHours", businessHours);

      await object.save();
      fetchUserData();
      //alert('Business hours saved successfully!');

    } catch (error) {
      console.error('Error saving business hours: ', error);
      alert('Failed to save business hours.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Business Hours Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
  
              <Grid item xs={4} sm={4}>
                <Typography> Weekdays </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
              <TextField
              label="Start Time"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              label="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
              </Grid>
        </Grid>
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            Save Business Hours
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default BusinessHoursForm;
