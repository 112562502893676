// ManagerAppBar.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  TextField,
  Typography,
  Button,
  Box,
  IconButton,
  Select,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { brandColors } from "../../brandColors";
import { useAppSelector } from "../../redux/store";
import { useParseSDK } from "hooks/useParseSDK";
import { Settings } from "@mui/icons-material";
import { useEmployeeInfo } from "@api/employeeInfo";


const ManagerAppBar = ({
  onToggleView,
  isPayrollView,
  filteringCycle,
  setFilteringCycle,
  sortedCycles,
  paymentCycle,
  paymentCycleStartDate,
  setPaymentCycle,
  setPaymentCycleStartDate,
}) => {
  const { Parse } = useParseSDK();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const user = useAppSelector((state) => state.loginState.user);
  const Userbusiness = user?.map((user) => user.business.id);
  const businessId = Userbusiness[0];
  const { fetchEmployeeInfo } = useEmployeeInfo();
  const handleSave = async () => {
    const Business = Parse.Object.extend("Business");
    const query = new Parse.Query(Business);
    try {
      const object = await query.get(businessId);
      object.set("payment_cycle_frequency", paymentCycle);
      object.set("payment_cycle_start", paymentCycleStartDate);

      await object.save();
      toggleSettingsModal();
    } catch (error) {
      alert(error.message);
    }
  };

  const toggleSettingsModal = () => {
    setSettingsModalOpen(!settingsModalOpen);
  };


  const renderSettingsModal = () => (
    <Modal
      open={settingsModalOpen}
      onClose={toggleSettingsModal}
      aria-labelledby="settings-modal-title"
      aria-describedby="settings-modal-description"
    >
      <Box
        className="modalContent"
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 300,
        }}
      >
        <h2 id="settings-modal-title">Settings</h2>
        <div id="settings-modal-description">
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="payment-cycle-label">Payment Cycle</InputLabel>
            <Select
              labelId="payment-cycle-label"
              value={paymentCycle}
              onChange={(e) => setPaymentCycle(e.target.value)}
              label="Payment Cycle"
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="fortnightly">Fortnightly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              id="payment-cycle-start-date"
              label="Payment Cycle Start Date"
              type="date"
              value={paymentCycleStartDate?.toISOString().split("T")[0]}
              onChange={(e) =>
                setPaymentCycleStartDate(new Date(e.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <Button
            onClick={toggleSettingsModal}
            variant="contained"
            color="primary"
            style={{ marginTop: "1em" }}
          >
            Close
          </Button>
          <Button
            onClick={() => handleSave()}
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: brandColors.gray,
          borderRadius: 5,
          borderWidth: 0.1,
        }}
      >
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Manager's Dashboard
          </Typography>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              height: 56,
              backgroundColor: "#f4f4f4",
              borderRadius: 5,
              marginRight: 10,
            }}
          >
            <Button variant="text" color="secondary" onClick={onToggleView}>
              {isPayrollView ? "Timesheet" : "Payroll"}
            </Button>
          </Box>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f4f4f4",
              borderRadius: 5,
              marginRight: 10,
            }}
          >
            <Typography sx={{ color: brandColors.gray }} variant="body1">
              Timesheet Cycle:
            </Typography>
            <Select
              labelId="filter-cycle-label"
              value={filteringCycle}
              onChange={(e) => setFilteringCycle(e.target.value)}
              label="Timesheet Cycle"
              color="primary"
            >
              <MenuItem value="">All Year</MenuItem>
              {sortedCycles?.map((cycle, index) => (
                <MenuItem key={index} value={cycle}>
                  {cycle}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              height: 56,
              backgroundColor: "#f4f4f4",
              borderRadius: 5,
            }}
          >
            <Typography sx={{ color: brandColors.gray }} variant="body1">
              Settings
            </Typography>
            <IconButton
              edge="start"
              aria-label="settings"
              onClick={toggleSettingsModal}
              sx={{
                marginLeft: 5,
              }}
            >
              <Settings size={24} color={brandColors.gray} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderSettingsModal()}
    </>
  );
};

export default ManagerAppBar;
