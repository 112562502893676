import { useParseSDK } from "hooks/useParseSDK";

const {Parse}=useParseSDK();

export async function saveSupplierData(formData) {
    // Destructure the formData to extract the files and other fields
    const { contactNumber, email, files, location, name, salesRep , businessId} = formData;
  
    // Step 1: Upload files
    const fileUploadPromises = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    // Sanitize the filename to allow letters, numbers, periods, underscores, and brackets
    const sanitizedFilename = file.name.replace(/[^a-zA-Z0-9.\[\]{}]/g, '_');
    const parseFile = new Parse.File(sanitizedFilename, file,"document/pdf");
    fileUploadPromises.push(parseFile.save());
  }

  try {
    const uploadedFiles = await Promise.all(fileUploadPromises);

      // Step 2: Create or Update the Supplier Object
      const Supplier = Parse.Object.extend("Suppliers");
      const supplier = new Supplier();
  
      // Set the fields
      supplier.set("contactNumber", contactNumber);
      supplier.set("email", email);
      supplier.set("Certificates", uploadedFiles); // Array of Parse.File
      supplier.set("Location", new Parse.GeoPoint(location.lat, location.lng));
      supplier.set("Name", name);
      supplier.set("salesRep", salesRep);
      supplier.set("Business",{
        __type: "Pointer",
        className: "Business",
        objectId: businessId,
      });
  
      // Step 3: Save the Supplier Object
      await supplier.save();
      console.log("Supplier saved successfully with certificates.");
    } catch (error) {
      console.error("Error saving supplier data:", error);
    }
  }
  
  
  export async function updateSupplier(supplierId, updateFields) {
   // Ensure this is correctly imported or available in scope
   console.log("Data passed:",updateFields);
    const { Certificates, ...otherFields } = updateFields;
    const Suppliers = Parse.Object.extend("Suppliers");
    const query = new Parse.Query(Suppliers);

    try {
        const supplier = await query.get(supplierId);

        // Process files for uploading only if they're not already Parse.File objects
        const fileUploadPromises = (Certificates || []).map(async (file) => {
            if (file instanceof File) { // This checks if it's a raw file needing upload
              const sanitizedFilename = file.name.replace(/[^a-zA-Z0-9.\[\]{}]/g, '_');
                const parseFile = new Parse.File(sanitizedFilename, file,"document/pdf");
                await parseFile.save();
                return parseFile;
            } else if (typeof file === "object" && file.__type === "File" && file._url) {
                // If the file looks like a Parse.File but is not an instance, recreate it
                return Parse.File(file._name, { uri: file._url });
            }
            return file; // Return as is if it's already a Parse.File
        });

        const uploadedFiles = await Promise.all(fileUploadPromises);

        // Update certificates field with uploadedFiles
        if (uploadedFiles.length > 0) {
            supplier.set("Certificates", uploadedFiles);
        }else {
          supplier.set("Certificates",[]);
        }

        // Update other fields
        supplier.set("contactNumber", otherFields.ContactNumber);
        supplier.set("email", otherFields.Email);
        supplier.set("Name", otherFields.Name);
        supplier.set("salesRep", otherFields.SalesRep);

        await supplier.save();
        console.log("Supplier updated successfully.");
    } catch (error) {
        console.error("Error updating supplier:", error);
    }
}

export const handleDeleteSupplier = async (supplierId) => {
  const { Parse } = useParseSDK();
  const Suppliers = Parse.Object.extend("Suppliers");
  try {
    const query = new Parse.Query(Suppliers);

    // Retrieve the stock object
    const supplier = await query.get(supplierId);

    // Delete the stock object from the backend
    await supplier.destroy();

  } catch (error) {
    console.error("Error while deleting supplier: ", error);
  }
};