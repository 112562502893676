// stockDataTransformation.js
import { convertUnits } from "./convertUnits";

export const updateStockData = (stockData) => {
  const updatedStockData = {};
  Object.keys(stockData).forEach((key) => {
    const item = stockData[key];
    const updatedItem = { ...item };
    if (item.unit !== "pcs") {
      const convertedQuantity = convertUnits(
        item.calculatedQuantity,
        item.unit
      );
      updatedItem.calculatedQuantity = convertedQuantity.value;
      updatedItem.unit = convertedQuantity.unit;

      updatedItem.calculatedPrice =
        item.calculatedPrice / updatedItem.calculatedQuantity;
    } else {
      updatedItem.calculatedPrice =
        item.calculatedPrice / item.calculatedQuantity;
    }
    updatedStockData[key] = updatedItem;
  });
  return updatedStockData;
};
