import React from 'react';
import { Paper, Typography, Divider, List, ListItem, ListItemText, Button } from '@mui/material';
import { useParseSDK} from 'hooks/useParseSDK';

export const UserProfile =({userData, user})=>{
const {Parse}=useParseSDK();
const currentUser = Parse.User.current();
 const customerId = currentUser.get("customerId");

    return(
<Paper elevation={3} style={{ padding: '10px', }}>
          <Typography variant="h4">User Profile</Typography>
          <Divider style={{ margin: '10px 0' }} />

          <Typography variant="h6">Role:{user[0]?.role}</Typography>
          <Button variant="outlined" onClick={async() => {
              
    // This will open the Stripe billing page in a new tab
    await Parse.Cloud.run(
      "createStripePortalSession",
      { customerId: customerId },
    ).then((portalUrl) => {
    // Redirect the customer to the portal URL or send the URL to the front end
    window.open(portalUrl);
    //console.log(portalUrl);
  })
  .catch((error) => {
    // Handle error
    console.error(error);
  });
  }}>Manage My Subscription</Button> 
 
  {userData && (
    <div>
      <Typography variant="h6">User Details:</Typography>
      <List>
        <ListItem>
          <ListItemText primary="First Name" secondary={userData.firstName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Last Name" secondary={userData.lastName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" secondary={userData.email} />
        </ListItem>
      </List>
    </div>
  )}
  </Paper>
    );
  };