import React from 'react';
import { List, ListItem, ListItemText, TextField, Typography, IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";

const EditableStockList = ({ currentInvoiceStocks, handleStockChange, handleDeleteStock }) => {
  
  return (
    <List dense>
      {currentInvoiceStocks?.map((stockItem, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={`Stock Name: ${stockItem.name}`}
            secondary={`Category: ${stockItem.category}`}
          />
          <TextField
            label="Amount"
            type="number"
            value={stockItem.qty}
            onChange={(e) => handleStockChange(index, 'qty', e.target.value)}
            margin="normal"
            style={{ width: '80px' }}
          />
          <Typography variant="body"> X </Typography>
          <TextField
            label="Quantity"
            type="number"
            value={stockItem.quantity}
            onChange={(e) => handleStockChange(index, 'quantity', e.target.value)}
            margin="normal"
            style={{ width: '80px' }}
          />
         <TextField
          label="Unit"
          value={String(stockItem.unit)} // Convert to string
          onChange={(e) => handleStockChange(index, 'unit', e.target.value)}
          margin="normal"
          style={{ width: '80px' }}
        />

          <TextField
            label="Price"
            type="number"
            value={stockItem.price}
            onChange={(e) => handleStockChange(index, 'price', e.target.value)}
            margin="normal"
            style={{ width: '80px' }}
          />
          <IconButton onClick={() => handleDeleteStock(index)} color="secondary">
            <Delete />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};

export default EditableStockList;
