import { useParseSDK } from "hooks/useParseSDK";


const {Parse}=useParseSDK();

///////////////////////////////////////////////////////////////Bills///////////////////////////////////////////

export const handleAddExpense = async (bills, businessId) => {
  const Expense = Parse.Object.extend("Expenses");
  const expenseObjects = [];
  
  // Iterate over each bill and create an Expense Parse object
  for (const { name, amount, recurrence } of bills) {
    const expense = new Expense();
    expense.set("name", name);
    expense.set("amount", amount);
    expense.set("recurrence", recurrence);
    expense.set("Business", {
      __type: "Pointer",
      className: "Business",
      objectId: businessId,
    });
    expenseObjects.push(expense);
  }

  try {
    // Use Parse.Object.saveAll() to save multiple objects at once
    await Parse.Object.saveAll(expenseObjects);
    
  } catch (error) {
    console.error(`Failed to save expenses: ${error.message}`);
  }
};

export const handleUpdateExpense = async (newExpenseData) => {
  
  try {
    // Create the Parse Object Instance
    const Expenses = Parse.Object.extend("Expenses");
    const expenseQuery = new Parse.Query(Expenses);

    // Use the 'id' from newExpenseData to find the Parse Object
    const expenseToUpdate = await expenseQuery.get(newExpenseData.id);

    // Update the fields on the Parse Object
    expenseToUpdate.set("name", newExpenseData.name);
    expenseToUpdate.set("amount", newExpenseData.amount.toString());
    expenseToUpdate.set("recurrence", newExpenseData.recurrence);

    // Save the updates back to Back4App
    await expenseToUpdate.save();
   
  } catch (error) {
    console.error("Error while updating Expense:", error);
  }
};

export const handleDeleteExpense = async (expenseId) => {
  
  try {
    // Create the Parse Object Instance
    const Expenses = Parse.Object.extend("Expenses");
    const query = new Parse.Query(Expenses);

    // Use the 'expenseId' to find the Parse Object
    const expenseToDelete = await query.get(expenseId);
  

    if (!expenseToDelete) {
      console.error(`Expense not found: ${expenseId}`);
      return;
    }

    // Delete the object from Back4App
    await expenseToDelete.destroy();

  } catch (error) {
    console.error(`Error while deleting expense: ${expenseId}`, error);
    alert("Expense delete failed.");
  }
};

export const deleteAllSelectedExpenses = async (expenseIds) => {
  
  const Expenses = Parse.Object.extend("Expenses");
  const query = new Parse.Query(Expenses);
  query.containedIn("objectId", expenseIds);

  try {
    const results = await query.find();
    await Parse.Object.destroyAll(results);
  } catch (error) {
    console.error("Error while deleting selected expenses", error);
    alert("Failed to delete all selected expenses.");
  }
};
