import React, { useState, useEffect } from "react";
import {
  IoAdd as IoIosAdd,
  IoSyncOutline,
  IoTrashBinSharp,
} from "react-icons/io5";
import { useFetchStock } from "@api/stock";
import AddStockNewCategory from "@components/Inventory/HOC/AddStockNewCategory";
import AddStockExistingCategory from "@components/Inventory/HOC/AddStockExistingCategory";
import {
  Button,
  Typography,
  TextField,
  Modal,
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { useStockState } from "@reduxHooks/useStocksState";
import {
  handleAddStocks,
  handleDeleteStocks,
  handleUpdateStocks,
} from "@api/CRUDOpStocks";
import { useAppSelector } from "../../redux/store";
import ResponseModal from "@components/success/errorModal";


const centerModalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const brandColors = {
  primary: "#7045af",
  secondary: "#8b65c2",
  tertiary: "#59378a",
  black: "#111111",
  white: "#ffffff",
  gray: "#2a2a2a",
};

const StockScreen = () => {
  const { stockState } = useStockState();

  const [selectedStock, setSelectedStock] = useState({});
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState(Number(0));
  const [price, setPrice] = useState(Number(0));
  const [unit, setUnit] = useState("");
  const [activeSection, setActiveSection] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false); // State for showing success animation
  const [showError, setShowError] = useState(false); // State for showing error animation
  const [showAnim,setSHowAnim] =useState(false);
  const [tabValue, setTabValue] = useState(0);
  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);

  const businessId = Userbusiness[0];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEditItem = (item) => {
    setSelectedStock(item); // Assuming setSelectedStock is your state setter for the selected item
    setName(item.Name);
    setQuantity(item.Quantity);
    setPrice(item.Price);
    setUnit(item.Unit);
    showEditModal();
  };

  const stockCategories =
  Object.keys(stockState.stock).length !== 0
    ? Object.entries(stockState.stock)
        ?.map(([category, items]) => ({
          category,
          items,
        }))
        .sort((a, b) => a.category.localeCompare(b.category)) // This line will sort the categories alphabetically
    : [];

  const handleCategoryPress = (category) => {
    setActiveSection(category);
  };

  const [addPressed, setAddPressed] = useState(false);

  const handleAddButtonPress = () => {
    setAddPressed(true);
  };

  const handleSaveButtonPress = async (
    stockId,
    newName,
    newQuantity,
    newUnit,
    newPrice,
    businessId
  ) => {
   try {await handleUpdateStocks(
      stockId,
      newName || selectedStock.Name,
      Number(newPrice) || selectedStock.Price,
      Number(newQuantity) || selectedStock.Quantity,
      newUnit || selectedStock.Unit,
      businessId
    );
    setSHowAnim(true);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setSHowAnim(false);
    }, 2000);
    await fetchStock();
    closeEditModal();
  } catch (error) {
    setSHowAnim(true);
    setShowError(true);
    setTimeout(() =>{ setShowError(false);  setSHowAnim(false);}, 2000);
  }
  };

  const handleSyncData = async () => {
    await fetchStock();
  };

  const [editModal, setEditModal] = useState(false);
  const showEditModal = () => {
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const closeAddModal = () => {
    setAddPressed(false);
  };

  const handleAddStock = async (
    addName,
    addQuantity,
    addPrice,
    addUnit,
    category
  ) => {
    await handleAddStocks(
      category,
      addName,
      addPrice,
      addQuantity,
      addUnit,
      businessId
    );
    await fetchStock();
  };

  const handleAdd = (data) => {
    handleAddStock(
      data.addName,
      data.addQuantity,
      data.addPrice,
      data.addUnit,
      data.addExistingCategory
    );
    setTimeout(() => {
      closeAddModal();
    }, 3000);
  };

  const handleAddNew = (data) => {
    handleAddStock(
      data.addName,
      data.addQuantity,
      data.addPrice,
      data.addUnit,
      data.addCategory
    );
    setTimeout(() => {
      closeAddModal();
    }, 3000);
  };

  const handleDelete = async (stock) => {
  try{  const stockId = stock.id;
    //TO-DO: Set a loading state, and also a success/ error lottie animation
   await handleDeleteStocks(stockId);
   setSHowAnim(true);
   setShowSuccess(true);
   setTimeout(() => {
     setShowSuccess(false);
     setSHowAnim(false);
   }, 2000);
   await fetchStock();
  } catch (error) {
    setSHowAnim(true);
    setShowError(true);
    setTimeout(() => {setShowError(false);setSHowAnim(false);}, 3000);
  }
  };

  // Loading data on component mount + loading state
  const { fetchStock } = useFetchStock();
  useEffect(() => {
    fetchStock();
  }, []);

 
  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: brandColors.white }}>
        <Toolbar sx={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            onClick={handleAddButtonPress}
            startIcon={<IoIosAdd size={20} />}
            sx={{
              marginRight: 5,
              backgroundColor: "#E1BEE7", // Light purple color
              "&:hover": {
                backgroundColor: "#CE93D8", // Slightly darker purple for the hover state
              },
              color: "white", // Adjust text color if necessary
            }}
          >
            Add Stock
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSyncData}
            startIcon={<IoSyncOutline size={20} />}
          >
            Sync
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          style={{ maxHeight: "80vh", overflowY: "auto" }}
        >
          <List>
            {stockCategories?.map(({ category }) => (
              <ListItem
                key={category}
                onClick={() => handleCategoryPress(category)}
                style={{
                  marginBottom: "8px",
                  cursor: "pointer",
                  backgroundColor:
                    activeSection === category
                      ? "lightgray"
                      : "transparent",
                }}
              >
                <ListItemText primary={category} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={addPressed ? 6 : 9}
          lg={addPressed ? 6 : 9}
          style={{
            maxHeight: "80vh",
           // overflow: "hidden",
            position: "relative", // Add this to create a stacking context
          }}
        >
        <Container
          sx={{
            maxHeight: "100%",
           // overflowY: "scroll",
            paddingRight: "17px", // Add some padding to prevent content from shifting on scroll
            overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },
          }}
        >
          {activeSection ? (
            stockState.stock[activeSection]?.map((item) => (
              <Paper
                key={item.id}
                sx={{
                  marginBottom: "16px",
                  padding: "24px",
                  boxShadow: 1,
                  borderRadius: 2,
                  p: 2,
                  backgroundColor:
                    selectedStock === item ? brandColors.secondary : "white",
                 
                  cursor: "pointer",
                  transition: "all 0.3s ease-in-out",
                  position: "relative", // Needed for absolute positioning of the delete button
                  ":hover": {
                   // boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)", // More pronounced shadow on hover
                    transform: "scale(1.01)", // Slightly scale up the paper
                    backgroundColor: "lightgray",
                   
                  },
                }}
                onClick={() => handleEditItem(item)}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="h6"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                      noWrap
                    >
                      {item.Name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                    >
                      {item.Quantity} {item.Unit}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="body1"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                    >
                      Available Quantity:
                    </Typography>
                    <Typography
                      variant="body1"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                    >
                      {item.Quantity} {item.Unit}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="body1"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                    >
                      Price per Unit:
                    </Typography>
                    <Typography
                      variant="body1"
                      color={
                        selectedStock === item
                          ? brandColors.white
                          : brandColors.black
                      }
                    >
                      {item.Price} AUD
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    minWidth: "40px",
                    height: "40px",
                    backgroundColor: "#E1BEE7",
                    ":hover": {
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)", // More pronounced shadow on hover
                      transform: "scale(1.01)", // Slightly scale up the paper
                      backgroundColor: "#d32f2f", // Ensure this doesn't conflict with Paper's hover
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Stop click from reaching the Paper component
                    handleDelete(item);
                  }}
                >
                  <IoTrashBinSharp size={20} />
                </Button>
              </Paper>
            ))
          ) : (
            <Paper
              sx={{
                padding: "16px",
                backgroundColor: "transparent",
                border: "2px dashed #ccc",
                borderRadius: "4px",
              }}
            >
              <Typography variant="h6" style={{ textAlign: "center" }}>
                No items available in this category.
              </Typography>
            </Paper>
          )}
          </Container>
        </Grid>
        {addPressed && (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <Typography variant="h5" align="center" gutterBottom>
                Add Stock
              </Typography>

              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label="New Category" />
                <Tab label="Existing Category" />
              </Tabs>

              {tabValue === 0 && (
                <AddStockNewCategory
                  handleAddNew={handleAddNew}
                  closeAddModal={closeAddModal}
                />
              )}
              {tabValue === 1 && (
                <AddStockExistingCategory
                  handleAdd={handleAdd}
                  closeAddModal={closeAddModal}
                />
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      <ResponseModal show={showAnim} setShow={setSHowAnim} success={showSuccess} errorResponse={showError}/>
     
      <Modal open={editModal} onClose={closeEditModal} style={centerModalStyle}>
        <Paper
          sx={{
            padding: "24px",
            width: "60%",
            backgroundColor: "#fff",
            position: "relative",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={closeEditModal}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          >
            ×
          </Button>
          <Typography variant="h4" mb={2}>
            Edit Stock
          </Typography>
          <Box mb={2}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quantity"
              variant="outlined"
              fullWidth
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Price"
              variant="outlined"
              fullWidth
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Box>
          <Box mb={2}>
          <FormControl fullWidth margin="normal">
      <InputLabel id="unit-select-label">Unit</InputLabel>
      <Select
        labelId="unit-select-label"
        value={unit}
        label="Unit"
        onChange={(e) => setUnit(e.target.value)}
      >
        <MenuItem value="g">g</MenuItem>
        <MenuItem value="kg">kg</MenuItem>
        <MenuItem value="pcs">pcs</MenuItem>
        <MenuItem value="ml">ml</MenuItem>
        <MenuItem value="l">l</MenuItem>
      </Select>
    </FormControl>
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleSaveButtonPress(
                    selectedStock.id,
                    name,
                    quantity,
                    unit,
                    price,
                    businessId
                  )
                }
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={closeEditModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
};

export default StockScreen;
