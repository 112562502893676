import axios from "axios";

export const validateABN = (abn: string): boolean => {
  if (!abn || abn.length !== 11) return false;

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  let sum = 0;

  for (let i = 0; i < abn.length; i++) {
    let digit = parseInt(abn[i], 10) - (i === 0 ? 1 : 0);
    sum += weights[i] * digit;
  }

  return sum % 89 === 0;
};

export const validateABNAndBusinessName = async (abn: string, authenticationGuid: string, expectedBusinessName: string): Promise<boolean> => {
  try {
    const url = `https://abr.business.gov.au/abrxmlsearch/AbrXmlSearch.asmx/SearchByABNv202001?searchString=${abn}&includeHistoricalDetails=N&authenticationGuid=${authenticationGuid}`;
    const response = await axios.get(url, { responseType: 'text' });
    const xmlDoc = new DOMParser().parseFromString(response.data, "text/xml");
    // Ensure namespaceURI is always a string by providing a default value if it's null
    const namespaceURI = xmlDoc.documentElement.namespaceURI || '';

    const businessNames = xmlDoc.getElementsByTagNameNS(namespaceURI, "businessName");

    if (businessNames.length > 0) {
      const latestBusinessName = getMostRecentBusinessName(businessNames, namespaceURI);
      // Ensure latestBusinessName is treated as a string. Add a check for null or undefined to handle cases where latestBusinessName might not have been assigned.
      if (latestBusinessName && latestBusinessName.toLowerCase() === expectedBusinessName.toLowerCase()) {
        return true;
      }
    } else {
      const businessNameNode = xmlDoc.getElementsByTagNameNS(namespaceURI, "organisationName")[0];
      const businessName = businessNameNode ? businessNameNode.textContent : undefined;
      // Perform a similar check for businessName as above
      if (businessName && businessName.toLowerCase() === expectedBusinessName.toLowerCase()) {
        return true;
      }
    }
  } catch (error) {
    console.error("Error fetching or validating business name:", error);
    return false;
  }

  return false;
};

function getMostRecentBusinessName(businessNames: NodeListOf<Element>, namespaceURI: string): string {
  let latestDate = new Date(0);
  let latestBusinessName = "";

  Array.from(businessNames).forEach(element => {
    const organisationNameNode = element.getElementsByTagNameNS(namespaceURI, "organisationName")[0];
    const effectiveFromDateNode = element.getElementsByTagNameNS(namespaceURI, "effectiveFrom")[0];
    const effectiveFromDateText = effectiveFromDateNode ? effectiveFromDateNode.textContent : null;
    const effectiveFromDate = effectiveFromDateText ? new Date(effectiveFromDateText) : new Date(0);

    if (effectiveFromDate > latestDate) {
      latestDate = effectiveFromDate;
      latestBusinessName = organisationNameNode ? organisationNameNode.textContent || "" : "";
    }
  });

  return latestBusinessName;
}



export const validateABNAndStatus = async (
  abn: string,
  authenticationGuid: string
): Promise<boolean> => {
  try {
    const url = `https://abr.business.gov.au/abrxmlsearch/AbrXmlSearch.asmx/SearchByABNv202001?searchString=${abn}&includeHistoricalDetails=N&authenticationGuid=${authenticationGuid}`;
    const response = await axios.get(url);

    // You'll need to parse the XML response to extract the relevant information
    // Depending on the actual structure of the response, you may need to adjust this code
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response.data, "text/xml");
    const status =
      xmlDoc.getElementsByTagName("entityStatusCode")[0]?.textContent;
    
     
    if (status === "Active") {
      console.log("true");
      return true;
    }
  } catch (error) {
    console.error("Error validating ABN and Status:", error);
  }

  return false;
};
