import React from 'react';
import { Grid, IconButton } from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const PricingPage = () => {
  const navigate = useNavigate();
  
  // Function to handle back navigation
  const handleBack = () => {
    navigate(-1); // This navigates to the previous page in the history stack
  };

  // Constants for Stripe Pricing Table and Publishable Key
  const PRICING_TABLE_ID = process.env.REACT_APP_PRICING_TABLE_ID;
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

  return (
    <div style={{ backgroundColor: '#fffcf8' }}>
      <IconButton onClick={handleBack} sx={{ margin: 1 }}>
        <ArrowBack />
      </IconButton>
      <Grid
        container
        spacing={2}
        sx={{
          bgcolor: "#fffcf8",
          maxWidth: "80vw",
          margin: "auto",
          marginTop: 10,
          padding: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            bgcolor: "#fffcf8",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../../assets/images/logo-icon.png")}
            width="200px"
            height="200px"
            alt="logo"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{bgcolor: "#fffcf8"}}
        >
          <stripe-pricing-table
            pricing-table-id={PRICING_TABLE_ID}
            publishable-key={publishableKey}
          >
          </stripe-pricing-table>
        </Grid>
      </Grid>
    </div>
  );
}

export default PricingPage;
