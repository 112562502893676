import React, { useState, useRef } from "react";
// components/LoginForm.js
import { useNavigate, useLocation } from "react-router-dom";
import { SButton } from "../../../styles";
// import {
//   validateABN,
//   validateABNAndBusinessName,
//   validateABNAndStatus,
// } from "utils/validateABN";
import { Box, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParseSDK } from "hooks/useParseSDK";
import Lottie from "lottie-react";
import SuccessLottie from "../.././../assets/lotties/success_lottie.json";
import ErrorLottie from "../.././../assets/lotties/error_lottie.json";
import { Loader } from "../components/Loder";
import { useUserState } from "@reduxHooks/useLoginState";
import {
  validateABN,
  validateABNAndStatus,
  validateABNAndBusinessName,
} from "utils/validateABN";

async function fetchCheckoutSession(sessionId: string) {
  const secretKey = process.env.REACT_APP_STRIPE_SK; // Ideally, this should be server-side
  const response = await fetch(
    `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
    {
      headers: {
        Authorization: `Bearer ${secretKey}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch checkout session");
  }

  const sessionData = await response.json();
  return sessionData;
}

// Usage example (make sure to replace 'session_id_here' with your actual session ID)

export const SignUpMainWrapper = () => {
  const navigate = useNavigate();
  const { Parse } = useParseSDK();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const nextHandler = useRef<any>(undefined);
  const userEmail = useRef<string>("");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const customerSessionId = queryParams.get("customer_id");
  const [customerEmail, setCustomerEmail] = useState();
  const [customerId, setCustomerId] = useState();
  const { setLoginState } = useUserState();

  // /////////////////
  const GUID = process.env.REACT_APP_GUID_ID;

  const validationSchema = yup
    .object({
      username: yup.string(),
      firstName: yup.string().required("We would love to know your name"),
      lastName: yup.string(),
      email: yup.string().email("Invalid Email").required("Email is Required"),
      password: yup
        .string()
        .required("Password Required")
        .min(8, "Minimum Password length is 8"),
      businessName: yup.string().required("Required Field"),
      businessAddress: yup.string().required("Required Field"),
      businessNumber: yup.string(),
      // TODO: handle ABN verification later for now it is always true
      businessABN: yup
        .string()
        .required("ABN is required")
        .test("ABN-validation", "Invalid ABN", async function (value) {
          const { businessName } = this.parent;
          // Remove spaces from ABN
          const abn = value?.replace(/\s+/g, "").trim();

          // First, check if ABN format is valid using validateABN
          if (!validateABN(abn)) {
            return this.createError({ message: `ABN format is incorrect.` });
          }

          // Use validateABNAndBusinessName to ensure the ABN matches the provided business name
          const isValidName = await validateABNAndBusinessName(
            abn,
            GUID as string,
            businessName
          );
          if (!isValidName) {
            return this.createError({
              message: `ABN does not match the business name.`,
            });
          }

          // Use validateABNAndStatus to ensure the ABN status is active
          const isActive = await validateABNAndStatus(abn, GUID as string);
          if (!isActive) {
            return this.createError({ message: `ABN is not active.` });
          }
          return true; // ABN is valid, matches the business name, and is active
        }),
    })
    .required();

  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  // ////////////////
  if (customerSessionId) {
    fetchCheckoutSession(customerSessionId)
      .then((sessionData) => {
        // Assuming sessionData.customer_details.email contains the email
        const fetchedEmail = sessionData.customer_details.email;
        if (fetchedEmail) {
          setCustomerEmail(fetchedEmail); // Update state if needed for other uses
          setValue("email", fetchedEmail); // Programmatically set the form value
          setCustomerId(sessionData.customer);
        }
      })
      .catch((error) => {
        console.error("Error fetching checkout session:", error);
      });
  } else {
    console.log("Customer ID not found in URL");
  }

  const signUpUserAndCreateBusiness = async (
    userData: any,
    businessData: any
  ) => {
    setLoading(true);
    const user = new Parse.User();
    user.set("username", userData.email);
    user.set("email", userData.email);
    user.set("password", userData.password);
    user.set("First_Name", userData.firstName);
    user.set("Last_Name", userData.lastName);
    user.set("isAuto", false);
    user.set("customerId", customerId);
    // TODO: change the name or handle that using differen indicator
    // user.set("emailVerified", false);
    // TODO: uncomment after finish testing

    try {
      const userResult = await user.signUp();
      // Check if "Manager" role exists, if not create it
      const roleQuery = new Parse.Query(Parse.Role);
      roleQuery.equalTo("name", "Manager");
      let managerRole = await roleQuery.first();

      if (!managerRole) {
        const roleACL = new Parse.ACL();
        roleACL.setPublicReadAccess(true);
        roleACL.setPublicWriteAccess(true);

        managerRole = new Parse.Role("Manager", roleACL);
        await managerRole.save();
      }

      // Add user to "Manager" role
      managerRole.getUsers().add(userResult);
      await managerRole.save();

      // Update businessData.Business_Users with the id of the new user
      businessData.Business_Users = [userResult.id];

      const myNewObject = new Parse.Object("Business");
      myNewObject.set("Business_Name", businessData.Business_Name);
      myNewObject.set("Business_Address", businessData.Business_Address);
      myNewObject.set("Business_Number", businessData.Business_Number);
      myNewObject.set("Business_Owner", userResult);
      myNewObject.set("Business_ABN", businessData.Business_ABN);
      myNewObject.set("Business_Users", [userResult.id]);

      await myNewObject.save();

      const userData = []; // Array to store user data

      // Retrieve the user's role
      const userRoleQuery = new Parse.Query(Parse.Role);
      userRoleQuery.equalTo("users", userResult);
      const userRole = await userRoleQuery.first();
      const roleName = userRole ? userRole.get("name") : "No Role Assigned";

      const userId = userResult.id;
      // Retrieve the user's role in the Business object
      const businessQuery = new Parse.Query("Business");
      businessQuery.equalTo("Business_Users", userId);
      const business = await businessQuery.first();

      const businessObjectId = business ? business.id : "No Business Found";
      const businessName = business
        ? business.get("Business_Name")
        : "Unknown Business";
      const businessNumber = business
        ? business.get("Business_Number")
        : "Unknown Business";
      const businessOwner = business
        ? business.get("Business_Owner")
        : "Unknown Business";
      const User = new Parse.User();

      const ownerUserQuery = new Parse.Query(User);
      // ownerUserQuery.equalTo("objectId", businessOwner.id);

      const ownerUser = await ownerUserQuery.get(businessOwner.id);

      const ownerEmail = ownerUser.get("email");
      const ownerUsername = ownerUser.get("username");

      const businessAddress = business
        ? business.get("Business_Address")
        : "Unknown Business";
      const businessUsers = business
        ? business.get("Business_Users")
        : "Unknown Business";
      const businessABN = business
        ? business.get("Business_ABN")
        : "Unknown Business";

      const username = userResult.get("username");
      const useremail = userResult.get("email");
      const isAuto = userResult.get("isAuto");
      userData.push({
        role: roleName,
        business: {
          id: businessObjectId,
          name: businessName,
          phone: businessNumber,
          owner: {
            id: businessOwner.id,
            username: ownerUsername,
            email: ownerEmail,
          },
          address: businessAddress,
          users: businessUsers,
          abn: businessABN,
          paymentCycle: business.get("payment_cycle_frequency")
            ? business.get("payment_cycle_frequency")
            : "weekly",
          paymentCycleStartDate: business.get("payment_cycle_start")
            ? business.get("payment_cycle_start").toISOString()
            : new Date().toISOString(),
          accountantName: "",
          accountantEmail: "",
          businessHours: [],
        },
        user: {
          id: userId,
          username: username,
          firstName: userResult.get("First_Name"),
          lastName: userResult.get("Last_Name"),
          email: useremail,
          isAuto: isAuto,
        },
      });

      const email = useremail;

      await Parse.Cloud.run("sendOtpEmail", { email })
        .then((message: string) => {
          console.log(message); // Should print "OTP sent successfully" or "User not found"
        })
        .catch((error: Error) => {
          console.error(error);
        });
      setIsSuccess(true);
      setLoginState({ user: userData });
      // navigate("/home");
    } catch (error) {
      console.error("Error while signing up user and creating Business", error);
      setIsError("Error while signing up user and creating Business");
    } finally {
      setLoading(false);
    }
  };

  const onSignupSubmit = async (NextHandler: any) => {
    const data = getValues();
    nextHandler.current = NextHandler;
    userEmail.current = data.email;
    console.log("THIS IS FORM DATA ", data);
    return await signUpUserAndCreateBusiness(
      {
        username: data.username,
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
      },
      {
        Business_Name: data.businessName,
        Business_Address: data.businessAddress,
        Business_Number: data.businessNumber,
        Business_ABN: data.businessABN,
      }
    );
  };

  const handleLoginPage = () => {
    // Implement reset password logic here
    navigate("/");
  };

  // TODO : check ABN function with jalal
  // TODO: handle Errors and helper text
  // TODO: handle styles

  return {
    SignupMain: () => {
      if (!loading && !isError && !isSuccess) {
        return (
          <Box component="form" display="flex" minHeight="600px">
            <Box display="flex" flexDirection="column" width="100%" gap={3}>
              <TextField
                label="Your Good Name"
                variant="outlined"
                {...register("firstName")}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
              />
              <TextField
                label="Your Last Name"
                variant="outlined"
                {...register("lastName")}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
              />
              <Controller
                name="email"
                control={control} // Make sure to destructure `control` from `useForm()`
                defaultValue={customerEmail} // Ensure `customerEmail` is defined and updated appropriately
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <TextField
                type="password"
                label={"Password"}
                variant="outlined"
                {...register("password")}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
              <TextField
                label={"Business Name"}
                variant="outlined"
                {...register("businessName")}
                error={Boolean(errors.businessName)}
                helperText={errors.businessName?.message}
              />
              <TextField
                label={"Business Address"}
                variant="outlined"
                {...register("businessAddress")}
                error={Boolean(errors.businessAddress)}
                helperText={errors.businessAddress?.message}
              />
              <TextField
                label={"Business Number (Optional)"}
                variant="outlined"
                {...register("businessNumber")}
                error={Boolean(errors.businessNumber)}
                helperText={errors.businessNumber?.message}
              />
              <TextField
                label={"Business ABN"}
                variant="outlined"
                {...register("businessABN")}
                // TODO: check this function and add it to validation in the YUP schema instead of the current one
                // after consulting with JALAL
                // onChange={handleABNChangeAndValidation}
                error={Boolean(errors.businessABN)}
                helperText={errors.businessABN?.message}
              />
              <SButton onClick={handleLoginPage}>
                Already have an account?
              </SButton>
            </Box>
          </Box>
        );
      }

      if (loading) return <Loader />;

      if (Boolean(isError) || isSuccess) {
        return (
          <Box
            sx={{
              minHeight: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Lottie
              animationData={isSuccess ? SuccessLottie : ErrorLottie}
              loop={false}
              autoplay
              style={{
                width: "120px",
                height: "120px",
              }}
              onComplete={() => {
                if (isSuccess) {
                  setIsSuccess(false);
                  nextHandler.current && nextHandler.current();
                  return;
                }
                if (isError) {
                  setTimeout(() => {
                    setIsError("");
                    console.log(
                      "ERROR HAPPENED , reset states  and show error message"
                    );
                  }, 1000);
                }
              }}
            />
            {Boolean(isError) && (
              // TODO: replace hex color with one from theme later
              <Typography
                color="#ff3333"
                fontSize="16px"
                marginTop="18px"
                fontWeight={900}
                maxWidth="300px"
                textAlign="center">
                {isError}
              </Typography>
            )}
          </Box>
        );
      }
    },
    triggerSignup: trigger,
    isSignupValid: isValid,
    submitSignUp: onSignupSubmit,
    isLoading: loading || Boolean(isError) || isSuccess,
    email: userEmail.current,
  };
};
