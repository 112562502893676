import React, {useState, useEffect} from 'react';

import {
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl,
    InputLabel, Select, MenuItem, Typography, Box
  } from "@mui/material";
  import EditableStockList from './EditableStockList'; // Ensure this path is correct
import ReadOnlyStockList from './ReadStockList';
  
const DialogComponent = ({
  openDialog, handleClose, formType, selectedTab, sampleSuppliers, supplier, setSupplier,
  handleOpenStockPicker, currentInvoiceStocks, handleStockChange,  onSubmit, register,handleSubmit,errors, recurrenceValue
}) => {
  const [copiedInvoiceStocks, setCopiedInvoiceStocks] = useState([]);

  // Initialize the copied array when the dialog opens
  useEffect(() => {
    setCopiedInvoiceStocks([...currentInvoiceStocks]);
  }, [openDialog, currentInvoiceStocks]);

   // Function to delete an item from the copied array
   const handleDeleteStock = (index) => {
    // Create a new array without the item to be deleted
    const updatedCopiedInvoiceStocks = [...copiedInvoiceStocks];
    updatedCopiedInvoiceStocks.splice(index, 1);
    setCopiedInvoiceStocks(updatedCopiedInvoiceStocks);
  };
 
    return (
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>
            {formType === "add" ? "Add New Item" : "Edit Item"}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              {/* Render form fields based on the type of item */}
              {/* Example for Invoices */}
              {selectedTab === 0 && (
                <>
                  <TextField
                    {...register("invNumber")}
                    label="Invoice Number"
                    error={!!errors.invNumber}
                    helperText={errors.invNumber?.message}
                  />
                  <TextField
                    {...register("date")}
                    type="date"
                    label="Date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                  />
                  <TextField
                    {...register("lastDate")}
                    type="date"
                    label="Last Date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.lastDate}
                    helperText={errors.lastDate?.message}
                  />
               <FormControl fullWidth sx={{ mt: 2 }}>
    <InputLabel id="supplier-select-label">Supplier</InputLabel>
    <Select
      labelId="supplier-select-label"
      id="supplier-select"
      value={supplier}
      label="Supplier"
      onChange={(e) => setSupplier(e.target.value)}
    >
      {sampleSuppliers.map((sup) => (
        <MenuItem key={sup.id} value={sup.id}>
          {sup.Name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
             
              <Typography variant="h6" sx={{ mt: 2 }}>
                Current Stocks
              </Typography>
              <Button variant="outlined" color="primary" onClick={handleOpenStockPicker} sx={{ mt: 2 }}>
                Add Items
              </Button>
              <Box sx={{maxHeight:"300px", overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },}}>
                 { formType === "add" ? (<EditableStockList
            currentInvoiceStocks={copiedInvoiceStocks} // Use the copied array for editing
            handleStockChange={handleStockChange} // Update the copied array
            handleDeleteStock={handleDeleteStock}
          />):(<ReadOnlyStockList currentInvoiceStocks={currentInvoiceStocks}/>)}
           
              </Box>
            
                </>
              )}
              {/* Add similar fields for Packages and Expenses */}
              {selectedTab === 1 && (
                <>
                  <TextField
                    {...register("name")}
                    label="Package Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    {...register("amount")}
                    type="text"
                    label="Amount"
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                  <TextField
                    {...register("price")}
                    type="text"
                    label="Price"
                    error={!!errors.price}
                    helperText={errors.price?.message}
                  />
                  <TextField
                    {...register("serves")}
                    type="text"
                    label="Serves"
                    error={!!errors.serves}
                    helperText={errors.serves?.message}
                  />
                  {/* Add additional fields as necessary */}
                </>
              )}
              {selectedTab === 2 && (
                <>
                  <TextField
                    {...register("name")}
                    label="Expense Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    {...register("amount")}
                    type="number"
                    label="Amount"
                    error={!!errors.amount}
                    helperText={errors.amount?.message}
                  />
                   <FormControl fullWidth margin="normal">
                    <InputLabel id="recurrence-label">Recurrence</InputLabel>
                    <Select
                      {...register("recurrence")}
                      labelId="recurrence-label"
                      id="recurrence-select"
                      label="Recurrence"
                      error={!!errors.recurrence}
                      defaultValue={"Weekly"}// Controlled by React Hook Form
                      // Display helper text below in case of error
                      // Note: Material UI Select doesn't directly support helperText like TextField.
                      // You might need to manually add Typography if needed to show errors.
                    >
                      <MenuItem  value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </Select>
                    {!!errors.recurrence && (
                      <Typography color="error" variant="caption">
                        {errors.recurrence.message}
                      </Typography>
                    )}
                  </FormControl>
                  {/* Add additional fields as necessary */}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {formType === "add" ? "Add" : "Update"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      );

};

export default DialogComponent;