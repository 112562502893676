import React, {useState} from 'react';
import {
   Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, IconButton, TextField,
}  from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';


const ExpensesTable = ({
    handleClickOpen, handleCollectiveDelete, handleSelectAllExpenses, handleSelectExpense, 
    isAllSelected, expenses, selectedExpenses, emptyState
  }) => {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (event) => {
      setSearchText(event.target.value);
    };
  
    const filteredBills= searchText
    ? expenses.filter(expense => {
        // Convert search text to lower case and split into keywords
        const searchKeywords = searchText.toLowerCase().split(/\s+/);
        // Convert invoice number to lower case for case-insensitive comparison
        const expensesNumberLower = expense.name.toLowerCase();
  
        // Check if any keyword is included in the invoice number
        return searchKeywords.some(keyword => expensesNumberLower.includes(keyword));
      })
    : expenses;
    const noDataMessage = (entityName) => (
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
        No {entityName} found. Please add some.
      </Typography>
    );
    return (
      <> 
      <TextField
        label="Search by Expense Name"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        style={{ marginBottom: '10px' }}
      />
        <Button onClick={() => handleClickOpen("add")}>Add Expense</Button>
        <Button color="secondary" onClick={handleCollectiveDelete} disabled={selectedExpenses.length === 0}>
          Delete Selected
        </Button>
        {emptyState ?(
              noDataMessage("expenses")
            ):(
        <TableContainer component={Paper} sx={{ maxHeight:"60vh" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllSelected(expenses)}
                    onChange={() => handleSelectAllExpenses(expenses)}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Recurrence</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
           <TableBody>
              {filteredBills.map((expense) => (
                <TableRow key={expense.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedExpenses.includes(expense.id)}
                      onChange={() => handleSelectExpense(expense.id)}
                    />
                  </TableCell>
                  <TableCell>{expense.name}</TableCell>
                  <TableCell>${expense.amount}</TableCell>
                  <TableCell>{expense.recurrence}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen('edit', expense)}><EditIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            
          </Table>
        </TableContainer>)}
      </>
    );
  };
  
  export default ExpensesTable;