import { useParseSDK } from "../hooks/useParseSDK";

const { Parse } = useParseSDK();

interface User {
  email: string;
  name: string;
  role: string;
}
interface UserUpdateData {
  id: string;
  firstName: string;
  lastName: string;
  userEmail: string;
}

interface BusinessUpdateData {
  id: string;
  businessName: string;
  businessPhone: string;
  businessAddress: string;
  businessABN: string;
  paymentCycle: string;
  paymentCycleStartDate: Date;
  accountantName: string;
  accountantEmail: string;
}

export const updateUserProfile = async (
  userData: UserUpdateData
): Promise<void> => {
  try {
    // Use Parse.Query with the Parse.User class
    const query = new Parse.Query(Parse.User);

    // Retrieve the user object you want to update
    const userToUpdate = await query.get(userData.id);

    // Set new values for the user's properties
    userToUpdate.set("First_Name", userData.firstName);
    userToUpdate.set("Last_Name", userData.lastName);
    userToUpdate.set("email", userData.userEmail);

    // Save the updated user object back to the server
    await userToUpdate.save();
  } catch (error) {
    console.error("Error updating user profile:", error);
  }
};

// Function to update business data
export const updateBusinessData = async (
  businessData: BusinessUpdateData
): Promise<void> => {
  try {
    const businessToUpdate = await new Parse.Query("Business").get(
      businessData.id
    );
    businessToUpdate.set("Business_Name", businessData.businessName);
    businessToUpdate.set("Business_Number", businessData.businessPhone);
    businessToUpdate.set("Business_Address", businessData.businessAddress);
    businessToUpdate.set("Business_ABN", businessData.businessABN);
    businessToUpdate.set("payment_cycle_frequency", businessData.paymentCycle);
    businessToUpdate.set(
      "payment_cycle_start",
      businessData.paymentCycleStartDate
    );
    businessToUpdate.set("Accountant_Name", businessData.accountantName);
    businessToUpdate.set("Accountant_Email", businessData.accountantEmail);
    await businessToUpdate.save();
    alert("Business data updated successfully.");
  } catch (error) {
    console.log("Error updating business data:", error);
  }
};

export const handleInviteUsers = async (
  users: User[],
  businessId: string
): Promise<void> => {
  const userObjects: (typeof Parse.User)[] = [];

  try {
    // Loop through the array of users
    for (const { email, name, role } of users) {
      // Generate a random password
      const randomPassword = Math.random().toString(36).slice(-8);

      // Create a new Parse User object
      const user = new Parse.User();
      user.set("username", email);
      user.set("email", email);
      user.set("First_Name", name);
      user.set("password", randomPassword);
      user.set("isAuto", true);

      // Add to the array of user objects
      userObjects.push(user);
    }

    // Assign roles to the users
    const userResults: (typeof Parse.User)[] = await Parse.Object.saveAll(
      userObjects
    );

    // Assign roles to the users
    const roleQuery = new Parse.Query(Parse.Role);
    for (const { role, email } of users) {
      roleQuery.equalTo("name", role);
      const employeeRole = await roleQuery.first();

      if (employeeRole) {
        console.log(`Role found: ${role}`);
        employeeRole
          .getUsers()
          .add(
            userResults.filter(
              (userResult) => userResult.get("email") === email
            )
          );
        await employeeRole.save();
      } else {
        console.log(`${role} role not found.`);
      }
    }

    // Fetch the business object
    const businessQuery = new Parse.Query("Business");
    businessQuery.equalTo("objectId", businessId);
    const business = await businessQuery.first();

    // Fetch existing Business_Users and append new user IDs
    const existingBusinessUsers: string[] =
      business?.get("Business_Users") || [];
    const newBusinessUsers = [
      ...existingBusinessUsers,
      ...userResults.map((u) => u.id),
    ];
    business?.set("Business_Users", newBusinessUsers);

    // Save the updated business object
    await business?.save();

    // Trigger password reset for all users
    for (const { email, name } of users) {
      await Parse.User.requestPasswordReset(email);
      setTimeout(async () => {
        await Parse.Cloud.run("sendAppInvite", { email: email, name: name }); // Send an email after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds
    }

    //  console.log(`Invitations sent to ${users.map((u) => u.email).join(", ")}`);
  } catch (error) {
    console.error("Error while inviting users", error);
  }
};
