import * as XLSX from 'xlsx';

function calculatePackagingCostPerServing(recipe) {
  if (!recipe || !Array.isArray(recipe.packaging) ) {
    console.error("Invalid recipe data");
    return 0;
  }

  let totalPackagingCost = 0;

  recipe.packaging.forEach(packagingItem => {
    // Parse packaging item data as numbers
    const amount = Number(packagingItem.amount);
    const price = Number(packagingItem.price);
    const serves = Number(packagingItem.serves);

    // Validate parsed values
    if (isNaN(amount) || isNaN(price) || isNaN(serves) || amount === 0 || serves === 0) {
      console.error("Invalid packaging item data", packagingItem);
      return; // Skip this packaging item
    }

    // Calculate the cost per package item
    const costPerPackageItem = price / amount;
    // Calculate how many servings each package item covers
    const servingsPerPackageItem = serves;
    // Add the cost per serving for this packaging item
    totalPackagingCost += costPerPackageItem / servingsPerPackageItem;
  });

  // Parse total servings as a number
 
  // Total packaging cost divided by total servings
  return totalPackagingCost ;
}

const createExcelWorkbook = (recipeData, overheadMarkup, taxMarkUp) => {
  if (!Array.isArray(recipeData)) {
    throw new Error("recipeData must be an array");
  }

  const workbook = XLSX.utils.book_new(); // Create a new workbook

  // Function to format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  recipeData.forEach((recipe) => {
    const worksheetData = [];
    const totalMarkup = overheadMarkup + taxMarkUp;
    const baseCostPerServe = recipe.cost / recipe.servings;
    const costPerServe = baseCostPerServe * (1 + totalMarkup / 100);
    const packagingCostPerServing = calculatePackagingCostPerServing(recipe)||0;
    // Summary Data
    worksheetData.push([
      "Name",
      "No. of serves",
      "Cost of Recipe",
      "Base cost per serving",
      "Cost per serving inc packaging",
      "Packaging cost per serve",
      "Overhead Mark Up & Tax",
      "Retail price",
      "Profit/Loss",
    ]);

    // Summary Row
    worksheetData.push([
      recipe.name,
      recipe.servings,
      formatCurrency(recipe.cost),
      formatCurrency(baseCostPerServe),
      formatCurrency(costPerServe),
      formatCurrency(packagingCostPerServing),
      totalMarkup ? `${totalMarkup}%` : "0%",
      recipe.retailPrice ? formatCurrency(recipe.retailPrice) : "0",
      recipe.retailPrice ? formatCurrency(recipe.retailPrice - costPerServe) : "0",
    ]);

    // Ingredients Data
    worksheetData.push(["", "", "", ""]); // Blank row for separation
    worksheetData.push(["Ingredients", "Amount", "Unit", "Cost"]);

    recipe.ingredients.forEach((ingredient) => {
      worksheetData.push([
        ingredient.name,
        ingredient.amount,
        ingredient.unit,
        formatCurrency(ingredient.cost),
      ]);
    });

    // Add worksheet to workbook
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, recipe.name.substring(0, 31)); // Sheet name is limited to 31 characters
  });

  return workbook;
};

export const exportToCSV = (recipeData, overheadMarkup, taxMarkUp) => {
  if (typeof overheadMarkup !== "number" || typeof taxMarkUp !== "number") {
    throw new Error("overheadMarkup and taxMarkUp must be numbers");
  }
  if (overheadMarkup < 0 || taxMarkUp < 0) {
    throw new Error("overheadMarkup and taxMarkUp must be positive numbers");
  }

  const workbook = createExcelWorkbook(recipeData, overheadMarkup, taxMarkUp);
  XLSX.writeFile(workbook, "omar-costwizard-report.xlsx");
};
