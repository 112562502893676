import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Typography,
  List, ListItem, Checkbox, ListItemText, Button
} from "@mui/material";

const StockPickerDialog = ({
  openStockPicker, handleCloseStockPicker, selectedCategory, setSelectedCategory,
  stockState, handleToggleStock, selectedStocks, handleAddStocksToInvoice
}) => {
  return (
    <Dialog open={openStockPicker} onClose={handleCloseStockPicker}>
      <DialogTitle>Select Stocks</DialogTitle>
      <DialogContent>
        {/* Category Picker */}
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            label="Category"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {Object.keys(stockState.stock).map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6">Select items from {selectedCategory}</Typography>
        <List>
          {stockState.stock[selectedCategory]?.map((stock) => (
            <ListItem key={stock.id} button onClick={() => handleToggleStock(stock.id, selectedCategory)}>
              <Checkbox
                checked={selectedStocks.some(item => item.stockId === stock.id && item.category === selectedCategory)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={stock.Name} secondary={`Qty: ${stock.Quantity},Unit: ${stock.Unit}, Price: $${stock.Price}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseStockPicker}>Cancel</Button>
        <Button onClick={handleAddStocksToInvoice}>Add Selected</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockPickerDialog;
