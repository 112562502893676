import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Grid,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Divider,
  Box,
  TableContainer,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { calculateRecipeCosts } from "../../utils/calculateRecipeCost";
import { calculateBaseCostPerServing } from "../../utils/costPerServingCalculation";
import { useRecipeState } from "@reduxHooks/useRecipesState";
import { useStockDataState } from "@reduxHooks/useStockDataState";
import stockDataProcessor from "../../hooks/RecipeProcessing";
import { exportToCSV } from "../../utils/exportCSV";
import { updateStockData } from "utils/DataTransformation";
import {organizeStockData}  from "hooks/organizeData";

const RecipeCostDisplay = () => {
  const { recipeState } = useRecipeState();
  const { stockData } = useStockDataState();
  const organizedInvoices= organizeStockData();
 const monthYearOptions = useMemo(() => {
    return Array.from(
      new Set(organizedInvoices.map((item) => item.monthYear))
    ).sort((a, b) => new Date(b) - new Date(a)); // Sort in descending order
  }, [organizedInvoices]);

  // Set the latest monthYear as the initial value
  const [selectedMonthYear, setSelectedMonthYear] = useState( "");

  const handleMonthYearChange = (event) => {
    setSelectedMonthYear(event.target.value);
  };
  

  const updatedStockData = useMemo(() => updateStockData(stockData.stockData), [stockData.stockData]);
  
  const recipeCosts = calculateRecipeCosts( recipeState.recipes,updatedStockData);

  const costPerServing = useMemo(
    () => calculateBaseCostPerServing(recipeCosts),
    [recipeCosts]
  );

  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [overheadMarkup, setOverheadMarkup] = useState(0);
  const [taxMarkup, setTaxMarkup] = useState(0);
  const [recipeIngredients, setRecipeIngredients] = useState([]);

  const calculateTotalCost = (costPerServing, overhead, tax) => {
    return costPerServing.map((recipe) => ({
      ...recipe,
      totalCost: parseFloat(
        (recipe.costPerServing * (1 + overhead / 100 + tax / 100)).toFixed(2)
      ),
    }));
  };

  const totalCosts = useMemo(
    () => calculateTotalCost(costPerServing, overheadMarkup, taxMarkup),
    [costPerServing, overheadMarkup, taxMarkup]
  );

  useEffect(() => {
    if (selectedRecipe) {
      const updatedIngredients = selectedRecipe.ingredients?.map(
        (ingredient) => ({
          ...ingredient,
          cost: parseFloat(ingredient.cost.toFixed(2)),
        })
      );
      setRecipeIngredients(updatedIngredients);
    }
  }, [selectedRecipe]);

  const handleRecipeSelect = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const { calculateRecipeWeight } = stockDataProcessor();

  const recipeWeight = calculateRecipeWeight(
    selectedRecipe || [],
    stockData.stockData
  );

  const [searchTerm, setSearchTerm] = useState("");

  const filteredRecipes = useMemo(() => {
    return totalCosts.filter((recipe) =>
      recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [totalCosts, searchTerm]);

  return (
    <Box sx={{ flexGrow: 1, p: 3, overflow: "none" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium and larger
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: { xs: 1, md: 0 } }}>
          Markup Details
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            width: { md: "auto", xs: "100%" }, // Full width on small screens, auto on medium and larger
            mb: 2,
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", sm: "auto" },
              mb: 2,
            }}
          >
            <Typography sx={{ minWidth: "150px" }}>Overhead Markup:</Typography>
            <Slider
              value={overheadMarkup}
              onChange={(e, newVal) => setOverheadMarkup(newVal)}
              aria-labelledby="overhead-markup-slider"
              sx={{ mx: 2, width: { xs: "100%", sm: "200px" } }}
            />
            <Typography>{overheadMarkup}%</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", sm: "auto" },
              mb: 2,
            }}
          >
            <Typography sx={{ minWidth: "150px" }}>Tax Markup:</Typography>
            <Slider
              value={taxMarkup}
              onChange={(e, newVal) => setTaxMarkup(newVal)}
              aria-labelledby="tax-markup-slider"
              sx={{ mx: 2, width: { xs: "100%", sm: "200px" } }}
            />
            <Typography>{taxMarkup}%</Typography>
          </Box>
          {/* TO-DO: This is part of the next part */}
          {/* <FormControl sx={{ minWidth:"150px"}}>
            <InputLabel id="monthYear-select-label">Month-Year</InputLabel>
            <Select
              labelId="monthYear-select-label"
              id="monthYear-select"
              value={selectedMonthYear}
              label="Month-Year"
              onChange={handleMonthYearChange}
            >
              {monthYearOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => exportToCSV(totalCosts, overheadMarkup, taxMarkup)}
          sx={{
            alignSelf: "start",
            width: { xs: "100%", md: "auto" },
            mt: { xs: 2, md: 0 },
          }} // Adjust margin-top for small screens
        >
          Export to Excel
        </Button>
      </Box>
      <TextField
        label="Search Recipe"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 1 }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              maxHeight: "70vh",
              position: "relative",

              overflow: "hidden",
              "&:hover": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "10px",
                },
                "::-webkit-scrollbar-track": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "2px solid rgba(0,0,0,0.05)",
                },
                "::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0,0,0,0.3)",
                },
                "::-webkit-scrollbar-button": {
                  display: "none",
                },
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Recipe Name</TableCell>
                  <TableCell align="right">Cost per Serving</TableCell>
                  <TableCell align="right">Total Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecipes.map((recipe) => (
                  <TableRow
                    key={recipe.id}
                    hover
                    onClick={() => handleRecipeSelect(recipe)}
                    selected={selectedRecipe?.id === recipe.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{recipe.name}</TableCell>
                    <TableCell align="right">
                      ${recipe.costPerServing}
                    </TableCell>
                    <TableCell align="right">${recipe.totalCost}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedRecipe ? (
            <Paper
              sx={{
                position: "relative",
                maxHeight: "70vh",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  bgcolor: "background.paper",
                  zIndex: 1,
                  p: 2,
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="h6">{selectedRecipe.name}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography variant="body2">
                    Cost: ${selectedRecipe.cost}
                  </Typography>
                  <Typography variant="body2">
                    Weight: {recipeWeight} kg
                  </Typography>
                  <Typography variant="body2">
                    Cost per kg: $
                    {(selectedRecipe.cost / recipeWeight).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <TableContainer
                sx={{
                  maxHeight: "calc(70vh - 48px)",
                  overflow: "hidden",
                  "&:hover": {
                    overflowY: "auto",
                    "::-webkit-scrollbar": {
                      width: "10px",
                    },
                    "::-webkit-scrollbar-track": {
                      borderRadius: "10px",
                      backgroundColor: "rgba(0,0,0,0.05)",
                    },
                    "::-webkit-scrollbar-thumb": {
                      borderRadius: "10px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                      border: "2px solid rgba(0,0,0,0.05)",
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "rgba(0,0,0,0.3)",
                    },
                    "::-webkit-scrollbar-button": {
                      display: "none",
                    },
                  },
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ingredient</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Unit</TableCell>
                      <TableCell align="right">Cost</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recipeIngredients.map((ingredient) => (
                      <TableRow key={ingredient.name}>
                        <TableCell>{ingredient.name}</TableCell>
                        <TableCell align="right">{ingredient.amount}</TableCell>
                        <TableCell align="right">{ingredient.unit}</TableCell>
                        <TableCell align="right">
                          $
                          {(
                            ingredient.cost *
                            (1 + overheadMarkup / 100 + taxMarkup / 100)
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Typography sx={{ mt: 2 }}>
              Select a recipe to view details.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecipeCostDisplay;
