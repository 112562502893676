import React, { FC, useState } from "react";
import { useForm , Controller} from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStockState } from "@reduxHooks/useStocksState";
import Lottie from 'lottie-react';
import SuccessLottie from '../../../assets/lotties/success_lottie.json'; // Update the path as necessary
import ErrorLottie from '../../../assets/lotties/error_lottie.json'; // Update the path as necessary

interface AddStockExistingCategoryProps {
  handleAdd: (data: any) => void;
  closeAddModal: () => void;
}

const schema = yup.object().shape({
  addExistingCategory: yup.string().required("Selecting a Stock is required"),
  addName: yup.string().required("Stock Name is required"),
  addQuantity: yup
    .number()
    .required("Stock Quantity is required")
    .positive("Stock Quantity must be positive")
    .integer("Stock Quantity must be an integer"),
  addPrice: yup
    .number()
    .required("Stock Price is required")
    .positive("Stock Price must be positive"),
  addUnit: yup.string().required("Stock Unit is required"),
});

const AddStockExistingCategory: FC<AddStockExistingCategoryProps> = ({
  handleAdd,
  closeAddModal,
}) => {
  const { stockState } = useStockState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showSuccess, setShowSuccess] = useState(false); // State for showing success animation
  const [showError, setShowError] = useState(false); // State for showing error animation

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    try {
      handleAdd(data);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        reset(); // Reset the form fields after successful submission
        setSelectedCategory(""); // Reset the selected category
      }, 3000);
    } catch (error) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
  };

  return (
    <>
      {showSuccess ? (
        <Box display="flex" justifyContent="center" my={2}>
          <Lottie animationData={SuccessLottie} loop={false} />
        </Box>
      ) : showError ? (
        <Box display="flex" justifyContent="center" my={2}>
          <Lottie animationData={ErrorLottie} loop={false} />
        </Box>
      ) : (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Paper
        sx={{
          padding: "24px",
          width: "100%",
          maxWidth: "600px",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <Typography variant="h5" mb={2}>
          Add Stock to Existing Category
        </Typography>
        <Box mb={2}>
          <FormControl fullWidth error={Boolean(errors.addExistingCategory)}>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              {...register("addExistingCategory")}
              value={selectedCategory}
              label="Category"
              onChange={(e) => setSelectedCategory(e.target.value)}
              // Remove the error and helperText props from here
            >
              {Object.keys(stockState.stock).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {errors.addExistingCategory?.message}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          {" "}
          {/* Add margin to create space */}
          <TextField
            label="Stock Name"
            variant="outlined"
            {...register("addName")}
            error={Boolean(errors.addName)}
            helperText={errors.addName?.message}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          {" "}
          {/* Add margin to create space */}
          <TextField
            label="Stock Quantity"
            variant="outlined"
            {...register("addQuantity")}
            error={Boolean(errors.addQuantity)}
            helperText={errors.addQuantity?.message}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          {" "}
          {/* Add margin to create space */}
          <TextField
            label="Stock Price (AUD)"
            variant="outlined"
            {...register("addPrice")}
            error={Boolean(errors.addPrice)}
            helperText={errors.addPrice?.message}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          {" "}
          {/* Add margin to create space */}
          <FormControl fullWidth margin="normal" error={Boolean(errors.addUnit)}>
      <InputLabel id="stock-unit-select-label">Stock Unit</InputLabel>
      <Controller
        name="addUnit"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            labelId="stock-unit-select-label"
            label="Stock Unit"
          >
            <MenuItem value="g">g</MenuItem>
            <MenuItem value="kg">kg</MenuItem>
            <MenuItem value="pcs">pcs</MenuItem>
            <MenuItem value="ml">ml</MenuItem>
            <MenuItem value="l">l</MenuItem>
          </Select>
        )}
      />
      {errors.addUnit && <FormHelperText>{errors.addUnit.message}</FormHelperText>}
    </FormControl>
        </Box>
        <Grid container spacing={2}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={closeAddModal}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>)}
    </>
  );
};

export default AddStockExistingCategory;
