import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the ScheduledRecipe type
interface ScheduledRecipe {
  recipe: string;
  ingredients: string[];
  batches: number;
  date: string; // or Date if you are using JavaScript Date objects
  batchNumber: number;
  Business: string;
  isDone: boolean; // Optional, based on whether it's always present or not
  start: string;
  end: string;
  parentId: string  | null;
  prepTime:string;
  cookTime:string;
}

type ScheduleStateType = {
  scheduledRecipes: ScheduledRecipe[];
};

const initialState: ScheduleStateType = {
  scheduledRecipes: [],
};

const scheduledRecipesSlice = createSlice({
  name: "scheduledRecipesState",
  initialState,
  reducers: {
    setScheduleData: (
      state,
      action: PayloadAction<{ scheduledRecipes: ScheduledRecipe[] }>,
    ) => {
      state.scheduledRecipes = action.payload.scheduledRecipes;
    },
  },
});

export const { setScheduleData } = scheduledRecipesSlice.actions;
export default scheduledRecipesSlice.reducer;
