import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuppliersState } from '@reduxHooks/useSupplierState';
import { Grid, Card, CardContent, Container, Typography, CardActionArea, Box, Drawer, IconButton } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material'; // Import the Delete icon
import { useFetchSuppliers } from '@api/suppliers';
import SupplierForm from './addSupplierForm';
import ResponseModal from '@components/success/errorModal';
import { handleDeleteSupplier } from '@api/CRUDOpSupplier';
import { useFetchInvoices } from '@api/invoice';


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function SupplierInfo() {
  const { suppliersState } = useSuppliersState();
  const { suppliers } = suppliersState;
  const hasFetchedData = useRef(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // State for showing success animation
  const [showError, setShowError] = useState(false); // State for showing error animation
  const [showAnim,setSHowAnim] =useState(false);
  const prevIsOpenMode = usePrevious(drawerOpen);

  const [isSuccess,setIsSucces]=useState(false);// true means Success and false means Error
  const [isError,setIsError]=useState(false);

  const { fetchSupplier } = useFetchSuppliers();
  const {fetchInvoices} = useFetchInvoices();

  const fetchData = useCallback(() => {
    if (!hasFetchedData.current) {
      fetchSupplier();
      fetchInvoices();
      hasFetchedData.current = true;
    }
  }, [fetchSupplier]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

 useEffect(() => {
    // This condition checks if either mode was true and has just been set to false
    if (prevIsOpenMode && !drawerOpen) {
      if(isSuccess){
        setSHowAnim(true);
        setShowSuccess(true);
        fetchSupplier();
        setTimeout(() => {
          setShowSuccess(false);
          setSHowAnim(false);
        }, 2000);
      } else if(isError){
        setSHowAnim(true);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setSHowAnim(false);
        }, 2000);
      }
    
    }
  }, [drawerOpen, prevIsOpenMode]);
  
  const navigate = useNavigate();

  const handleNavigate = (supplierId) => {
    navigate(`/dashboard/supplier/${supplierId}`);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    fetchData();
  };

  const handleDelete = async(event, supplierId) => {
   try{ event.stopPropagation(); // Prevent CardActionArea click event
    await handleDeleteSupplier(supplierId);
    setSHowAnim(true);
    setShowSuccess(true);
    fetchSupplier();
    setTimeout(() => {
      setShowSuccess(false);
      setSHowAnim(false);
    }, 2000);
  }catch (error) {
    setSHowAnim(true);
    setShowError(true);
    setTimeout(() =>{ setShowError(false);  setSHowAnim(false);}, 2000);
  }
    // Implement your delete logic here
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
        <IconButton
          aria-label="add"
          onClick={toggleDrawer}
          size="large"
          sx={{ bgcolor: "#2a2a2a", '&:hover': { bgcolor: "#3a3a3a" } }}
        >
          <AddCircleOutline style={{ color: '#fff' }} />
        </IconButton>
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{ width: 400, flexShrink: 0, '& .MuiDrawer-paper': { width: 400, boxSizing: 'border-box' } }}
      >
        <Box
          sx={{ width: 400, padding: 2 }}
          role="presentation"
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Add New Supplier
          </Typography>
          <SupplierForm setDrawer={setDrawerOpen} isSuccess={setIsSucces} isError={setIsError}/>
        </Box>
      </Drawer>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {suppliers.map((supplier) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={supplier.id}>
              <Card>
                <IconButton
                  aria-label="delete"
                  onClick={(event) => handleDelete(event, supplier.id)}
                  style={{ justifyContent:"flex-end",alignSelf:"flex-end", zIndex: 1 }} // Position the delete icon on top right corner
                >
                  <Delete />
                </IconButton>
                <CardActionArea onClick={() => handleNavigate(supplier.id)}>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {supplier.Name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Contact: {supplier.ContactNumber}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Email: {supplier.Email}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ResponseModal show={showAnim} setShow={setSHowAnim} success={showSuccess} errorResponse={showError}/>
   
    </>
  );
}

export default SupplierInfo;
