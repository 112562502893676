import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Select,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "redux/store";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useStockState } from "@reduxHooks/useStocksState";
import { useFetchStock } from "../../api/stock";
import { addRecipes } from "@api/CRUDOp";
import { useFetchRecipes } from "@api/recipe";
import Lottie from "lottie-react";
import SuccessLottie from "../../assets/lotties/success_lottie.json";
import ErrorLottie from "../../assets/lotties/error_lottie.json";
import { useRollbar } from "@rollbar/react";

const itemSchema = yup.object().shape({
  stockId: yup.string(),
  amount: yup.number().positive(),
  unit: yup.string(), // Adjust this based on your actual needs
});

// Extend your existing recipeSchema to include ingredients, base, and topping
const recipeSchema = yup
  .object({
    name: yup.string().required("Recipe name is required"),
    cookTime: yup.string().required("Cook time is required"),
    prepTime: yup.string().required("Preperation time is required"),
    cookTemp: yup.string().required("Cook temperature is required"),
    method: yup.string().required("Method is required"),
    servings: yup
      .number()
      .positive()
      .integer()
      .required("Servings are required"),
    retailPrice: yup.number().positive().required("Retail price is required"),
    isIngredient: yup.boolean(),
    ingredients: yup.array().of(itemSchema),
    topping: yup.array().of(itemSchema),
    base: yup.array().of(itemSchema),
  })
  .required();

/* Main Recipe Form Component */
const RecipeForm = () => {
  const rollbar=useRollbar();
  const { fetchStock } = useFetchStock();
  useEffect(() => {
    fetchStock();
  }, []);

  const user = useAppSelector((state) => state.loginState.user);

  const Userbusiness = user.map((user) => user.business.id);
  const businessId = Userbusiness[0];

  const { fetchRecipes } = useFetchRecipes();

  const [tabValue, setTabValue] = useState(0);
  const [recipe, setRecipe] = useState({
    name: "",
    cookTime: "",
    prepTime:"",
    cookTemp: "",
    method: "",
    servings: 0,
    retailPrice: 0,
    businessId: "",
    isIngredient: false,
    ingredients: [],
    topping: [],
    base: [],
  });

  const { stockState } = useStockState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(recipeSchema),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Update recipe state
  const handleChange = (name) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setRecipe({
      ...recipe,
      [name]: value,
      businessId: businessId,
    });
  };

  const handleNestedChange = (name, updatedData) => {

    setRecipe({ ...recipe, [name]: updatedData });
  };
  const [showSuccess, setShowSuccess] = useState(false); // State to control the display of the success animation
  const [showError, setShowError] = useState(false); // State to control the display of the error animation

  const onSubmit = async (formData) => {
    try {
      const completeData = {
        ...formData,
        businessId: businessId,
        ingredients: recipe.ingredients,
        topping: recipe.topping,
        base: recipe.base,
      };

      await addRecipes(completeData);
      console.log(completeData);
      setShowSuccess(true); // Show the success animation
      await fetchRecipes();
      // Reset form fields to initial values after successful submission
      setRecipe({
        name: "",
        cookTime: "",
        prepTime:"",
        cookTemp: "",
        method: "",
        servings: 0,
        retailPrice: 0,
        businessId: "",
        isIngredient: false,
        ingredients: [],
        topping: [],
        base: [],
      });

      // Set a timer to hide the animation after a few seconds
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      // Handle errors if the submission fails
      rollbar.error("Submission error:", error);
      setShowError(true); // Show the error animation

      // Optionally, hide the error animation after a delay
      setTimeout(() => setShowError(false), 3000);
    }
  };

  return (
    <Card sx={{ maxWidth: 600 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Add New Recipe
      </Typography>
      {showSuccess ? (
        <Box display="flex" justifyContent="center">
          <Lottie
            animationData={SuccessLottie}
            height={200}
            width={200}
            loop={false}
            isStopped={!showSuccess}
          />
        </Box>
      ) : showError ? (
        <Box display="flex" justifyContent="center">
          <Lottie
            animationData={ErrorLottie}
            height={200}
            width={200}
            loop={false}
            isStopped={!showError}
          />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Recipe Details" />
            <Tab label="Ingredients" />
            <Tab label="Toppings" />
            <Tab label="Base" />
          </Tabs>
          <Box p={3}>
            {tabValue === 0 && (
              <RecipeDetailsForm
                data={recipe}
                onChange={handleChange}
                register={register}
                errors={errors}
              />
            )}
            {tabValue === 1 && (
              <ItemListForm
                listName="ingredients"
                stockData={stockState.stock}
                data={recipe.ingredients}
                onChange={handleNestedChange}
                businessId={businessId}
              />
            )}
            {tabValue === 2 && (
              <ItemListForm
                listName="topping"
                stockData={stockState.stock}
                data={recipe.topping}
                onChange={handleNestedChange}
                businessId={businessId}
              />
            )}
            {tabValue === 3 && (
              <ItemListForm
                listName="base"
                stockData={stockState.stock}
                data={recipe.base}
                onChange={handleNestedChange}
                businessId={businessId}
              />
            )}
          </Box>
          <Box display="flex" justifyContent="center" m={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              Submit Recipe
            </Button>
          </Box>
        </form>
      )}
    </Card>
  );
};

/* Recipe Details Form Component

TO-DO: Add Props Validation*/
const RecipeDetailsForm = ({ data, onChange, register, errors }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2}>
        {/* Name input */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            autoFocus
            label="Name"
            {...register("name")}
            error={!!errors.name}
            helperText={errors.name?.message}
            value={data.name}
            onChange={onChange("name")}
          />
        </Grid>

        {/* Cook Time input */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Cook Time"
            {...register("cookTime")}
            error={!!errors.cookTime}
            helperText={errors.cookTime?.message}
            value={data.cookTime}
            onChange={onChange("cookTime")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Prep Time"
            {...register("prepTime")}
            error={!!errors.prepTime}
            helperText={errors.prepTime?.message}
            value={data.prepTime}
            onChange={onChange("prepTime")}
          />
        </Grid>
        {/* Cook Temperature input */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Cook Temperature"
            {...register("cookTemp")}
            error={!!errors.cookTemp}
            helperText={errors.cookTemp?.message}
            value={data.cookTemp}
            onChange={onChange("cookTemp")}
          />
        </Grid>

        {/* Servings input */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Servings"
            type="number"
            {...register("servings")}
            error={!!errors.servings}
            helperText={errors.servings?.message}
            value={data.servings}
            onChange={onChange("servings")}
          />
        </Grid>

        {/* Retail Price input */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Retail Price"
            type="number"
            {...register("retailPrice")}
            error={!!errors.retailPrice}
            helperText={errors.retailPrice?.message}
            value={data.retailPrice}
            onChange={onChange("retailPrice")}
          />
        </Grid>

        {/* Is Ingredient switch */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                {...register("isIngredient")}
                checked={data.isIngredient}
                onChange={onChange("isIngredient")}
              />
            }
            label="Is this recipe an ingredient?"
          />
        </Grid>

        {/* Method input */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Method"
            multiline
            rows={4}
            {...register("method")}
            error={!!errors.method}
            helperText={errors.method?.message}
            value={data.method}
            onChange={onChange("method")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/* Item List Form Component for Ingredients, Toppings, and Base */
const ItemListForm = ({ listName, stockData, data, onChange, businessId }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(stockData)[0] || ""
  );

  // Initialize itemDetails with data prop
  const [itemDetails, setItemDetails] = useState(data);

  const handleToggle = (itemId) => {
    setItemDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails };
      if (updatedDetails[itemId]) {
        delete updatedDetails[itemId];
      } else {
        updatedDetails[itemId] = {
          stock: itemId,
          amount: "",
          unit: "",
          businessId: businessId,
        };
      }
      onChange(listName, updatedDetails); // Update parent state immediately upon toggle
      return updatedDetails;
    });
  };

  const handleItemChange = (itemId, field) => (value) => {
    if (itemDetails[itemId]) {
      const updatedItemDetails = {
        ...itemDetails,
        [itemId]: {
          ...itemDetails[itemId],
          [field]: value, // Update the field with the selected value
        },
      };
      // Convert the updatedItemDetails object to an array for the parent component
      const itemsArray = Object.values(updatedItemDetails);

      // Update the parent component state using the onChange prop
      onChange(listName, itemsArray);

      // Update the itemDetails state to reflect the changes
      setItemDetails(updatedItemDetails);
    }
  };

  const isSelected = (itemId) => !!itemDetails[itemId];

  const renderUnitDropdown = (itemId) => {
    return (
      <UnitDropdown
        value={itemDetails[itemId]?.unit || ""}
        onChange={(selectedUnit) =>
          handleItemChange(itemId, "unit")(selectedUnit)
        }
        name={`unit-${itemId}`}
      />
    );
  };
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="category-select-label">Category</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={selectedCategory}
          label="Category"
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {Object.keys(stockData).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="h6">Select items from {selectedCategory}</Typography>
      <List sx={{ maxHeight: "40vh", overflowY: "auto" }}>
        {stockData[selectedCategory]?.map((item) => (
          <ListItem key={item.id}>
            <Checkbox
              checked={isSelected(item.id)}
              onChange={() => handleToggle(item.id)}
              tabIndex={-1}
              disableRipple
            />
            <ListItemText
              primary={`${item.Name}`}
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              }}
            />

            {isSelected(item.id) && (
              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Amount"
                  type="number"
                  name={`amount-${item.id}`}
                  value={itemDetails[item.id]?.amount || ""}
                  onChange={(e) =>
                    handleItemChange(item.id, "amount")(e.target.value)
                  } // Pass the target value
                />
                {renderUnitDropdown(item.id)} {/* Render the Unit Dropdown */}
              </Box>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const UnitDropdown = ({ value, onChange, name }) => {
  const unitOptions = [
    { label: "Grams (g)", value: "g" },
    { label: "Kilograms (kg)", value: "kg" },
    { label: "Milliliters (ml)", value: "ml" },
    { label: "Liters (L)", value: "L" },
    { label: "Pieces (Pcs)", value: "pcs" },
    // Add more units as needed
  ];
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={`${name}-select-label`}>Unit</InputLabel>
      <Select
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        value={value}
        label="Unit"
        onChange={(e) => onChange(e.target.value)}
        name={name}
      >
        {unitOptions.map((unit) => (
          <MenuItem key={unit.value} value={unit.value}>
            {unit.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RecipeForm;
