import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  Card,
  Container,
  CardContent,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "redux/store";
import "./custom.css";
import { useFetchSchedule } from "@api/scheduledRecipes";
import { useParseSDK } from "hooks/useParseSDK";
import CircularProgress from "@mui/material/CircularProgress";


const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const RecipeCalendar = ({
  recipes,
  events,
  editEvent,
  deleteEvent,
}) => {
  const theme = useTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900, // Define your medium breakpoint here (e.g., 900px)
        lg: 1200,
        xl: 1920,
      },
    },
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const { fetchSchedule } = useFetchSchedule();
  const {Parse}=useParseSDK();
  const [draggedRecipe, setDraggedRecipe] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const user = useAppSelector((state) => state.loginState.user);

  const businessHours = user.map((user) => user.business.businessHours);

  const handleDragStart = (recipe) => {
    setDraggedRecipe(recipe);
  };

  const onDropFromOutside = ({ start }) => {
    if (!draggedRecipe) return;
    setDisable(false);
    // Parse cookTime and prepTime to integers, defaulting prepTime to 0 if unavailable
    const cookTimeInMinutes = parseInt(draggedRecipe.cookTime, 10);
    const prepTimeInMinutes = draggedRecipe.prepTime ? parseInt(draggedRecipe.prepTime, 10) : 0;
    const totalTimeInMinutes = cookTimeInMinutes + prepTimeInMinutes;
  
    // Create a moment object from the dropped start time
    let momentStart = moment(start);
 
    // Calculate the end time by adding total time to the adjusted start time
    const end = momentStart.clone().add(totalTimeInMinutes, 'minutes').toDate();
   
    // Create a new event object
    const newEvent = {
      id: Math.random(),
      recipe: draggedRecipe.id,
      ingredients: draggedRecipe.ingredients,
      date: momentStart.format('YYYY-MM-DD'),
      isDone: false,
      title: draggedRecipe.name,
      cookTime: draggedRecipe.cookTime,
      prepTime: draggedRecipe.prepTime,
      isRecurring: false,
      lastDate: null,
      recurrence: null,
      start: momentStart.toDate(),
      end:end,
      allDay: false,
    };
    // Here you would typically add the new event to your calendar's events state
    // For example:
    // setEvents((prevEvents) => [...prevEvents, newEvent]);
    setSelectedEvent(newEvent);
    setEditDialogOpen(true);
    setDraggedRecipe(null);
  };
  
  const [isLoading, setIsLoading] = useState(false);


  const moveEvent = async ({ event, start, end }) => {
    setIsLoading(true); // Start loading
    const duration = moment(event.end).diff(moment(event.start));
    const ScheduledRecipe = Parse.Object.extend("Scheduled_Recipes");
    const query = new Parse.Query(ScheduledRecipe);
    try {
      const eventObject = await query.get(event.id); // Use the event's objectId to fetch it
      const adjustedEnd = moment(start).add(duration).toDate();
      eventObject.set("start", start);
      eventObject.set("end", adjustedEnd);
      await eventObject.save();
      fetchSchedule(); // Refresh the schedule after the update
    } catch (error) {
      console.error("Error updating event: ", error);
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };
  const [disable,setDisable]=useState(false);

  const handleEditEvent = (event) => {
    // Implement your edit event logic here
    // Show the edit dialog
    if(draggedRecipe){
     
      setEditDialogOpen(true);
    }
    else{
      setDisable(true);
      setEditDialogOpen(true);
       setSelectedEvent(event);

    }
   
  };
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value.toLowerCase());
  };

  const filteredRecipes = recipes?.filter((recipe) =>
    recipe.name.toLowerCase().includes(searchKeyword)
  );
  //Setting responsivneess
  let calendarStyle = {};
  if (isSmallScreen) {
    calendarStyle = { height: "50vh", width: "100%" }; // Adjust for small screens
  } else if (isMediumScreen) {
    calendarStyle = { height: "70vh", width: "100%" }; // Adjust for medium screens
  } else {
    calendarStyle = { height: "calc(90vh - 250px)", width: "100%" }; // Default style for larger screens
  }

 
  const filteredEvents = events.filter(event => {
    const dayOfWeek = moment(event.start).day();
    return dayOfWeek !== 0 && dayOfWeek !== 6; // 0 is Sunday, 6 is Saturday
  });
  
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('week');
  const [businessHoursForDay, setBusinessHoursForDay] = useState({
    start: new Date(2023, 0, 1, 9, 0, 0), // Default to 09:00 on 2024-01-01
    end: new Date(2090, 0, 1, 18, 0, 0), // Default to 18:00 on 2024-01-01
  });

  React.useEffect(() => {
    // Assuming businessHours is accessible here and structured as mentioned
    const dayOfWeek = moment().format('dddd').toLowerCase(); // If you need to adjust based on currentDate, replace moment() with a moment(currentDate)
    const todayBusinessHours = businessHours[0].find(({ day }) => day.toLowerCase() === dayOfWeek);

    if (todayBusinessHours) {
      const baseDate = new Date('2024-01-01'); // Adjusted to 2024
      const [startHour, startMinute] = todayBusinessHours.startTime.split(':').map(Number);
      const [endHour, endMinute] = todayBusinessHours.endTime.split(':').map(Number);

      const minTime = new Date(baseDate.setHours(startHour, startMinute, 0, 0));
      const maxTime = new Date(baseDate.setHours(endHour, endMinute, 0, 0));

      setBusinessHoursForDay({ start: minTime, end: maxTime });
    }
  }, [/* dependencies if any, like businessHours or currentDate */]);
  
   
  return (
    <Container>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ):(
        <>
      <TextField
        label="Filter Recipes"
        variant="outlined"
        value={searchKeyword}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          },
          alignItems: "center",
          height: { xs: "200px", sm: "200px", md: "200px", lg: "100px" },
          my: 2,
          overflow: { xs: "auto", sm: "auto", md: "auto", lg: "hidden" },
          "&:hover": {
            overflowX: { xs: "hidden", sm: "hidden", md: "hidden", lg: "auto" },
            "::-webkit-scrollbar": {
              width: "10px",
            },
            "::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "rgba(0,0,0,0.05)",
            },
            "::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "rgba(0,0,0,0.2)",
              border: "2px solid rgba(0,0,0,0.05)",
            },
            "::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0,0,0,0.3)",
            },
            "::-webkit-scrollbar-button": {
              display: "none",
            },
          },
        }}
      >
        {filteredRecipes?.map((recipe) => (
          <Card
            key={recipe.id}
            draggable
            onDragStart={(e) => {
              e.currentTarget.style.opacity = "0.5"; // Reduce opacity when dragging
              handleDragStart(recipe);
            }}
            onDragEnd={(e) => {
              e.currentTarget.style.opacity = "1"; // Reset opacity after dragging
             // handleDragRecipeIntoCalendar(recipe);
            }}
            sx={{
              minWidth: { xs: "90%", sm: "90%", md: "90%", lg: "160px" },
              maxHeight: "180px",
              m: 1,
              flexShrink: 0,
              cursor: "grab", // Cursor indicates it's draggable
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Shadow effect on hover
              
                  backgroundColor: '#CE93D8', // Slightly darker purple for the hover state
              
              },
            }}
          >
            <CardContent>
              <Typography variant="h6" noWrap>
                {recipe.name}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <DragAndDropCalendar
        localizer={localizer}
        events={filteredEvents}
        onEventDrop={moveEvent}
        onDropFromOutside={onDropFromOutside}
        resizable
        style={calendarStyle}
        selectable
        onSelectEvent={(event) => handleEditEvent(event)}
        eventPropGetter={(event) => {
          return {
            className: event.isDone ? 'event-done' : ''
          };
        }}
        view={currentView}
        onView={setCurrentView}
        date={currentDate}
        onNavigate={setCurrentDate}
        min={businessHoursForDay.start} // Dynamically set min time
        max={businessHoursForDay.end} // Dynamically set max time
        step={15}
        timeslots={4}
      />
      </>
)}

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        fullWidth
        maxWidth={isSmallScreen ? "sm" : "md"}
      >
        <DialogTitle>Edit Event</DialogTitle>
        <DialogContent>
          {/* Display event details and allow editing */}
          <TextField
            label="Batch Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedEvent ? selectedEvent.batchNumber : ""}
            onChange={(e) =>
              setSelectedEvent({
                ...selectedEvent,
                batchNumber: e.target.value,
              })
            }
          />
          <FormControl variant="outlined" fullWidth margin="normal" disabled={disable}>
            <InputLabel>Is Recurring?</InputLabel>
            <Select
              label="Is Recurring?"
              value={selectedEvent ? selectedEvent.isRecurring||false : false} // Ensure non-null value
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  isRecurring: e.target.value,
                })
              }
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          {selectedEvent && selectedEvent?.isRecurring && (
            <Box
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl variant="outlined" fullWidth margin="normal" disabled={disable}>
                <InputLabel>Recurrence</InputLabel>
                <Select
                  label="Recurrence"
                  value={selectedEvent ? selectedEvent.recurrence || "" : ""} // Fallback to empty string if null
                  onChange={(e) =>
                    setSelectedEvent({
                      ...selectedEvent,
                      recurrence: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Last Date"
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedEvent ? selectedEvent.lastDate : ""}
                onChange={(e) =>
                  setSelectedEvent({
                    ...selectedEvent,
                    lastDate: e.target.value,
                  })
                }
                disabled={disable}
              />
            </Box>
          )}

          <TextField
            label="Batches"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedEvent ? selectedEvent.batches : ""}
            onChange={(e) =>
              setSelectedEvent({
                ...selectedEvent,
                batches: e.target.value,
              })
            }
          />
           <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Complete?</InputLabel>
            <Select
              label="Is Recurring?"
              value={selectedEvent ? selectedEvent.isDone||false : false} // Ensure non-null value
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  isDone: e.target.value,
                })
              }
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
  <Button onClick={() => setEditDialogOpen(false)} color="primary">
    Cancel
  </Button>
  <Button
    onClick={async() => {
      if (editEvent) {
        // Assuming editEvent is async and returns a Promise
       await editEvent(selectedEvent);
          setEditDialogOpen(false);
         
      }
    }}
    color="primary"
  >
    Save Changes
  </Button>
  <Button
    onClick={async() => {
      if (deleteEvent) {
        // Assuming deleteEvent is async and returns a Promise
        await deleteEvent(selectedEvent);
          setEditDialogOpen(false);
          // Optionally, refresh events or handle UI updates here
        
      }
    }}
    color="secondary"
  >
    Delete Event
  </Button>
</DialogActions>
      </Dialog>
    </Container>
  );
};

export default RecipeCalendar;
